import React, { useEffect, useState } from "react";
import { scaleLinear } from "d3-scale";
import * as d3 from 'd3';
import mapData from './data.csv';
import features from './features.json';
import { ComposableMap, Geographies, Geography, Sphere, Graticule, ZoomableGroup } from "react-simple-maps";

const colorScale = scaleLinear()
  .domain([0.29, 0.68])
  .range(["#DFF0FF", "#0E8EFD"]);

const MapChart = ({setTooltipContent}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    d3.csv(mapData).then((data) => {
      setData(data);
	});
  }, []);
	
  const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json"

  return (
		<ComposableMap height={400} projection="geoMercator">
			<ZoomableGroup center={[30, 0]}>
				<Sphere stroke="#DFF0FF" strokeWidth={0.5} />
				<Graticule stroke="#DFF0FF" strokeWidth={0.5} />
				{data.length > 0 && (
					<Geographies geography={geoUrl}>
						{({ geographies }) =>
							geographies.map((geo) => {
								const d = data.find((s) => s.ISO3 === geo.id);
								return (
									<Geography
										onMouseEnter={() => {
											setTooltipContent(`${geo.properties.name}`);
										}}
										onMouseLeave={() => {
											setTooltipContent("");
										}}
										key={geo.rsmKey}
										geography={geo}
										fill={d ? colorScale(d["2017"]) : "#0E8EFD"}
										style={{
											hover: {
											  fill: "#0A70C9",
											  outline: "none"
											},
										  }}
									/>
								);
							})
						}
					</Geographies>
				)}
			</ZoomableGroup>
		</ComposableMap>
  );
};

export default MapChart;
