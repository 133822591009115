import React, { useEffect, useState } from 'react'
import Logo from '../../layouts/Shared/Logo/Logo'
import Button from '../Buttons/Button'
import { CheckCircle, CloudOff, Info, Plus } from 'react-feather'
import NewMenu from './NewMenu'
import withRouteChange from "@threeskye/route-change";
import { PAGES, ROUTE_PATHS } from "../../InternalApiApp";
import './TopBar.scss'
import Popup from '../Popup'
import ProfileMenu from './ProfileMenu'
import ProfileImage from '../ProfileImage'
import { useRemote } from '../../Utils/Utils'
import { format } from 'date-fns'
import LoadingIcon from '../LoadingIcon';
import StatusWidget from './StatusWidget'

const TopBar = ({ changeRoute, topMessage }) => {
	const [profileMenuOpen] = useState(false);

	const remote = useRemote();

	const [me, setMe] = useState(null);
	
	useEffect(() => {
		remote.get("/crm/me").then(setMe);
	}, [])

	const getMessageComponent = () => {
		const { noDocument, saving, lastSaved, error } = topMessage;

		if (topMessage) {
			return (
				<StatusWidget
					icon={noDocument ? <Info /> : saving ? <LoadingIcon variant="light" size={20} /> : lastSaved ? <CheckCircle className="colour-text-positive" /> : error ? <CloudOff /> : ""}
					label={noDocument ? "No document loaded" : saving ? "Saving to 3SKYE…" : lastSaved ? "Save Complete:" : error ? "Save Unavailable:" : ""}
					message={lastSaved ? <>{format(lastSaved, "hh:mm aaa, dd MMM yyyy ")}</> : error ? "Network Lost" : ""}
					status={error && !saving ? "danger" : ""}
				/>
			);
		}
		return null;		
	}

	return (
		<div className="top-bar colour-bg-accent-2">
			<div className="top-bar-left-container">
				<Logo className="top-bar-logo" onClick={() => {
					changeRoute(ROUTE_PATHS[PAGES.LIBRARY])
					}} 
				/>
			</div>
			{topMessage && getMessageComponent()}
			<div className="top-bar-right-container">
				<Popup
					className="mr-l"
					triggerElement={<Button noHover className="top-bar-new-Button" onClick={() => changeRoute(ROUTE_PATHS[PAGES.EDITOR])} icon={<Plus />} >New</Button>}
					popupElement={<NewMenu crmPath={ROUTE_PATHS[PAGES.CRM]} editorPath={ROUTE_PATHS[PAGES.EDITOR]} changeRoute={changeRoute}/>}
					hoverOnly
					offsetY="2"
				/>
				<Popup
					// onClick={() => setProfileMenuOpen(!profileMenuOpen)}
					triggerElement={<ProfileImage focused={profileMenuOpen} user={me} src={me && ("data:image/jpeg;base64,"+me.photo)} /> }
					popupElement={<ProfileMenu changeRoute={changeRoute} user={me} />} 
					hoverOnly
				/>
			</div>
		</div>
		

	)
};

export default withRouteChange(TopBar);