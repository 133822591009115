// import { withData } from "../../DataController";

/**
 * 
 * @param {*} templateStyles The master styles for the template
 * @param {*} componentStyleMap The style mappings for this component
 * @param {*} tableStyles Table styles for this component, if they exist
 * @param {*} data withData callback
 */
export const getEditableStyles = (templateStyles, componentStyleMap, tableStyles, data) => {
	const myStyles = Object.keys(componentStyleMap).map(key => {
		const actual = templateStyles[componentStyleMap[key]];
		const converted = data.getStyle(actual, true);
		converted.lineHeight = "1.2";
		const val = {};
		val[key] = converted;
		return val;
	});

	const flattenedStyles = {};
	myStyles.forEach(style => Object.assign(flattenedStyles, style));

	if (tableStyles) {
		const defaultCellStyle = data.getStyle(templateStyles[componentStyleMap.cell], true);
		const cellHeaderStyle = data.getStyle(templateStyles[componentStyleMap.cellHeader], true);
		//browser default cellHeader is text-align: center... kill this
		if (cellHeaderStyle && !cellHeaderStyle.hasOwnProperty("textAlign")) {
			cellHeaderStyle.textAlign = "left";
		}


		const tdStyle = {};
		const thStyle = {};

		const paddingBelowTable = tableStyles.paddingBelowTable;
		if (paddingBelowTable) {
			flattenedStyles.table = {
				marginBottom: paddingBelowTable+"pt"
			}
		} else {
			flattenedStyles.table = {
				marginBottom: tableStyles.cellVerticalPadding + "pt"
			}
		}

		Object.assign(tdStyle, defaultCellStyle);
		flattenedStyles.td = tdStyle;
		Object.assign(thStyle, cellHeaderStyle);
		flattenedStyles.th = thStyle;
	}
	return flattenedStyles;
}

export const getTableStyles = (templateStyles, rowStyles, columnStyles, data) => {
	if (!rowStyles) {
		return;
	}
	const keys = rowStyles.map(obj => obj.key);
	columnStyles.forEach(obj => {
		if (!keys.includes(obj.key)) {
			keys.push(obj.key);
		}
	});

	const myStyles = keys.map(key => {
		const converted = data.getStyle(templateStyles[key], true);
		const val = {};
		val[key] = converted;
		return val;
	});

	const flattenedStyles = {};
	myStyles.forEach(style => Object.assign(flattenedStyles, style));

	if (flattenedStyles.minHeight) {
		flattenedStyles.minHeight = flattenedStyles.minHeight + 'pt';
	}
	
	return flattenedStyles;
}