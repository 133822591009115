import React from "react";
import { Info } from "react-feather";
import "./NoResultsMessage.scss";

const NoResultsMessage = ({ icon, message, subMessage, noHeader, noIcon, compact }) => {
	return (
		<div className={`no-results-message-container${compact ? " no-results-compact" : ""}`}>
			{noIcon ? "" : icon ? icon : <Info />}
			{noHeader ? "" : <h2>{message ? message : "No Results Found"}</h2>}
			 <div className="sub-message">{subMessage ? subMessage : "Please check your spelling or try a different term"}</div>
		</div>
	);
}

export default NoResultsMessage;