import React, { useEffect, useState } from 'react';
import { Briefcase, Mail } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import TextFieldLabel from '../../../components/Input/TextFieldLabel';
import './UserPortalAccessDetails.scss'
import { format } from 'date-fns';
import { formatDateTime, useRemote } from '../../../Utils/Utils';
import LoadingIcon from '../../../components/LoadingIcon';
import Button from '../../../components/Buttons/Button';
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';


const blankPortalAccessDetails = {
	"hasAccess": true,
	"status": "Unlocked",
	"lastLogin": format(new Date(), "dd/MM/yyyy hh:mma"),
	"last2FA": format(new Date(), "dd/MM/yyyy hh:mma"),
	"alertEvents": []
}

const UserPortalAccessDetails = ({ editMode, userId, user, updateUser }) => {
	const [portalAccess, setPortalAccess] = useState(blankPortalAccessDetails)

	const remote = useRemote()
	const { hasAccess, status, lastLogin, last2FA } = portalAccess

	useEffect(() => {
		//initialise()
	}, [])

	const initialise = () => {
		//@Colin - These endpoints are copied from CRM 
		//Status comes from endpoint api/modules/crm/provider/clients/{clientEmail}/details in CRM
		const detailCalls = [
			remote.get(`/modules/crm/provider/clients/${userId}/sms-history`),
			remote.get(`/modules/crm/provider/clients/${userId}/login-events`),
			remote.get(`/modules/crm/provider/clients/${userId}/login-history`)
		]

		Promise.all(detailCalls)
			.then(([loginDetails, loginHistory, smsHistory]) => {
				let lastLogin = loginDetails.lastLogin ? format(new Date(loginDetails.lastLogin), "dd/MM/yyyy h:mmaaa") : ""
				let last2FA = loginDetails.last2FAEvent.date ? format(new Date(loginDetails.last2FAEvent.date), "dd/MM/yyyy h:mmaaa") : ""
				let status = "Unlocked"
				let hasAccess = true
				setPortalAccess({ lastLogin, last2FA, status, hasAccess })
			})
	}

	const handleGivePortalAccess = (grantPortalAccess) => {
		if (grantPortalAccess) {
			remote.post(`/crm/contacts/${userId}/portal-access`).then(resp => {
				const updatedUser = { ...user }
				updatedUser.hasPortalAccess = true;
				updateUser(updatedUser);
			});
		} else {
			remote.delete(`/crm/contacts/${userId}/portal-access`).then(resp => {
				const updatedUser = { ...user }
				updatedUser.hasPortalAccess = false;
				updateUser(updatedUser);
			});
		}
	}

	const handleGiveModelAccess = (grantModelAccess) => {
		if (grantModelAccess) {
			remote.post(`/crm/contacts/${userId}/model-access`).then(resp => {
				const updatedUser = { ...user }
				updatedUser.accessToModel = true;
				updateUser(updatedUser);
			});
		} else {
			remote.delete(`/crm/contacts/${userId}/model-access`).then(resp => {
				const updatedUser = { ...user }
				updatedUser.accessToModel = false;
				updateUser(updatedUser);
			});
		}
	}

	const inviteToPortal = () => {
		remote.post(`/crm/contacts/${userId}/send-portal-welcome`).then(resp => {
			if (resp.success) {
				const updatedUser = { ...user }
				updatedUser.hasPortalAccess = true;
				updateUser(updatedUser);
				toastSuccess("Invitation email sent")
			} else {
				toastDanger("Invitation email failed, please check the user has a valid email address.")
			}
		}).catch(() => {
			toastDanger("Invitation email failed, please check the user has a valid email address.")
		});
	}

	if (!user) {
		return <LoadingIcon />
	}

	return (
		<>
			<PageGridItem col="1 / span 6" justifyContent="between">
				<DetailSectionHeader header="Portal Access" icon={<Briefcase />} />
			</PageGridItem>
			<PageGridItem col="1 / span 3" className="portal-access-radio" >
				<label className="colour-text-label-grey transition-fade-up">Model Access</label>
				<div className='portal-access-radio-options mt-xxl'>
					<TextFieldLabel className='portal-access-radio-label' > Yes
						<input readOnly type={"radio"} className='portal-access-radio' checked={user.accessToModel} name="has-model-access" onClick={() => handleGiveModelAccess(true)} />
					</TextFieldLabel>
					<TextFieldLabel className='portal-access-radio-label' > No
						<input readOnly type={"radio"} className='portal-access-radio' checked={!user.accessToModel} name="has-model-access" onClick={() => handleGiveModelAccess(false)} />
					</TextFieldLabel>
				</div>
			</PageGridItem>
			<PageGridItem col="4 / span 3" className="portal-access-radio" >
				<label className="colour-text-label-grey transition-fade-up">Portal Access</label>
				<div className='portal-access-radio-options mt-xxl'>
					<TextFieldLabel className='portal-access-radio-label' > Yes
						<input readOnly type={"radio"} className='portal-access-radio' checked={user.hasPortalAccess} name="has-portal-access" onClick={() => handleGivePortalAccess(true)} />
					</TextFieldLabel>
					<TextFieldLabel className='portal-access-radio-label' > No
						<input readOnly type={"radio"} className='portal-access-radio' checked={!user.hasPortalAccess} name="has-portal-access" onClick={() => handleGivePortalAccess(false)} />
					</TextFieldLabel>
				</div>
			</PageGridItem>
			<PageGridItem col="1 / span 3" className="portal-access-radio" >
				<label className="colour-text-label-grey transition-fade-up">Portal Invitation</label>
				<Button disabled={user.hasPortalAccess} className="invite-to-portal mt-s" onClick={inviteToPortal} icon={<Mail className="invite-to-portal-icon" />}>Send Email</Button>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					editMode={false}
					label="Portal Access Status"
					value={user.portalLocked ? "Locked" : "Unlocked"}
					onChange={e => console.log({ website: e.target.value })}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					editMode={false}
					label="Last Login Event"
					value={user.lastLogin ? formatDateTime(user.lastLogin) : "No login record"}
					onChange={e => console.log({ website: e.target.value })}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					editMode={false}
					label="Last 2FA Event"
					value={"N/A"}
					onChange={e => console.log({ website: e.target.value })}
				/>
			</PageGridItem>
		</>
	);
}

export default UserPortalAccessDetails;