import React from 'react';
import IconLink from '../Menus/IconLink';
import withRouteChange from '@threeskye/route-change';
import { PAGES, ROUTE_PATHS } from "../../InternalApiApp";
import { Edit, Settings, LogOut, BookOpen, User, BarChart, BarChart2 } from 'react-feather';
import SideBarWrapper, { SideBarIconGroup } from './SideBarWrapper';
import { withRemote } from '@threeskye/global';

const SideBarLeft = ({ location, changeRoute, remote }) => {

	const checkRoute = (route) => {
		return route === "/" ? location.pathname === route : location.pathname.startsWith(route)
	}

	return (
		<SideBarWrapper borderRight left>
			<SideBarIconGroup>	
				<IconLink
					icon={<Edit />}
					onClick={() => changeRoute(ROUTE_PATHS[PAGES.EDITOR])}
					active={checkRoute(ROUTE_PATHS[PAGES.EDITOR])}
					label="Editor"
				/>
				<IconLink
					icon={<BookOpen />}
					onClick={() => changeRoute(ROUTE_PATHS[PAGES.LIBRARY])}
					active={checkRoute(ROUTE_PATHS[PAGES.LIBRARY])}
					label="Library"
				/>
				<IconLink
					icon={<BarChart2 />}
					onClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers")}
					active={checkRoute(ROUTE_PATHS[PAGES.ANALYTICS])}
					label="Analytics"
				/>
				<IconLink
					icon={<User />}
					onClick={() => changeRoute(ROUTE_PATHS[PAGES.CRM]+"/users")}
					active={checkRoute(ROUTE_PATHS[PAGES.CRM])}
					label="Client Management"
				/>
				<IconLink
					icon={<Settings />}
					onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN]+"/tickers")}
					active={checkRoute(ROUTE_PATHS[PAGES.ADMIN])}
					label="Admin"
				/>
			</SideBarIconGroup>
			<SideBarIconGroup className="mt-auto">
				<IconLink
					icon={<LogOut />}
					onClick={() => {remote.logout()}}
					active={false}
					label="Logout"
				/>
			</SideBarIconGroup>
		</SideBarWrapper>
	)
};
export default withRemote(withRouteChange(SideBarLeft));