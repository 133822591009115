import React from 'react';
import { format, parseISO } from 'date-fns';
import { withRemote } from '@threeskye/global';
import PageGridItem from './layouts/Shared/Page/PageGridItem';
import SuggestionMessage from './components/EmptyMessage/SuggestionMessage';
import { toastDanger } from './components/popups/Toast';
import { AlertCircle } from 'react-feather';
import { PageContentBodyGrid } from './layouts/Shared/Page/Page';

const DataContext = React.createContext({blah:"blah1"});

export function withData(Child) {
	return function DataChild(props) {
		return React.createElement(DataContext.Consumer, null, function (data) {
			return React.createElement(Child, Object.assign({}, props, {
				data: data
			}));
		});
	};
}


//const JET = window.JET;
const workingInDev = false;

class Data {

	constructor(props) {
		this.props = props;
		this.setRemote = this.setRemote.bind(this);
		this.traverse = this.traverse.bind(this);
		this.compileData = this.compileData.bind(this);
	}

	isBroker = false;
	contentData = {};
	listeners = [];
	localContentData = {};
	localListeners = [];
	tickerListeners = [];

	setRemote(remote) {
		this.dataProvider = remote;
	}
	
	/**
	 * Lookup the actual value of a defined field.
	 * @param {*} val The value to lookup from the stored fields 
	 * @param {*} dataSuffix Suffix to append on the lookup if required. 
	 * @returns 
	 */
	getValue(val, dataSuffix) {
		val = this.isBroker && val.brokerValue ? val.brokerValue : val.value;
		if (!val)
			return val;

		if (dataSuffix && this.tickers.length == 1) {
			dataSuffix = null;
		}

		if (val.includes("PAGE_NUM")) {
			//todo figure out page numbers
			return "";
		}
		
		const self = this;
		return val.replace(/\$\{(.*?)\}/g, function(match, quantity) {
			//quantity = quantity.substring
			let errorValue = null;
			if (quantity.startsWith("coalesce")) {
				const end = quantity.indexOf(")");
				errorValue = quantity.substring(9, end);
				quantity = quantity.substring(end+2);
			}
			if (quantity.startsWith("date:")) {
				//TODO format SimpleDateFormat format = new SimpleDateFormat(quantity.substring(5));
				quantity = format(new Date(), quantity.substring(5));
			} else {
				const upper = quantity.startsWith("uppercase:");
				if (upper) {
					quantity = quantity.substring(10);
				}
				let dateFormat = null;
				if (quantity.startsWith("formatDate:")) {
					const index = quantity.indexOf(":", 12);
					dateFormat = quantity.substring(11, index);
					quantity = quantity.substring(index + 1);
				}
				let multiplierChar = "1";
				let numberFormat = null;
				if (quantity.startsWith("format:")) {
					multiplierChar = quantity.substring(7, 8);
					const index = quantity.indexOf(":", 9);
					numberFormat = quantity.substring(9, index);
					quantity = quantity.substring(index + 1);
				}
				const bits = new Set();
				quantity = quantity.toUpperCase();
				self.parseComplexQuantity(quantity, bits);
				let processed = false;
				if (bits.size === 1) {
					bits.forEach(q=>{
						if (q === quantity) {
							//It's a simple
							quantity = self.contentData[q + (dataSuffix ? dataSuffix : "")];
							processed = true;
							if (dateFormat) {
								try {
									quantity = format(parseISO(quantity), dateFormat);
								} catch (e) {
									console.log("unable to parse iso date ", quantity)
								}
							}
						}
					});
				}
				if (!processed) {
					//Calculation
					let script = "";
					bits.forEach(bit=>{
						let value = self.contentData[bit + (dataSuffix ? dataSuffix : "")];
						//eslint disabled due to bug bug - see note below
						const sanitisedBit = bit.replace(/[\[\-\]\.]/g, "_");					//eslint-disable-line no-useless-escape
						script += "let " + sanitisedBit + " = " + value + ";"
						
						//Also santise the original
						if (sanitisedBit !== bit) {  //Shouldn't be necessary, but if it is we get an infinite loop so better to be safe than sorry
							while (quantity.indexOf(bit) > -1)
								quantity = quantity.replace(bit, sanitisedBit);
						}
					});
					script += "quantity = " + quantity;
					try {
						eval(script);
					} catch (e) {
						quantity = errorValue || "-";	//unable to evaluate
					}
				}

				if (!quantity || (typeof quantity === "number" && isNaN(quantity))) {
					quantity = "";
				} else {
					if (upper) {
						quantity = quantity.toUpperCase();
					}
					if (dateFormat && !(quantity === 'N/A')) {
						try {
							quantity = format(parseISO(quantity), dateFormat);
						} catch (e) {
							console.log("unable to parse iso date ", quantity)
						}
					}
					if (numberFormat) {
						switch (multiplierChar.toUpperCase()) {
							case "k":
								quantity /= 1000;
								break;
							case "M":
								quantity /= 1000000;
								break;
							case "B":
								quantity /= 1000000000;
								break;
							case "Z":
								if (Math.abs(quantity) > 1000) {
									quantity /= 1000;
									if (Math.abs(quantity) > 1000) {
										quantity /= 1000;
										if (Math.abs(quantity) > 1000) {
											quantity /= 1000;
											return new DecimalFormat(numberFormat).format(quantity) + "B";	//eslint-disable-line no-undef
										} else {
											return new DecimalFormat(numberFormat).format(quantity) + "M" 	//eslint-disable-line no-undef
										}
									} else {
										return new DecimalFormat(numberFormat).format(quantity)+"k";		//eslint-disable-line no-undef
									}
								} else {	
									return new DecimalFormat(numberFormat).format(quantity);				//eslint-disable-line no-undef
								}
							default:
								break;
						}
						quantity = new DecimalFormat(numberFormat).format(quantity);  //eslint-disable-line no-undef
					}
				}
			}
			return quantity;
		});
	}

	getLocalValue(prop) {
		return this.localContentData[prop];
	}

	getStyle(style, includeCellStyles) {
		const { size, weight, paddingBelowParagraph, height, lineHeight, minHeight, paddingLeft, paddingRight, paddingBottom, width, borderBottomWidth, ...others } = style;

		const theStyle = { ...others };

		if (theStyle.borderLeftWidth) {
			theStyle.borderLeftWidth += "pt";
		}
		if (theStyle.borderRightWidth) {
			theStyle.borderRightWidth += "pt";
		}
		if (theStyle.paddingTop) {
			theStyle.paddingTop += "pt";
		}
		console.log("Getting style, the style is ", theStyle);
		
		if (size) {
			theStyle.fontSize = size + "pt";
		}
		if (weight) {
			theStyle.fontWeight = weight;
		}
		if (paddingBelowParagraph) {
			theStyle.marginBottom = paddingBelowParagraph + "pt";
		}
		if (width) {
			theStyle.width = width + "pt";
		}
		if (lineHeight) {
			theStyle.lineHeight = lineHeight + "pt"
		}
		if (includeCellStyles) {
			if (minHeight) {
				theStyle.minHeight = minHeight + "pt"
			}
			if (height) {
				theStyle.height = height + "pt";
			}
			if (paddingLeft) {
				theStyle.paddingLeft = paddingLeft + "pt";
			}
			if (paddingRight) {
				theStyle.paddingRight = paddingRight + "pt";
			}
			if (paddingBottom) {
				theStyle.paddingBottom = paddingBottom + "pt";
			}
			if (borderBottomWidth) {
				theStyle.borderBottomWidth = borderBottomWidth+"pt";
			}
			if (lineHeight) {
				theStyle.lineHeight = lineHeight + "pt"
			}
		}
		return theStyle;
		// return {
		// 	fontSize: size + "pt",
		// 	fontWeight: weight,
		// 	marginBottom: paddingBelowParagraph + "pt",
		// 	...others
		// }
	}

	updateTickerGroup(name, allTickers) {
		this.tickerGroup = name;
		this.allTickers = allTickers;
		this.contentData["TICKERGROUP"] = name;
		this.updateLocalData("TICKERGROUP", name);
		const self = this;
		const runAfter = () => {
			self.loadConsensusData.bind(self)(true);
		}
		this.loadLocalData.bind(this)({"TICKERGROUP":name},runAfter);
		if (self.contentData) {
			self.contentData.TICKERGROUP = name;
		}
		//TODO trigger a consensus lookup too
	}

	registerTemplate(template, templateName) {
		this.templateName = templateName;
		const consensusFields = new Set();
		const brokerFields = new Set();
		const indexFields = new Set();

		//Traverse the tree
		template.pages.forEach(page=>{
			page.content.forEach(content=>{
				this.traverse(content, consensusFields, indexFields, brokerFields);
			});
		});
		this.templateType = template.ticker.type;
		
		this.consensusFields = [];
		consensusFields.forEach(field=>this.consensusFields.push(field));
		this.brokerFields = [];
		brokerFields.forEach(field=>this.brokerFields.push(field));
		this.indexFields = [];
		indexFields.forEach(field=>this.indexFields.push(field));

		//Also clear local data
		this.localContentData = {};
		this.tickers = [""];
		this.tickerGroup = null;
		this.fireLocalUpdate.bind(this)();
		this.loadLocalData();
	}

	setTickers(tickers, index, broker) {  //TODO add broker and index
		if (!tickers) {
			return;
		}
		this.tickers = tickers;
		this.index = index;
		this.broker = broker;
		const tickerGroup = this.contentData.TICKERGROUP;
		this.contentData = {};
		if (tickerGroup) {
			this.contentData.TICKERGROUP = tickerGroup;
		}
		this.contentData["TICKERLIST"] = tickers.join(', ');
		
		this.updateLocalData("TICKERLIST", tickers.join(', '));
		//Initially load consensus data
		this.loadConsensusData.bind(this)();
		//and local data
		this.loadLocalData.bind(this)({"TICKERLIST":tickers.join(', ')});
		this.fireTickerUpdate.bind(this)();
	}

	getTickers() {
		return this.tickers;
	}

	getTemplateName() {
		return this.templateName;
	}

	compileData(into) {
		into = into || {};		
		Object.assign(into, this.contentData, this.localContentData);
		return into;
	}

	/**
	 * Update the tickers in the controller
	 * @param {An array of strings containing the tickers} tickers 
	 */
	updateTicker(tickers, src) {
		this.setTickers(tickers);
		this.localContentData = {};
		//
		this.loadLocalData();
		this.fireLocalUpdate.bind(this)(src);
		this.fireTickerUpdate.bind(this)();
	}
	

	loadConsensusData(fireTickerUpdate) {
		if (!this.consensusFields || this.consensusFields.length === 0)
			return;

		const self = this;
		const instruments = (self.tickers && self.tickers.length > 0 && self.tickers[0] !== "") ? self.tickers : self.allTickers;

		const request = {
			instruments: instruments,
			fields: self.consensusFields.map(field=>{return {name: field}})
		};

		this.dataProvider.request(request).then(resp=>{
			const consensusData = JSON.parse(resp);
			const headers = consensusData.headers;
			const data = consensusData.data;
			for (let i=1; i<headers.length; i++) {
				self.contentData[headers[i].field.toUpperCase()] = data[i];
			}
			if (self.localContentData && self.localContentData.TICKERGROUP) {
				self.contentData.TICKERGROUP = self.localContentData.TICKERGROUP;
			}
			if (self.localContentData && self.localContentData.TICKERLIST) {
				self.contentData.TICKERLIST = self.localContentData.TICKERLIST;
				if (fireTickerUpdate) {
					self.fireTickerUpdate.bind(this);
				}
			}
			self.fireUpdate.bind(self)();
		});
		// if (self.index && self.indexFields && self.indexFields.length > 0) {
		// 	const indexRequest = {
		// 		instruments: [self.index],
		// 		fields: self.indexFields.map(field=>{return {name: field}})
		// 	}
		// 	service.request(indexRequest).then(resp=>{
		// 		const indexData = JSON.parse(resp);
		// 		const headers = indexData.headers[0];
		// 		const data = indexData.data[0];
		// 		for (let i=1; i<headers.length; i++) {
		// 			self.contentData["INDEX:" + headers[i].field.toUpperCase()] = data[i];
		// 		}
		// 		self.fireUpdate.bind(self)();
		// 	});
		// }
	}

	loadLocalData(overrides, then) {
		const self = this;
		let identifier = this.tickers ? this.tickers.join(',') : '';
		if (this.templateType === 'selection') {
			identifier = this.tickerGroup;
		}
		if (!identifier || identifier === '') {
			console.log("Not loading local data as identifier is ", identifier);
			if (then) {
				then();
			}
			return;
		}
		identifier = btoa(identifier);
		this.props.remote.get("/retrievev2/" + identifier + "/" + this.templateName)
			.then(localData=>{
				//if (localData.TICKERLIST && typeof localData.TICKERLIST === 'string') {
				//	localData.TICKERLIST = localData.TICKERLIST.split(", ");
				//}
				//async call may not have updated tickerlist before retrieval happens, if we're given an uptodate one then override it.
				if (overrides) {
					Object.assign(localData, overrides);
				}
				if (localData.TICKERLIST) {
					this.tickers = localData.TICKERLIST.split(", ");
					self.fireTickerUpdate.bind(self)();				
				}
				self.localContentData = localData;
				self.fireLocalUpdate.bind(self)();
				if (then) {
					then();
					
				}
			});

	}

	updateLocalData(field, data, src, dataName) {
		const self = this;
		if (!self.tickers || self.tickers.length < 1) {
			console.log("No ticker so can't save");
			//can't save anything without a ticker!
			this.dataProvider.topMessageSetter({noDocument:true})
			return;
		}
		if (!self.templateName) {
			this.dataProvider.topMessageSetter({noDocument:true})
			console.log("No template so can't save");
			//can't save anything without a ticker!
			return;
		}
		let identifier = self.tickers.join(',');
		if (self.templateType === 'selection') {
			identifier = this.tickerGroup;
		}
		if (!identifier || identifier === '') {
			this.dataProvider.topMessageSetter({noDocument:true})
			return;
		}
		this.dataProvider.topMessageSetter({saving:true})
		const obj = {};
		obj[field] = data;
		identifier = btoa	(identifier);
		this.props.remote.put("/savev2/" + identifier + "/" + self.templateName, obj).then(response => {
			if (response.success) {
				self.fireSaveSucceeded(response.data);
			}
			if (!response.success) {
				self.fireSaveError();
			}
		}).catch(err => {
			console.log("Catch response, ", err);
			self.fireSaveError();
		});
		this.localContentData[field] = data;
		if (src) {
			this.fireLocalUpdate(src, dataName);
		}
	}

	getVersions(limit) {
		const self = this;
		const versionBase = this.getVersionBase();
		if (versionBase) {
			return this.props.remote.get(versionBase+"?limit="+limit);
		} else {
			return Promise.resolve([]);
		}
	}

	getVersionBase() {
		const self = this;
		if (!self.tickers || self.tickers.length < 1) {
			return null;
		}
		if (!self.templateName) {
			return null;
		}
		let identifier = self.tickers.join(',');
		if (self.templateType === 'selection') {
			identifier = this.tickerGroup;
		}
		if (!identifier || identifier === '') {
			return null;
		}
		identifier = btoa	(identifier);
		return "/versions/" + identifier + "/" + self.templateName;
	}

	deleteVersion(id, num) {
		console.log("Deleting version ", id);
		const base = this.getVersionBase();
		if (!base) {
			return Promise.resolve({success:false});
		} 
		return this.props.remote.delete(base+"/"+id+"?limit="+num);
	}

	restoreVersion(id) {
		console.log("Restoring version ", id);
		const base = this.getVersionBase();
		if (!base) {
			return Promise.resolve({success:false});
		} 
		return this.props.remote.post(base, {version:id});
	}

	lockVersion(id, lock, num) {
		console.log("locking version ", id, lock);
		const base = this.getVersionBase();
		if (!base) {
			return Promise.resolve({success:false});
		} 
		return this.props.remote.put(base+"/"+id, {lock:lock, limit:num});
	}

	setSaveErrorListener(listener) {
		this.saveErrorListener = listener;
	}

	setSaveSizeListener(listener) {
		this.saveSizeListener = listener;
	}

	unsetSaveSizeListener() {
		this.saveSizeListener = null;
	}

	fireSaveError() {
		this.dataProvider.topMessageSetter({error:true})
		if (this.saveErrorListener) {
			this.saveErrorListener(true);
		}
	}
	fireSaveSucceeded(size) {
		this.dataProvider.topMessageSetter({lastSaved:new Date()})
		if (this.saveErrorListener) {
			this.saveErrorListener(false);
		}
		if (this.saveSizeListener) {
			this.saveSizeListener(size);
		}
	}

	fireUpdate() {
		this.listeners.forEach(list=>{
			list();
		});
	}

	fireLocalUpdate(src, dataName) {
		this.localListeners.forEach(list=>{
			list(src, dataName);
		});
	}

	fireTickerUpdate() {
		this.tickerListeners.forEach(list=>{
			list(this.tickers);
		});
	}

	addLocalListener(list) {
		this.localListeners.push(list);
	}

	removeLocalListener(list) {
		console.log("Removing a local listener ", list);
		this.localListeners = this.localListeners.filter(l=>l !== list);
	}

	addListener(list) {
		this.listeners.push(list);
	}

	removeListener(list) {
		this.listeners = this.listeners.filter(l=>l !== list);
	}

	removeTickerListener(list) {
		this.tickerListeners = this.listeners.filter(l=> l !== list);
	}

	addTickerListener(list) {
		this.tickerListeners.push(list);
	}

	/**
	 * Traverses an object, populating fields with all required fields.
	 * 
	 * @param {*} obj The object to recursively traverse 
	 * @param {Set} consensusFields  set to populate
	 * @param {Set} indexFields 
	 * @param {Set} brokerFields 
	 */
	traverse(obj, consensusFields, indexFields, brokerFields) {

		const self = this;
		//We'll just look for any string with a matching field(s)
		Object.keys(obj).forEach(key=>{
			const val = obj[key];
			if (typeof val === "object" && val !== null && val !== undefined) {
				this.traverse(val, consensusFields, indexFields, brokerFields);
			} else if (typeof val === "string") {
				let target = null;
				if (key === "value") {
					target = consensusFields;
				} else if (key === "brokerValue") {
					target = brokerFields;
				}
				if (target !== null) {
					val.replace(/\$\{(.*?)\}/g, function(match, quantity) {
						//Remove the ${ }
						//quantity = quantity.substring
						if (quantity.startsWith("date:")) {
							//Do nothing
						} else {
							if (quantity.startsWith("index:")) {
								indexFields.add(quantity.substring(6));
							} else {
								//TODO format number quantities
								if (quantity.startsWith("uppercase"))
									quantity = quantity.substring(10);

								if (quantity.startsWith("formatDate:")) {
									const index = quantity.indexOf(":", 12);
									quantity = quantity.substring(index + 1);
								}

								if (quantity.startsWith("coalesce")) {
									const index = quantity.indexOf(":", 7);
									quantity = quantity.substring(index + 1);
								}

								if (quantity.startsWith("format:")) {
									const index = quantity.indexOf(":", 10);
									quantity = quantity.substring(index + 1);
								}
								//Handle Calculations
								self.parseComplexQuantity(quantity, target);
							}
						}
						return quantity;
					});
				}
			}
		});
	}

	parseComplexQuantity(quantity, target) {
		//NB:  eslint reports no-useless-escape when the escape is _not_ useless.  These regexs are correct :)
		//Firstly remove any - that are part of qualifiers
		quantity = quantity.replace(/(\[[^\]]*?)\-([^\]]*?\])/g, "$1#$2")			//eslint-disable-line no-useless-escape
		//Now split by operators
		const bits = quantity.split(/[\*\+\-\/\(\)\s]+/);							//eslint-disable-line no-useless-escape
		bits.forEach(bit => {
			//Empty?
			if (bit.length === 0)
				return;
			//Number?
			if (bit.match(/^\-?\d*\.?\d*$/))										//eslint-disable-line no-useless-escape
				return;
			//And put back the -s
			bit = bit.replace(/#/, "-");
			const match = bit.match(/\[(\-?\d+)\~(\-?\d+)\]/);						//eslint-disable-line no-useless-escape
			if (match) {
				const start = match[1];
				const end = match[2];
				for (let i=start; i<=end; i++) {
					target.add(bit.replace(match[0], "[" + i + "]"));
				}
			} else {
				target.add(bit);
			}
		});
	}
}

class DataController extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			hasError: false
		}
		const {setTopMessage} = props;
		this.provider = {
			request: (data) => Promise.all(
				data.instruments.map(instrument => {
					return Promise.resolve(this.props.remote.post(`/data/${instrument}/get`, data.fields.map(field=>field.name)));
				})
			).then(responses => {
				//const json = {data:[], headers:[]};
				const eikonFormat = {
					data: [""],
					headers: [{field: ""}]
				};
				for (const [index, value] of responses.entries()) {
					for (const [dataIndex] of value.headers.entries()) {
						if (responses.length > 1) {
							eikonFormat.headers.push({field: value.headers[dataIndex].field + "<"+data.instruments[index]+">"});
						} else {
							eikonFormat.headers.push({field: value.headers[dataIndex].field});
						}
						eikonFormat.data.push(value.data[dataIndex] ? value.data[dataIndex] : "");
					} 
				}
				return new Promise(resolve => resolve(JSON.stringify(eikonFormat)));
			}),
			topMessageSetter: setTopMessage
		}

		this.data.setRemote(this.provider);
	}
	
	data = new Data(this.props);

	componentDidCatch(error, info) {
		this.setState(state => ({ ...state, hasError: true }))
	}


	render() {
		if (this.state.hasError) { return <PageContentBodyGrid rowGap="xl" showScrollbar><PageGridItem colSpan="12"><div style={{"margin":"20px 20px"}}><AlertCircle color={"#E66780"}/> There has been an unrecoverable error.  To prevent loss of data, all editors have been closed.  Please reload the application to continue.</div>
			</PageGridItem></PageContentBodyGrid>}


		return <DataContext.Provider value={this.data}>{this.props.children}</DataContext.Provider>
	}

}

export default withRemote(DataController);