import React, { useEffect, useState } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import PageGridItem from '../../Shared/Page/PageGridItem';
import CompanyGeneralDetails from './CompanyGeneralDetails';
import CompanyKeyContact from './CompanyKeyContact';
import CompanyBillingInformation from './CompanyBillingInformation';
import useWindowDimensions, { useRemote } from '../../../Utils/Utils';
import { useParams, useHistory } from 'react-router-dom';
import CompanyUsersList from './CompanyUsersList';
import withRouteChange from "@threeskye/route-change";
import { ROUTE_PATHS, PAGES } from '../../../InternalApiApp';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import { toastDanger, UndoToastMessage, toast } from '../../../components/popups/Toast';
import { debounce } from 'throttle-debounce';
import { parseLocationDetails, undoDeleteCompany } from '../../../Utils/Utils';

const blankCompany = {
	"name": "",
	"locations": [{ location: null }],
	"DistributionList": "0",
	"id": null,
	"billedTo": null,
	"billingAddress": null,
	"billingCycle": null,
	"billingInformation": null,
	"companyId": 1,
	"nextPayment": null,
	"phone": null,
	"website": null
}

const CompanyPage = ({ changeRoute }) => {
	const [editMode, setEditMode] = useState(false);
	const [company, setCompany] = useState(blankCompany)
	const [contacts, setContacts] = useState([]);
	const [countries, setCountries] = useState([]);
	const [createMode, setCreateMode] = useState(false);
	const [allOrgNames, setAllOrgNames] = useState([])
	const [nameValid, setNameValid] = useState(true)
	const [companyNameTouched, setCompanyNameTouched] = useState(false)

	const { companyId } = useParams()
	const { width } = useWindowDimensions();
	const history = useHistory()
	const remote = useRemote();
	const params = new URLSearchParams(window.location.search);
	const createQuery = params.get('create');
	const editQuery = params.get('edit')

	useEffect(() => { createMode === true && setEditMode(true) }, [createMode])

	useEffect(() => {
		switch (companyId) {
			case "create":
				remote.get('/crm/countries').then(setCountries)
				setCreateMode(true)
				setCompany(blankCompany)
				setNameValid(false)
				remote.get(`/crm/organisations`)
					.then((response) => response.map((org) => org.name.toLowerCase()))
					.then(setAllOrgNames)
				break;
			default:
				getCompany()
				if (createQuery) {
					setCreateMode(true)
				}
				if (editQuery) {
					setEditMode(true)
				};
				break;
		}
		remote.get('/crm/countries').then(setCountries)
		companyId !== "create" && remote.get(`/crm/organisations/${companyId}/contacts`).then(setContacts);

	}, [])

	const debouncedValidation = debounce(100, (name) => {
		if (companyNameTouched === false) setCompanyNameTouched(true)
		if (name.length > 0 && allOrgNames.includes(name.toLowerCase())) { setNameValid(false) }
		else setNameValid(true)
	});

	const getCompany = () => {
		remote.get(`/crm/organisations/${companyId}`).then(c => {
			setCompany(parseLocationDetails(c));
			remote.get(`/crm/organisations`)
				.then((response) => response.map((org) => org.name.toLowerCase()))
				.then((response) => {
					let nameList = response.filter((name) => name !== c.name.toLowerCase())
					setAllOrgNames(nameList)
				});
		})
	}
	const editCompany = () => {
		const companyToPush = { ...company, locations: [company.locations] };
		remote.put(`/crm/organisations/${companyId}`, companyToPush).then((response => {
			setCompany({ ...response.data, locations: response.data.locations[0] })
			setEditMode(false)
		}))
	}
	const createCompany = () => {
		remote.post(`/crm/organisations`, { ...company, locations: [company.locations] })
			.then((companyResponse) => {
				//save members
				contacts.map(contact => {
					remote.put(`/crm/organisations/${companyResponse.data.id}/contacts/${contact.id}`)
				})
				return companyResponse
			})
			.then((response => {
				setEditMode(false)
				setCreateMode(false)
				history.push(ROUTE_PATHS[PAGES.CRM] + "/companies/company/" + response.data.id)
				setCompany({ ...response.data, locations: response.data.locations[0] })
			}))
	}
	const returnToPage = (deletedCompany) => {changeRoute(ROUTE_PATHS[PAGES.CRM] + "/companies/company/" + deletedCompany.id)}
	const removeCompany = (e) => {
		e.stopPropagation();
		remote.delete(`/crm/organisations/${company.id}`)
			.then(() => {
				toast(<UndoToastMessage message="Company removed" onClick={() => { undoDeleteCompany({ ...company, locations: [company.locations] }, contacts, returnToPage )}} />, {autoClose: 10000})
				changeRoute(ROUTE_PATHS[PAGES.CRM] + "/companies")
			})
			.catch(() => {
				toastDanger("Company could not be removed")
			})
	}

	return (
		<Page fixedBanner>
			<PageContentBanner divider>
				<ActionButtonsBanner
					handleBackOnClick={() => changeRoute(ROUTE_PATHS[PAGES.CRM] + "/companies")}
					editMode={editMode}
					createMode={createMode}
					edit={{ onClick: () => setEditMode(true) }}
					duplicate={{ onClick: () => setCreateMode(true) }}
					remove={{ onClick: (e) => removeCompany(e) }}
					cancel={{
						onClick: () => {
							setEditMode(false);
							if (createMode) {
								changeRoute(ROUTE_PATHS[PAGES.CRM] + "/companies")
							}
							else getCompany();
						}
					}}
					save={{
						disabled: !nameValid,
						onClick: () => {
							if (createMode) {
								createCompany();
							} else {
								editCompany();
							}
						},
					}}
				/>
			</PageContentBanner>
			<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				{/* grid container for the user details section */}
				<PageGridItem container col="1 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
						{/* General details */}
						<CompanyGeneralDetails
							editMode={editMode}
							company={company}
							editCompany={(content) => setCompany(content)}
							contacts={contacts}
							validateName={debouncedValidation}
							nameValid={nameValid}
							setCompanyNameTouched={setCompanyNameTouched}
							companyNameTouched={companyNameTouched}
						/>
						{/* Company key contact details */}
						{contacts.filter(c => c.keyContact).map(c => <CompanyKeyContact key={c.id} contact={c} />)}
					</PageContentBodyGrid>
				</PageGridItem>
				{/* grid container for the distribution lists details section */}
				{/* Hiding distribution list as per standup meeting 20/01/2022  */}
				{/* <PageGridItem container col="7 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"}>
						<PageGridItem col="1 / span 6">
							<DetailPageHeader header="Distribution Lists" />
						</PageGridItem>
					</PageContentBodyGrid>
				</PageGridItem> */}
				{/* grid container for the users list section */}
				<PageGridItem container col="7 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"}>
						{/* Company billing details */}
						<CompanyBillingInformation
							editMode={editMode}
							company={company}
						/>
					<PageGridDivider col="1 / span 6" />

						{!createMode && <CompanyUsersList editMode={editMode} contacts={contacts} company={company} setContacts={setContacts} />}
					</PageContentBodyGrid>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page >
	);
}

export default withRouteChange(CompanyPage);