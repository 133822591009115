import React from 'react';
import Modal from '../Modals/Modal';
import TickerSectorImage from '../TickerSectorImage';
import "./ImageBrowserModal.scss";
import ageImage1 from "../../images/sectors/Aged Care & Retirement.png"
import ageImage2 from "../../images/sectors/Aged Care & Retirement 2.png"
import bankImage1 from "../../images/sectors/Banking.png"
import dairyImage1 from "../../images/sectors/Dairy.png"
import dairyImage2 from "../../images/sectors/Dairy 2.png"
import dairyImage3 from "../../images/sectors/Dairy 3.png"
import Divider from '../Divider';
import { Plus } from 'react-feather';
import FlexWrapper from '../../layouts/FlexWrapper';
import Button from '../Buttons/Button';
import SidebarExtensionLink from '../SideBar/SideBarExtension/SidebarExtensionLink';
import ImageGalleryWrapper from './ImageGalleryWrapper';
import { useState } from 'react';

const systemImages = [
	{header: "Age Care & Retirement", images: [ageImage1, ageImage2]},
	{header: "Banking", images: [bankImage1]},
	{header: "Dairy", images: [dairyImage1, dairyImage2, dairyImage3]},
	{header: "Dairy", images: [dairyImage1, dairyImage2, dairyImage3]},
	{header: "Dairy", images: [dairyImage1, dairyImage2, dairyImage3]},
];

const cipImages = [
	{header: "Craigs IP", images: []}
];

const ImageBrowserModal = ({ handleClose }) => {
	let selectedImage = ageImage1;
	const [currentView, setCurrentView] = useState("system");

	return (
		<Modal className="image-browser-modal" padding="none" handleClose={handleClose}>
			<div className="image-browser-modal-sidebar">
				{/* Selected Image */}
				<div className="selected-image-container">
					<TickerSectorImage hoverUpload image={selectedImage} size="xxl" />
					<Button variant="transparent" icon={<Plus className="upload-icon" />} className="upload-button">
						Upload
					</Button>
				</div>
				<Divider spacingTop="s" spacingBottom="s" />
				{/* Sidebar Menu */}
				<FlexWrapper gap="m" direction="column" className="p-m sidebar-categories">
					<SidebarExtensionLink onClick={() => setCurrentView("system")} active={currentView === "system"}>
						3SKYE
					</SidebarExtensionLink>
					<SidebarExtensionLink onClick={() => setCurrentView("company")} active={currentView === "company"}>Craigs IP</SidebarExtensionLink>
				</FlexWrapper>
				<div className="finish-button-wrapper">
					<Button size="small" fullWidth className="finish-button" onClick={handleClose}>
						Finish
					</Button>
				</div>
			</div>
			{/* Image gallery */}
			<div className="image-browser-modal-gallery">
				<ImageGalleryWrapper images={currentView === "system" ? systemImages : cipImages} />
			</div>
		</Modal>
	);
};

export default ImageBrowserModal;