import { format, compareAsc, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { AlertCircle, BarChart2, Eye, Mail, Send, Users } from "react-feather";
import { useHistory, useParams } from "react-router"
import SearchTermFilter from "../../components/Input/SearchTermFilter";
import LoadingIcon from "../../components/LoadingIcon";
import Table from "../../components/Table/Table";
import useWindowDimensions, { useRemote } from "../../Utils/Utils";
import FlexWrapper from "../FlexWrapper";
import DetailSectionHeader from "../Shared/DetailSection/DetailSectionHeader";
import Page from "../Shared/Page/Page"
import { PageContentBodyGrid } from "../Shared/Page/Page";
import PageGridItem from "../Shared/Page/PageGridItem";
import { RawCraigsLibraryTable } from "./CraigsLibraryTable";
import DetailField from "../Shared/DetailSection/DetailField";

const DeliveryDetails = () => {

	const { deliveryId } = useParams();

	const [analytics, setAnalytics] = useState({});
	const [portal, setPortal] = useState({});
	const [delivery, setDelivery] = useState([])
	const [craigsDocs, setCraigsDocs] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState(false)
	const { width } = useWindowDimensions();
	const [filteredData, setFilteredData] = useState([])
	const [expanded, setExpanded] = useState({})

	const [showDelivered, setShowDelivered] = useState(true);
	const [showErrored, setShowErrored] = useState(true);
	const [showOpened, setShowOpened] = useState(true);
	
	const [loading, setLoading] = useState(true);

	const xl = width > 2250;
	const l = width > 1000;

	const remote = useRemote();
	const history = useHistory();

	useEffect(() => {
		if (!deliveryId) {
			return;
		}
		remote.get(`/documents/document/${deliveryId}/details`).then(details => {
			setCraigsDocs([details.summary])
			setAnalytics(details.analytics);
			setPortal(details.portal);
			const distributions = details.distribution;
			setLoading(false);
			if (distributions && distributions.campaignId) {
				setDelivery(distributions);
				setFilteredData(distributions.distributions);
				return;
			}	
		});
	},[deliveryId])


	if (loading) {
		return <LoadingIcon centered size={40} />
	}

	const getEventDate = (event) => {
		event.forEach(e => {
			e.parsedDate = parseISO(e.eventTime);
		});
		const sorted = event.map(e => e.parsedDate).sort(compareAsc);
		return format(sorted[0], 'HH:mm dd/MM/yyyy');
	}

	const formatDate = (date) => {
		return format(parseISO(date), 'HH:mm dd/MM/yyyy');
	} 

	const showHide = (id) => {
		const newExpanded = {...expanded};
		newExpanded[id] = !expanded[id];
		setExpanded(newExpanded);
	}
	
	const colWidths = [40 + "%", 20 + "%", 20 + "%", 20 + "%" ];

	const dataMap = filteredData && filteredData.map((distribution, idx) => {

		const user = distribution.contact;
		const delivered = distribution.events.filter(e => e.event === 'delivered');
		const dropped = distribution.events.filter(e => e.event === 'dropped' || e.event === 'bounce');
		const read = distribution.events.filter(e => e.event === 'open');


		//TODO @Charlie - fix up icons please.
		return (
			<React.Fragment key={distribution.distributionId}>
			<tr key={idx} className={"cursor-pointer"} onClick={() => showHide(distribution.distributionId)}>
				<td>
					<div className="truncate">
						{user.firstName + " " + user.lastName} <span className="extension-label ml-s">{user.email}</span>{" "}
					</div>
				</td>
				<td>
					{delivered && delivered.length > 0 && <>{getEventDate(delivered)}</>  }
				</td>
				<td>
					{dropped && dropped.length > 0 && <>{getEventDate(dropped)}</>  }
				</td>
				<td>
					{read && read.length > 0 && <>{getEventDate(read)}</>  }
				</td>
			</tr>
			{expanded[distribution.distributionId] && <>
				{
					distribution.events.map((e,idx) => <tr  key={distribution.distributionId+"expanded"+e.eventTime+idx}>
						<td>{formatDate(e.eventTime)}</td><td>{e.event}</td><td colSpan="2">{e.reason}</td>
					</tr>)
				}
				</>}
			</React.Fragment>
		);
	})

	const headers = ["",<FlexWrapper gap="s"><Send style={{transform: "translateY(-1px)"}} color="#56D1C3" width="16" height="16" /> Delivered</FlexWrapper>,<FlexWrapper gap="s"><AlertCircle  width="16" height="16" style={{transform: "translateY(-1px)"}} color="#E66780"/> Failed</FlexWrapper>,<FlexWrapper gap="s"><Mail  width="16" height="16" style={{transform: "translateY(-1px)"}} color="blue"/> Read</FlexWrapper>];

	const filter = (doc, which) => {
		if (which === 'sent') {
			setShowDelivered(!showDelivered)
		};
		if (which === 'failed') {
			setShowErrored(!showErrored)
		};
		if (which === 'opened') {
			setShowOpened(!showOpened)
		};
	}

	const pageViewDataMap = Object.keys(analytics.pageViewEvents).map(key => <tr key={`page-${key}`}><td>{key}</td><td>{analytics.pageViewEvents[key]} </td></tr>)

	return <Page noBanner>
		<PageContentBodyGrid rowGap="xl">
			<PageGridItem fullWidth showScrollbar>
				<RawCraigsLibraryTable craigsDocs={craigsDocs} onDeliveryClick={filter}/>
			</PageGridItem>
			<PageGridItem col={width > 1400 ? "1 / span 4" : "1 / span 12"} gap="m" wrap divider>
				<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
					<PageGridItem col="1 / span 6">
						<DetailSectionHeader icon={<BarChart2 />} header={"Portal Analytics"} />
					</PageGridItem>
					<PageGridItem col={width < 1400 ? "1 / span 2" : "1 / span 6"}>
						<DetailField
							label="Unique Views"
							value={<FlexWrapper gap="s" align="center"><Eye size={16} className="colour-text-primary" /> {analytics.viewEvents}</FlexWrapper>}
						/>
					</PageGridItem>
					<PageGridItem col={width < 1400 ? "3 / span 4" : "1 / span 6"}>
						<FlexWrapper direction="column" gap="l" align="start">
							<label className="colour-text-label-grey transition-fade-up">Page View Distribution</label>
							<Table colWidths={['50%','50%']} headers={["Page","Views"]} dataMap={pageViewDataMap} />
						</FlexWrapper>
					</PageGridItem>
				</PageContentBodyGrid>
			</PageGridItem>
			<PageGridItem col={width > 1400 ? "5 / span 8" : "1 / span 12"} showScrollbar>
				<PageContentBodyGrid rowGap="l" gridColCount="6" subGrid divider={width < 1400 ? "top" : "left"}>
					<PageGridItem col="1 / span 6">
						<FlexWrapper gap="l" fullWidth>
							<DetailSectionHeader icon={<Users />} header={"Recipients (" + (delivery && delivery.distributions ? delivery.distributions.length : 0) + ")"} />
							<SearchTermFilter
								size="small"
								width={xl ? "l" : l ? "m" : "s"}
								placeholder={`Search`}
								dataSet={delivery.distributions}
								setFilteredData={setFilteredData}
								path={["contact.firstName", "contact.lastName", "contact.email"]}
								hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
							/>
						</FlexWrapper>
					</PageGridItem>
					<PageGridItem col="1 / span 6">
						<Table colWidths={colWidths} headers={headers} dataMap={dataMap} />
					</PageGridItem>
				</PageContentBodyGrid>
			</PageGridItem>
		</PageContentBodyGrid>
	</Page>

}

export default DeliveryDetails;