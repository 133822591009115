import React from 'react';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import Table from '../../../components/Table/Table';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import DetailField from '../../Shared/DetailSection/DetailField';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { useState } from 'react';
import { useRemote } from '../../../Utils/Utils';
import Tooltip from '../../../components/popups/Tooltip';
import { Eye, Mail } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';
import FlexWrapper from '../../FlexWrapper';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import Divider from '../../../components/Divider';
import IconCounter from '../../../components/IconCounter';
import TickerSectorItem from '../../../components/TickerSectorItem';
import PageContentToggle from '../../../components/Menus/PageContentToggle';
import AnalyticsMetricFilter from '../../../components/Analytics/AnalyticsMetricFilter';

const AnalyticsCompanyPage = ({ changeRoute }) => {
	const remote = useRemote();

	const dummyCompany = {
		name: "Acme Co",
		id: 123456,
		analytics: { watching: 11, emailViews: 22, portalViews: 33, pdfDownload: 44, modalDownload: 55 },
		research: [
			{
				ticker: { name: "Auckland International Airport Limited", code: "AIA.NZ", ric: "AIA.NZ", image: "https://research-portal-test.3skye.com/icons/tickers/AIA.NZ.png" },
				publishedDate: "22/01/2022",
				title: "Core strong, market volatile",
				author: "Carmen Sandiego",
				download: "modal",
				viewedIn: "",
				device: "desktop",
				analytics: { watching: 1, emailViews: 2, portalViews: 3, pdfDownload: 4, modalDownload: 5 },
			},
			{
				ticker: { name: "Mercury", code: "MEL.NZ", ric: "MEL.NZ", image: "https://research-portal-test.3skye.com/icons/tickers/MEL.NZ.png" },
				publishedDate: "22/01/2022",
				title: "Core strong, market volatile",
				author: "Carmen Sandiego",
				download: "",
				viewedIn: "portal",
				device: "desktop",
				analytics: { watching: 5, emailViews: 4, portalViews: 3, pdfDownload: 2, modalDownload: 1 },
			},
			{
				ticker: { name: "Auckland International Airport Limited", code: "AIA.NZ", ric: "AIA.NZ", image: "https://research-portal-test.3skye.com/icons/tickers/AIA.NZ.png" },
				publishedDate: "22/01/2022",
				title: "Core strong, market volatile",
				author: "Carmen Sandiego",
				download: "pdf",
				viewedIn: "",
				device: "mobile",
				analytics: { watching: 2, emailViews: 4, portalViews: 6, pdfDownload: 8, modalDownload: 10 },
			},
		],
		users: [
			{
				user: { firstName: "Coraline", lastName: "Jones", email: "Coraline.jones@gmail.com", id: 123456 },
				analytics: { watching: 11, emailViews: 22, portalViews: 33, pdfDownload: 44, modalDownload: 55 },
			},
			{
				user: { firstName: "Gary", lastName: "Oak", email: "Gary.oak@acme.com", id: 123456 },
				analytics: { watching: 1, emailViews: 2, portalViews: 3, pdfDownload: 4, modalDownload: 5 },
			},
		]
	};

	// State
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [hasSearchTerm, setHasSearchTerm] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentView, setCurrentView] = useState("research");
	const [currentFilter, setCurrentFilter] = useState("");

	// Table data
	const researchColWidths = ["10%", "15%", "20%", "10%", "10%", "10%", "10%"];
	const researchHeaders = [
		"Time/Date",
		"Ticker",
		"Title",
		{
			text: (
				<Tooltip label="Email Views">
					<Mail className="cursor-pointer colour-text-purple" size={16} />
				</Tooltip>
			),
			className: "text-align-center",
		},
		{
			text: (
				<Tooltip label="Portal Views">
					<Eye className="cursor-pointer colour-text-primary" size={16} />
				</Tooltip>
			),
			className: "text-align-center",
		},
		{
			text: (
				<Tooltip label="PDF Downloads">
					<PdfIcon noHover className="cursor-pointer" size={16} />
				</Tooltip>
			),
			className: "text-align-center",
		},
		{
			text: (
				<Tooltip label="Model Downloads">
					<XlsIcon noHover className="cursor-pointer" size={16} />
				</Tooltip>
			),
			className: "text-align-center",
		},
	];

	const usersColWidths = ["20%", "20%", "10%", "10%", "10%", "10%"];
	const usersHeaders = [
		"Name",
		"Email",
		{
			text: (
				<Tooltip label="Email Views">
					<Mail className="cursor-pointer colour-text-purple" size={16} />
				</Tooltip>
			),
			className: "text-align-center",
		},
		{
			text: (
				<Tooltip label="Portal Views">
					<Eye className="cursor-pointer colour-text-primary" size={16} />
				</Tooltip>
			),
			className: "text-align-center",
		},
		{
			text: (
				<Tooltip label="PDF Downloads">
					<PdfIcon noHover className="cursor-pointer" size={16} />
				</Tooltip>
			),
			className: "text-align-center",
		},
		{
			text: (
				<Tooltip label="Model Downloads">
					<XlsIcon noHover className="cursor-pointer" size={16} />
				</Tooltip>
			),
			className: "text-align-center",
		},
	];

	const researchDataMap = (data) =>
		data &&
		data.map((data, idx) => {
			return (
				<tr key={idx} className="cursor-pointer">
					{/* User details */}
					<td>{data.publishedDate}</td>
					<td>
						<TickerSectorItem image={data.ticker.image} label={data.ticker.code} />
					</td>
					<td>{data.title}</td>
					<td className="text-align-center">{data.analytics.emailViews}</td>
					<td className="text-align-center">{data.analytics.portalViews}</td>
					<td className="text-align-center">{data.analytics.pdfDownload}</td>
					<td className="text-align-center">{data.analytics.modalDownload}</td>
				</tr>
			);
		});
	
	const usersDataMap = (data) =>
		data &&
		data.map((data, idx) => {
			return (
				<tr key={idx} className="cursor-pointer">
					{/* User details */}
					<td>{data.user.firstName + data.user.lastName}</td>
					<td>{data.user.email}</td>
					<td className="text-align-center">{data.analytics.emailViews}</td>
					<td className="text-align-center">{data.analytics.portalViews}</td>
					<td className="text-align-center">{data.analytics.pdfDownload}</td>
					<td className="text-align-center">{data.analytics.modalDownload}</td>
				</tr>
			);
		});

		const filterOnclick = (value) => {
			return currentFilter === value ? setCurrentFilter("") : setCurrentFilter(value);
		}

	return (
		<Page fixedBanner showScrollbar>
			{/* Modal */}
			<PageContentBanner divider gap="m">
				<ActionButtonsBanner handleBackOnClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/users")} />
			</PageContentBanner>
			<PageContentBodyGrid showScrollbar>
				<PageGridItem colSpan="12">
					<FlexWrapper gap="xl">
						<FlexWrapper gap="xs" direction="column" align="flex-start">
							<label>Company Name</label>
							<p className="font-weight-semi-bold">{dummyCompany.name}</p>
						</FlexWrapper>
						<Divider vertical height={40} />
						<AnalyticsMetricFilter
							onClick={() => filterOnclick("emailViews")}
							active={currentFilter === "emailViews"}
							inactive={currentFilter !== "" && currentFilter !== "emailViews"}
							label="Email Views"
							status="read"
							icon={<Mail />}
							count={dummyCompany.analytics.emailViews}
						/>
						<AnalyticsMetricFilter
							onClick={() => filterOnclick("portalViews")}
							active={currentFilter === "portalViews"}
							inactive={currentFilter !== "" && currentFilter !== "portalViews"}
							label="Portal Views"
							icon={<Eye />}
							count={dummyCompany.analytics.portalViews}
						/>
						<AnalyticsMetricFilter
							onClick={() => filterOnclick("pdfDownload")}
							active={currentFilter === "pdfDownload"}
							inactive={currentFilter !== "" && currentFilter !== "pdfDownload"}
							label="PDF Downloads"
							icon={<PdfIcon noHover />}
							count={dummyCompany.analytics.pdfDownload}
						/>
						<AnalyticsMetricFilter
							onClick={() => filterOnclick("modalDownload")}
							active={currentFilter === "modalDownload"}
							inactive={currentFilter !== "" && currentFilter !== "modalDownload"}
							label="Model Downloads"
							icon={<XlsIcon noHover />}
							count={dummyCompany.analytics.modalDownload}
						/>
					</FlexWrapper>
				</PageGridItem>
				<PageGridDivider colSpan="12" />
				<PageGridItem colSpan="12">
					<FlexWrapper gap="m">
						<PageContentToggle labels={[
							{ label: "Research", active: currentView === "research", onClick: () => setCurrentView("research")},
							{ label: "Users", active: currentView === "users", onClick: () => setCurrentView("users")},
						]} />
						<Divider vertical height={24} />
						<SearchTermFilter
							size="small"
							width="l"
							placeholder={`Search ${loading ? "" : "(" + data.length + ")"}`}
							isClearable
							dataSet={data}
							setFilteredData={setFilteredData}
							path={["firstName", "lastName", "email"]}
							hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
						/>
					</FlexWrapper>
				</PageGridItem>
				<PageGridItem colSpan="12">
					<Table
						minWidth={1000}
						colWidths={currentView === "research" ? researchColWidths : usersColWidths}
						headers={currentView === "research" ? researchHeaders : usersHeaders }
						dataMap={currentView === "research" ? researchDataMap(hasSearchTerm ? filteredData : dummyCompany.research) : usersDataMap(hasSearchTerm ? filteredData : dummyCompany.users)}
						noResults={{ message: "No results found" }}
						dataLoading={loading}
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(AnalyticsCompanyPage);