import React from 'react';
import { User } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import PhoneNumberDetailField from '../../Shared/DetailSection/PhoneNumberDetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Checkbox from '../../../components/Checkbox';
import SelectDetailField from '../../Shared/DetailSection/SelectDetailField';
import Tooltip from '../../../components/popups/Tooltip';

const UserGeneralDetails = ({ editMode, user, editUser, setValid, countries }) => {

	const countryOptions = countries.map(country => { return { label: country.name, value: country.name } })

	const getPhoneNumber = (phone) => {
		return `${phone.idd ? phone.idd : ""} ${phone.number ? phone.number : ""}`
	}

	return (
		<React.Fragment>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="General Details" icon={<User />} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					bold
					editMode={editMode} autoFocus
					onChange={(e) => editUser({ ...user, firstName: e.target.value })}
					label="First Name"
					value={user.firstName ? user.firstName : ""}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					bold
					editMode={editMode}
					onChange={(e) => editUser({ ...user, lastName: e.target.value })}
					label="Last Name"
					value={user.lastName ? user.lastName : ""}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					editMode={editMode}
					onChange={(e) => editUser({ ...user, preferredName: e.target.value })}

					label="Preferred Name"
					value={user.preferredName ? user.preferredName : ""}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					editMode={editMode}
					onChange={(e) => editUser({ ...user, role: e.target.value })}

					label="Title"
					value={user.role ? user.role : ""}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					editMode={editMode}
					onChange={(e) => editUser({ ...user, salutation: e.target.value })}

					label="Salutation"
					value={user.salutation ? user.salutation : ""}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					editMode={editMode}
					onChange={(e) => editUser({ ...user, email: e.target.value })}
					label="Email"
					value={user.email ? user.email : ""}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				{editMode
					? <PhoneNumberDetailField
						onChange={(e) => editUser({ ...user, phone: e })}
						value={user.phone}
						label="Mobile"
						setValid={setValid}
					/>
					: <DetailField
						editMode={editMode}
						onChange={(e) => editUser({ ...user, phone: e.target.value })}
						label="Mobile"
						value={user.phone ? getPhoneNumber(user.phone) : ""}
					/>
				}
			</PageGridItem>
			{/* <PageGridItem col="4 / span 3">
				<DetailField 
				editMode={editMode} label="Phone" value={user.phone.number ? user.phone.number : "" } />
			</PageGridItem> */}
			<PageGridItem col="4 / span 3">
				{/* <DetailField
					editMode={editMode}
					label="Location"
					value={user.location ? user.location : ""}
					onChange={(e) => editUser({ ...user, location: e.target.value })}
				/> */}
				<SelectDetailField
					editMode={editMode}
					label="Country of Residence"
					placeholder="Country of Residence"
					value={user.location ? {value: user.location, label: user.location} : null}
					options={countryOptions}
					onChange={(option) => editUser({ ...user, location: option.value})}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 6" alignItems="end">
				<Checkbox checked={user.emailSuppressed} onChange={(e) => editUser({ ...user, electronicDelivery: e.currentTarget.checked })} disabled={!editMode} label="User has opted out from all correspondence" tooltip={user.emailSuppressed ? "User has clicked the unsubscribe link" : ""} />
			</PageGridItem>
		</React.Fragment>
	);
}

export default UserGeneralDetails;