import React, { useEffect, useState } from 'react';
import Button from '../../../components/Buttons/Button';
import { Mail, Plus, } from 'react-feather';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import IconCounter from '../../../components/IconCounter';
import Table from '../../../components/Table/Table';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import useWindowDimensions, { formatDate, useRemote, undoDeleteDistributionList } from '../../../Utils/Utils';
import TableActionIconGroup from '../../../components/Table/TableActionIconGroup';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import TickerSectorItem from '../../../components/TickerSectorItem';
import { toast, toastDanger, UndoToastMessage } from '../../../components/popups/Toast';



const DistributionList = ({ changeRoute }) => {

	const colWidths = [40 + "%", 30 + "%", 10 + "%", 20 + "%"];
	const headers = ["Name", "Date Created", "Members", ""];

	const remote = useRemote();

	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState(false)
	//	const [showModal, setShowModal] = useState(false);
	const { width } = useWindowDimensions();

	useEffect(() => {
		remote.get("/crm/lists")
			.then((lists) => {
				let sortedLists = lists.sort((userA, userB) => {
					let nameA = userA.name
					let nameB = userB.name
					return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
				})
				return sortedLists
			})
			.then(setData);
	}, []);

	const addListToData = (list) => {
		let listWithoutRemovedList = data.filter((listToFilter) => list.name !== listToFilter.name)
		let listsToSort = [...listWithoutRemovedList, {...list, listId: list.id}]
		let sortedLists = listsToSort.sort((userA, userB) => {
			let nameA = userA.name
			let nameB = userB.name
			return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
		})
		setData(sortedLists)
	}

	const removeList = (e, list) => {
		e.stopPropagation();
		remote.get("/crm/lists/" + list.listId)
			.then((DistributionListToDelete) => {
				// setList(DistributionListToDelete.data.list); setContacts(DistributionListToDelete.data.contacts); setTickers(DistributionListToDelete.data.tickers)

				remote.delete(`/crm/lists/${list.listId}`)
					.then(() => {
						let newArray = []
						data.forEach((dataList) => {
							if (dataList.listId !== list.listId) {
								newArray.push(dataList)
							}
						})
						setData(newArray)
						toast(<UndoToastMessage message="Distribution List removed" onClick={() => { undoDeleteDistributionList(list, DistributionListToDelete.data.tickers, DistributionListToDelete.data.contacts, addListToData )}} />, {autoClose: 10000})
					})
					.catch(() => {
						toastDanger("List could not be removed")
					})
			});
	}

	const dataMap = (dataToMap) => dataToMap && dataToMap.map((list, idx) => {
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => changeRoute(ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/" + list.listId)}>
				<td>
					{/* {list.name}
					<span className="extension-label ml-s">{list.createdBy}</span> */}
					<TickerSectorItem label={list.name} extensionLabel={list.createdBy} image={list.logo}  />
				</td>
				<td>
					{list.createdDate && list.createdDate.includes("-") ? formatDate(list.createdDate.split("-")) : formatDate(list.createdDate)}
				</td>
				<td>
					<IconCounter icon={<Mail />} dataAvailable={true} type="read" count={list.members} />
				</td>
				<TableActionIconGroup
					download={{ hide: true }}
					edit={{ onClick: (e) => { e.stopPropagation(); changeRoute(ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/" + list.listId + "?edit=true") } }}
					duplicate={{ onClick: (e) => { e.stopPropagation(); changeRoute(ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/" + list.listId + "?create=true") } }}
					remove={{ onClick: (e) => removeList(e, list) }}
				/>
			</tr>
		);
	});

	return (
		<Page fixedBanner id="crm-distribution-page">
			{/* Old mailing list page below */}
			{/* <CraigsMailingListPage /> */}
			<PageContentBanner divider gap="m">
				{/* <Button icon={<UserPlus />} onClick={() => setModalShowing(true)}>Create User</Button> */}
				<Button icon={<Plus />} onClick={() => changeRoute(ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/create")} >{width < 900 ? "" : "New Distribution List"}</Button>
				{/* <SelectSearchField size="small" width="m" placeholder="Search lists" isClearable/> */}
				<SearchTermFilter
					size="small"
					width="l"
					placeholder="Search lists"
					isClearable
					dataSet={data}
					setFilteredData={setFilteredData}
					path={["createdBy", "createdDate", "name"]}
					hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
				/>
			</PageContentBanner>
			<PageContentBodyGrid showScrollbar>
				<PageGridItem colSpan="12">
					<Table minWidth={1000} colWidths={colWidths} headers={headers} dataMap={dataMap(hasSearchTerm ? filteredData : data)} />
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(DistributionList);