import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Eye } from 'react-feather';
import TableActionIcon from '../../components/Table/TableActionIcon';
import TickerSectorItem from '../../components/TickerSectorItem';
import PdfIcon from '../../images/icons/PdfIcon';
import XlsIcon from '../../images/icons/XlsIcon';
import PublicationSettings from './PublicationManagement/PublicationSettings';

const LibraryTableRow = ({data, idx, tickers, expandedRows, setExpandedRows, getImage, onCraigsDocClick, onDeliveryClick, models, modelsLoading}) => {
	const [document, setDocument] = useState({})
	const [imageKey, setImageKey] = useState(Date.now())

	const updateVisibility = (bool) => {
		let newDocument = { ...document, publishedToPortal: bool }
		setDocument(newDocument)
	}

	const updateImageKey = () => {
		setImageKey(Date.now())
	}

	useEffect(() => {
		setDocument(data)
	}, [data])

	const delivered = document.delivered;
	const sent = document.sent;
	const pdf = document.downloads;
	const model = document.modelDownloads;
	const email = document.opened;
	const portal = document.views;
	const failed = delivered > sent ? 0 : (sent - delivered);
	const datafiles = (document && document.files && document.files.filter(f => f.data)) || [];
	const defaultPdf = (document && document.files && document.files.filter(f => f.defaultPdf)) || [];
	const ticker = tickers && tickers.find(t => t.ric === document.ticker)

	return (<>
		<tr key={idx} className={expandedRows && expandedRows.includes(document.docId) ? "library-row-selected" : ""} >
			<td>
				<p>{document.date && format(parseISO(document.date), 'dd MMM yyyy')}</p>
			</td>
			<td className="pl-m">
				<Eye
					className={`published-icon ${document.publishedToPortal ? "active" : ""}`}
					onClick={() => {
						expandedRows && expandedRows.includes(document.docId)
							? setExpandedRows(expandedRows.filter((val) => val !== document.docId))
							: setExpandedRows([...expandedRows, document.docId])
					}} />

			</td>
			<td>
				<TickerSectorItem label={document.ticker} image={`/api/public/images/note/${document.docId}.png?${imageKey}`} country={ticker?.country} filename={ticker?.ric}/>
			</td>
			<td className="research-title">
				<p>{document.title}</p>
			</td>
			<td>
				<p>{document.author}</p>
			</td>
			<td className="" >
				<TableActionIcon onClick={() => onCraigsDocClick(defaultPdf[0])} icon={<PdfIcon width="24px" />} toolTip="PDF download" />
				<TableActionIcon
					disabled={datafiles.length === 0}
					onClick={(event) => {
						event.stopPropagation()
						datafiles.forEach(onCraigsDocClick)
					}}
					icon={<XlsIcon />}
					toolTip={datafiles.length === 0 ? "No data" : "Data download (" + datafiles.length + ")"}
				/>
			</td>
			<td className="pointer" onClick={(e) => onDeliveryClick(e, document)}>
				{delivered}
			</td>
			<td className="pointer" onClick={(e) => onDeliveryClick(e, document)}>
				{failed}
			</td>
			<td className="pointer" onClick={(e) => onDeliveryClick(e, document)}>{email}

			</td>
			<td className="pointer" onClick={(e) => onDeliveryClick(e, document)}>{portal}
			</td>
			<td className="pointer" onClick={(e) => onDeliveryClick(e, document)}>
				<div className="icon-counter-count">{pdf}</div>
			</td>
			<td className="pointer" onClick={(e) => onDeliveryClick(e, document)}>
				<div className="icon-counter-count">{model}</div>
			</td>
		</tr>
		{!modelsLoading && expandedRows && expandedRows.includes(document.docId) &&
			<tr className="no-hover library-row-expanded" key={"portal-details-" + document.docId}>
				<td colSpan={12}>
					<PublicationSettings data={document} image={getImage(document.ticker)} tickers={tickers} models={models} updateVisibility={updateVisibility} updateImageKey={updateImageKey} modelsLoading={modelsLoading} />
				</td>
			</tr>
		}
	</>
	);
}

export default LibraryTableRow;