import React, { Component } from 'react';
import "./ModalSectionWrapper.scss";

class 	ModalSectionWrapper extends Component {
	state = {  }
	render() { 
		const { header, children } = this.props;
		return ( 
			<div className="modal-section-wrapper">
				{header && <h3 className="mb-l">{header}</h3>}
				{children}
			</div>
		 );
	}
}
 
export default ModalSectionWrapper;