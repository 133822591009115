import React from 'react';
import "./IconCounter.scss";
import Tooltip from './popups/Tooltip';

const IconCounter = (props) => {
	const { status, icon, count, tooltip, dataAvailable, inactive, iconOnly, pointer, onClick } = props;
	return (
		<Tooltip hoverOnly label={dataAvailable ? tooltip : "No Data Available"} disabled={!tooltip}>
			<div className={`icon-counter-wrapper ${pointer? "" : "cursor-pointer"} icon-counter-status-${status}${inactive ? " counter-inactive" : ""}`} onClick={onClick}>
				{icon}
				{iconOnly ? "" : <div className="icon-counter-count">{dataAvailable ? count : <div className="icon-counter-empty">-</div>}</div>}
			</div>
		</Tooltip>
	);
}

export default IconCounter;