export function retrievePhonePrefixList(entry, storage){
	entry = entry && entry.startsWith("+") ? entry.substring(1) : entry;
	const findMatches = (str, list) => {
		var filteredAndTransformedList = [];
		const match = typeof str === "number" ? str : str.toLowerCase();
		for(var i =0; i<list.length; i++){
			if(list[i]["name"].toLowerCase().includes(match)
				||list[i]["idd"].toString().indexOf(match)===0){
				filteredAndTransformedList.push({
					label:list[i].name,
					value:"+"+list[i].idd,
					id:list[i].id
				})
			}
		}
		return filteredAndTransformedList;
	}
	return storage.get("/crm/idds")
		.then(idds=>{
			return findMatches(entry, idds);
		});
}

export function testInternationalNumberSeperately(idd,number){
	return !!idd && /^\+[0-9]{1,4}$/.test(idd.replace(/\s/g,"")) //assumes that the prefix is a string!!!
	&& /^[1-9][0-9]{6,10}$/.test(number);//also total length test?
}