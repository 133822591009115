import React from 'react';
import AnalyticsCounter from '../../../components/Analytics/AnalyticsCounter';
import FlexWrapper from '../../FlexWrapper';
import UserChart from '../Charts/UserChart';
import AnalyticsHeader from './AnalyticsHeader';

const UsageMetrics = (props) => {
	return (
		<>
			<AnalyticsHeader dateRanges>Usage</AnalyticsHeader>
			<FlexWrapper fullWidth gap="xxl" className="mb-l">
				<AnalyticsCounter label="Users" count="260" />
				<AnalyticsCounter label="New Users" count="18" />
				<AnalyticsCounter label="Average Login Frequency" count="415" />
				<AnalyticsCounter label="Average Session" count="12,396" />
			</FlexWrapper>
			{/* Chart Here */}
			<UserChart />
		</>
   );
}

export default UsageMetrics;