import React from 'react';
import { FileText } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailFieldList from '../../Shared/DetailSection/DetailFieldList';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import SelectDetailField from '../../Shared/DetailSection/SelectDetailField';
import PageGridItem from '../../Shared/Page/PageGridItem';

const TemplateGeneralDetails = ({ editMode, template }) => {

	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="Template Details" icon={<FileText />} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField bold editMode={editMode} autoFocus label="Name" value={template.name} />
			</PageGridItem>
			{!editMode && (
				<>
					<PageGridItem col="4 / span 3">
						<SelectDetailField editMode={editMode} label="Ticker Type" value={template.ticker ? template.ticker.type : "-"} />
					</PageGridItem>
					<PageGridItem col="1 / span 3">
						<DetailField editMode={editMode} label="Optional Pages" value={template.optionalPages ? "Yes" : "No"} />
					</PageGridItem>
					{template.outputs && <PageGridItem col="1 / span 3">
						<DetailFieldList label="Outputs" value={template.outputs.map(output => output.name + (output.dflt ? " (default)" : ""))} />
					</PageGridItem>}
				</>
			)}
		</>
	);
}

export default TemplateGeneralDetails;