import React from 'react';
import { Clock } from 'react-feather';
import Table from '../../../components/Table/Table';
import IconCounter from '../../../components/IconCounter';
import FlexWrapper from '../../FlexWrapper';
import ProfileImage from '../../../components/ProfileImage';

const TopUsersTable = (props) => {

	const colWidths = ["60%", "40%"];
	const data = [
		{ name: "Forrest Law", count: "20m 32s" },
		{ name: "Forrest Law", count: "20m 32s" },
		{ name: "Forrest Law", count: "20m 32s" },
		{ name: "Forrest Law", count: "20m 32s" },
		{ name: "Forrest Law", count: "20m 32s" },
	];

	const dataMap = () => data && data.map((analyst, idx) => {
		return (
			<tr key={idx} className="cursor-pointer">
				<td>
					<FlexWrapper gap="s">
						<ProfileImage size={24} src="https://i.ibb.co/9vjYZzn/pexels-photo-1024311.jpg" />
						<p>{analyst.name}</p>
					</FlexWrapper>
				</td>
				<td className="table-action-icons transition-fade-in-slow">
					<FlexWrapper gap="xxl" justify="flex-end">
						<IconCounter icon={<Clock />} tooltip="Session Duration" dataAvailable count={analyst.count} />
					</FlexWrapper>
				</td>
			</tr>
		);
	});

	return (
		<Table rowSize="s" colWidths={colWidths} dataMap={dataMap()} />
   );
}

export default TopUsersTable;