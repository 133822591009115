import React from 'react'
import { Circle, Radio } from 'react-feather';
import FlexWrapper from '../layouts/FlexWrapper';
import "./RadioButton.scss";

function RadioButton({ label, checked, onClick, disabled }) {
	return (
		<FlexWrapper className={`radio-wrapper ${disabled? "disabled" : ""}`} align="center" gap="xs" onClick={!disabled && onClick}>
			<div className={`radio-icon-wrapper${checked ? ' radio-checked' : ''}`}><Circle className="radio-icon" /></div>
			{label}
		</FlexWrapper>
	);
}

export default RadioButton;
