import React from 'react';

const ButtonGroupWrapper = ({ children, className, justifyContent }) => {
	return (
		<div style={{ display: "flex", gap: "1rem", justifyContent: justifyContent }} className={className ? className : ""}>
			{children}
		</div>
	);
};

export default ButtonGroupWrapper;