import React from 'react';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import Table from '../../../components/Table/Table';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { useState } from 'react';
import { useRemote } from '../../../Utils/Utils';
import Tooltip from '../../../components/popups/Tooltip';
import { Eye, Mail, Users } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';

const AnalyticsCompaniesList = ({ changeRoute }) => {
	const remote = useRemote();

	const dummyCompanies = [
		{
			name: "Acme Co",
			location: "Wellington, New Zealand",
			id: 111,
			analytics: { emailViews: 12, portalViews: 34, pdfDownload: 56, modalDownload: 78 },
		},
		{
			name: "Shinra Company",
			location: "Wellington, New Zealand",
			id: 123,
			analytics: { emailViews: 22, portalViews: 33, pdfDownload: 44, modalDownload: 55 },
		},
	];

	// State
	const [data, setData] = useState(dummyCompanies);
	const [filteredData, setFilteredData] = useState([]);
	const [hasSearchTerm, setHasSearchTerm] = useState(false);
	const [loading, setLoading] = useState(false);

	// Table data
	const colWidths = ["20%", "20%", "10%", "10%", "10%", "10%"];
	const headers = [
		"Company",
		"Location",
		{
			text: (
				<Tooltip label="Email Views">
					<Mail className="cursor-pointer colour-text-purple" size={16} />
				</Tooltip>
			),
			className: "text-align-center",
		},
		{
			text: (
				<Tooltip label="Portal Views">
					<Eye className="cursor-pointer colour-text-primary" size={16} />
				</Tooltip>
			),
			className: "text-align-center",
		},
		{
			text: (
				<Tooltip label="PDF Downloads">
					<PdfIcon noHover className="cursor-pointer" size={16} />
				</Tooltip>
			),
			className: "text-align-center",
		},
		{
			text: (
				<Tooltip label="Model Downloads">
					<XlsIcon noHover className="cursor-pointer" size={16} />
				</Tooltip>
			),
			className: "text-align-center",
		},
	];

	const dataMap = (dummyAnalytics) =>
		dummyAnalytics &&
		dummyAnalytics.map((data, idx) => {
			return (
				<tr key={idx} className="cursor-pointer" onClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/companies/company/" + data.id)}>
					{/* User details */}
					<td>{data.name}</td>
					<td>{data.location}</td>
					{/* Analytics icons */}
					<td className="text-align-center">{data.analytics.emailViews}</td>
					<td className="text-align-center">{data.analytics.portalViews}</td>
					<td className="text-align-center">{data.analytics.pdfDownload}</td>
					<td className="text-align-center">{data.analytics.modalDownload}</td>
				</tr>
			);
		});

	return (
		<Page fixedBanner showScrollbar>
			{/* Modal */}
			<PageContentBanner divider gap="m">
				<SearchTermFilter
					size="small"
					width="l"
					placeholder={`Search companies ${loading ? "" : "(" + data.length + ")"}`}
					isClearable
					dataSet={data}
					setFilteredData={setFilteredData}
					path={["company", "location", ""]}
					hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
				/>
			</PageContentBanner>
			<PageContentBodyGrid showScrollbar>
				<PageGridItem colSpan="12">
					<Table
						minWidth={1000}
						colWidths={colWidths}
						headers={headers}
						dataMap={dataMap(hasSearchTerm ? filteredData : data)}
						noResults={{ message: "No users found", subMessage: "Try adding a new user." }}
						dataLoading={loading}
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(AnalyticsCompaniesList);