import React from "react";
import "./App.scss";
import "./styles/UtilClasses.scss";
import "./styles/DesignTokenClasses.scss";
import BuilderPage from "./layouts/BuilderPage";
import DataController from "./DataController";
import { ThreeSkyeWrapper, withRemote } from "@threeskye/global";
import { withRouter } from "react-router";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Authorise from "./Authorise";
import Extensions from "./Extensions";
import NoAccess from "./NoAccess";
import MenuWrapper from "./layouts/MenuWrapper";
import CraigsLibraryPage from "./layouts/CraigsLibraryPage/CraigsLibraryPage";
import AdminPage from "./layouts/AdminPage/AdminPage";
import DistributionList from "./layouts/CompanyUserManagement/Distribution/DistributionList";
import SettingsProfile from "./layouts/Settings/Profile/SettingsProfilePage";
import SettingsGeneralPage from "./layouts/Settings/General/SettingsGeneralPage";
import UsersLists from "./layouts/CompanyUserManagement/User/UsersLists";
import UserPage from "./layouts/CompanyUserManagement/User/UserPage";
import CompanyLists from "./layouts/CompanyUserManagement/Company/CompanyLists";
import CompanyPage from "./layouts/CompanyUserManagement/Company/CompanyPage";
import ToastContainer from "./components/popups/ToastContainer";
import DistributionListPage from "./layouts/CompanyUserManagement/Distribution/DistributionListPage";
import ErrorPage from "./layouts/ErrorPage";
import DeliveryDetails from "./layouts/CraigsLibraryPage/DeliveryDetails";
import SuppressionList from "./layouts/CompanyUserManagement/Distribution/SuppressionList";
import PublicationView from "./layouts/CraigsLibraryPage/PublicationManagement/PublicationView";
import PublicationAnalytics from "./layouts/CraigsLibraryPage/PublicationManagement/PublicationAnalytics";
import PublicationSettings from "./layouts/CraigsLibraryPage/PublicationManagement/PublicationSettings";
// Analytics
import AnalyticsPage from "./layouts/Analytics/AnalyticsPage";
import AnalyticsTickersList from "./layouts/Analytics/Tickers/AnalyticsTickersList";
import AnalyticsTickerPage from "./layouts/Analytics/Tickers/AnalyticsTickerPage"
import AnalyticsTickerResearchViews from "./layouts/Analytics/Tickers/AnalyticsTickerResearchViews"
import AnalyticsCompaniesList from "./layouts/Analytics/Companies/AnalyticsCompaniesList";
import AnalyticsUsersList from "./layouts/Analytics/Users/AnalyticsUsersList";
import AnalyticsUserPage from "./layouts/Analytics/Users/AnalyticsUserPage";
import AnalyticsCompanyPage from "./layouts/Analytics/Companies/AnalyticsCompanyPage";


export const PAGES = {
	EDITOR: "editor",
	LIBRARY: "library",
	ADMIN: "admin",
	CRM: "crm",
	ANALYTICS: "analytics"
};

export const ROUTE_PATHS = {
	[PAGES.EDITOR]: "/editor",
	[PAGES.LIBRARY]: "/",
	[PAGES.ADMIN]: "/admin",
	[PAGES.CRM]: "/crm",
	[PAGES.SETTINGS]: "/settings",
	[PAGES.ANALYTICS]: "/analytics"
};

class AuthIntercept extends React.Component {

	constructor(props) {
		super(props);
		this.state = { authenticated: false, me: null, company: null, organisation: null, topMessage: null };
		this.logout = this.logout.bind(this)
		this.initialise = this.initialise.bind(this)
		this.setTopMessage = this.setTopMessage.bind(this);
	}

	setTopMessage(message) {
		this.setState({topMessage:message});
	}

	componentDidMount() {
		this.initialise()
	}

	initialise() {
		const authenticated = !!window.sessionStorage.getItem("3skye.auth.token");
		if (!authenticated) {
			//For now this is hard wired (backend) to CIP.  Need to find a way to split based on URL
			window.location.href = "/api/login?host="+window.location.hostname;
		} else if (authenticated) {
			this.setState({ authenticated });
			this.props.remote.registerLogoutHandler(this.logout);
			this.props.remote.get("/crm/company").then((org) => {this.setState({organisation: org})} )
		}
	}


	componentWillMount() {
		this.unlisten = this.props.history.listen((location, action) => {
			localStorage.setItem("3skye.research.lastpath", location.pathname);
		});

		// TODO needs re-thinking to accomodate the many more pages that now exist :)

		// if (localStorage.getItem("3skye.research.lastpath")) {
		// 	this.props.history.push(localStorage.getItem("3skye.research.lastpath"));
		// }
	}

	componentWillUnmount() {
		this.unlisten();
	}

	logout() {
		this.initialise();
	}

	render() {
		const { authenticated } = this.state;
		if (!authenticated) {
			return null;
		}
		return (
			<MenuWrapper topComponent={this.state.topMessage}>
				<Switch>
					<Route
						exact
						path={ROUTE_PATHS[PAGES.EDITOR]}
						render={() => (
							<DataController internalApi={true} setTopMessage={this.setTopMessage}>
								<BuilderPage organisation={this.state.organisation} template={this.craigs} setTopMessage={this.setTopMessage}/>
							</DataController>
						)}
					/>
					{/* <Route
						exact
						path={ROUTE_PATHS[PAGES.EDITOR] + "/:template"}
						render={() => (
							<DataController internalApi={true}>
								<BuilderPage template={this.craigs} />
							</DataController>
						)}
					/> */}
					<Route path={ROUTE_PATHS[PAGES.ADMIN]} component={AdminPage} />
					<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/users"} component={UsersLists} />
					<Route path={ROUTE_PATHS[PAGES.CRM] + "/users/user/:userId"} component={UserPage} />
					<Route path={ROUTE_PATHS[PAGES.CRM] +  "/delivery/:deliveryId"} component={DeliveryDetails}/>
					<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/companies"} component={CompanyLists} />
					<Route path={ROUTE_PATHS[PAGES.CRM] + "/companies/company/:companyId"} component={CompanyPage} />
					<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/distribution"} component={DistributionList} />
					<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/:distributionListId"} component={DistributionListPage} />
					<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/suppressions"} component={SuppressionList} />
					<Route path={ROUTE_PATHS[PAGES.SETTINGS] + "/profile"} component={SettingsProfile} />
					<Route path={ROUTE_PATHS[PAGES.SETTINGS] + "/general"} component={SettingsGeneralPage} />
					<Route path={ROUTE_PATHS[PAGES.LIBRARY] + "publication/:publicationId"} component={PublicationView} />
					{/* Analytics */}
					<Route path={ROUTE_PATHS[PAGES.ANALYTICS] + "/dashboard"} component={AnalyticsPage}/>
					<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers"} component={AnalyticsTickersList}/>
					<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/ticker/:tickerId"} component={(props) => <AnalyticsTickerPage datatita={props}/>}/>
					<Route path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/ticker/:tickerId/research/:researchId"} component={(props) => <AnalyticsTickerResearchViews datatita={props}/>}/>
					<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/users"} component={AnalyticsUsersList}/>
					<Route path={ROUTE_PATHS[PAGES.ANALYTICS] + "/users/user/:userId"} component={AnalyticsUserPage}/>
					<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/companies"} component={AnalyticsCompaniesList}/>
					<Route path={ROUTE_PATHS[PAGES.ANALYTICS] + "/companies/company/:companyId"} component={AnalyticsCompanyPage}/>
					<Route exact component={CraigsLibraryPage} />
					{/* 404/error page */}
					<Route path="*" component={ErrorPage} />
				</Switch>
			</MenuWrapper>
		);
	}
}

class InternalApiApp extends React.Component {
	render() {
		return (
			<ThreeSkyeWrapper disableI18n={true}>
				<ToastContainer />
				<BrowserRouter>
					<Switch>
						<Route exact path="/authorise/:token" component={Authorise} />
						<Route path="/ext/:comp" component={Extensions} />
						<Route path="/no-access" component={NoAccess} />
						<Route component={withRemote(withRouter(AuthIntercept))} />
					</Switch>
				</BrowserRouter>
			</ThreeSkyeWrapper>
		);
	}
}

export default (InternalApiApp);
