import React from 'react';
import { Tool } from 'react-feather';
import Checkbox from '../../../components/Checkbox';
import SelectTagField from '../../../components/Input/SelectTagField';
import Table from '../../../components/Table/Table';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';

const TemplateCustomPublishActions = ({ data }) => {
	const colWidths = [40 + "%", 60 + "%"];
	const options = [
		{ value: 'Van Helsing', label: 'Van Helsing' },
		{ value: 'Rick Owens', label: 'Rick Owens' },
	];

	const dataMap = () => {
		return (
			<tr className="cursor-pointer no-hover">
				<td><Checkbox label="Email List" onChange={() => alert("Check me out")}/></td>
				<td><SelectTagField options={options} /></td>
			</tr>
		);
	}

	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="Custom Publish Actions" icon={<Tool />} />
			</PageGridItem>
			<PageGridItem col="1 / span 6">
				<Table colWidths={colWidths} dataMap={dataMap()} />
			</PageGridItem>
		</>
	);
}

export default TemplateCustomPublishActions;