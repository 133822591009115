import React, { useState } from 'react';
import VersionOption from './VersionOption';
import FlexWrapper from '../../../layouts/FlexWrapper';
import { ChevronDown } from 'react-feather';
import { format, addHours, parseISO } from 'date-fns';

const GroupedVersions = ({ group, data, versionsToLoad, refresh, formatDate, key, current, selected }) => {
	const [expanded, setExpanded] = useState(false)
	let date = `${format(parseISO(group[0].savedTime), "dd/MM/yyyy")}`
	let time = `${format(parseISO(group[0].savedTime), "hh:00 a")} - ${format(addHours(parseISO(group[0].savedTime), 1), "hh:00 a")}`
	return (
		<div >
			<FlexWrapper gap="xs" align="flex-start" justify="space-between" className={`version-option${current ? " current-version" : ""}${selected || expanded ? " selected-version" : ""}`} fullWidth onClick={() => setExpanded(!expanded)}>
			<FlexWrapper className="version-content" direction="column" align="flex-start">
				<FlexWrapper gap="xs" direction="column" align="flex-start" fullWidth>
					<div className="version-date-name font-weight-medium">{date}</div>
				</FlexWrapper>
				<FlexWrapper gap="s" className="version-file-size-author font-size-body-s">
					<div className="version-file-size">{time}</div>
				</FlexWrapper>
			</FlexWrapper>
			<div style={{display: "flex", alignSelf: "flex-end"}}>
				 <ChevronDown className={`version-expand-icon colour-text-neutral-dark-50 ${expanded ? ' expanded' : ''}`} size={14}/>
			</div>
		</FlexWrapper>
		
			<div id="nested-versions-wrapper">
				{expanded && group.map((version, idx) => {
					return <VersionOption
						nestedVersion
						key={version.id + " " + version.locked}
						id={version.id}
						data={data}
						date={formatDate(version.savedTime)}
						author={version.user}
						size={version.size}
						locked={version.locked}
						published={version.publishedDocument !== 0}
						versionsToLoad={versionsToLoad}
						refresh={refresh}
					/>

				})}
			</div>
		</div>
	);
}

export default GroupedVersions;