import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer as Toaster} from 'react-toastify';
import "./ToastOverrides.scss";
import { X } from 'react-feather';


const ToastContainer = (props) => {
	return (
		<Toaster autoClose={false} closeButton={<X className="toast-close-button" />} />
	)
}

export default ToastContainer;