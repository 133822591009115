import React from 'react';
import StatusToken from '../../StatusTokens';
import SideBarComment from '../Comments/SideBarComment';
import SidebarExtensionWrapper from './SideBarExtensionWrapper';
import SideBarSection from './SideBarSection';

const CommentsSidebarExtension = ({slideIn, slideOut, compliance, peerReview}) => {

	const getTokenState = (state) => {
		if (state.approved) {return "positive"}
		else if (state.responded) {return "warning"}
		else if (state.requested) {return "warning"}
		else if (state.denied) {return "danger"}
		else {return ""}
	}
	const getTokenMessage = (state) => {
		if (state.approved) {return "Approved"}
		else if (state.responded) {return "Responded"}
		else if (state.requested) {return "Requested"}
		else if (state.denied) {return "Denied"}
		else {return "Not Requested"}
	}
	
	const complianceRequested = compliance.approved || compliance.denied || compliance.comment || compliance.responded;
	const peerReviewRequested = peerReview.approved || peerReview.denied || peerReview.comment || peerReview.responded;

	return (
		<SidebarExtensionWrapper position="right" shadow slideIn={slideIn} slideOut={slideOut} noPadding>
			<SideBarSection>
				<div className="flex-align-center">
					<h4 className="mr-s">Compliance</h4>
					<StatusToken type={getTokenState(compliance)}>{getTokenMessage(compliance)}</StatusToken>
				</div>
				{/* Comments start */}
				{complianceRequested && <SideBarComment className="mt-xl" comment={{ name: "", date: "", comment: compliance.comment }} />}
			</SideBarSection>
			<SideBarSection separator>
				<div className="flex-align-center">
					<h4 className="mr-s">Peer Review</h4>
					<StatusToken type={getTokenState(peerReview)}>{getTokenMessage(peerReview)}</StatusToken>
				</div>
				{/* Comments start */}
				{peerReviewRequested && <SideBarComment className="mt-xl" comment={{ name: "", date: "", comment: peerReview.comment }} />}
			</SideBarSection>
		</SidebarExtensionWrapper>
	);
}

export default CommentsSidebarExtension;