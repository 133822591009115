import React, {useState} from 'react'
import { BookOpen, ChevronDown, ChevronUp, Plus } from 'react-feather';
import SearchField from '../../../components/Input/SearchField';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import useWindowDimensions from '../../../Utils/Utils';
import FlexWrapper from '../../FlexWrapper';
import DetailSectionHeader from '../DetailSection/DetailSectionHeader';
import PopupMenu from '../../../components/PopupMenu';
import Popup from '../../../components/Popup';
import PopupMenuItem from '../../../components/PopupMenuItem';
import Button from '../../../components/Buttons/Button';

const GenericListHeader = ({ setHasSearchTerm, onSearchSelect, header, headerOptions, onHeaderOptionSelect, addableLists, userLists, setFilteredUserLists, searchSelect, addAllButton, setShowModal, hideAddInput }) => {
	const { width } = useWindowDimensions();
	const xl = width > 2250;
	const [popupOpen, setPopupOpen] = useState(false)

	const listLength = userLists && userLists.length;
	const noData = !userLists || listLength <= 0;
	const noSearchResults = false;
	return (
		<>
			{headerOptions && headerOptions.length > 1
				? <Popup
					onVisibilityChange={(open) => setPopupOpen(open) }
					triggerElement={(
						<div style={{cursor: "pointer"}} className='flex-center'>
							<DetailSectionHeader icon={<BookOpen />} header={`${header} (${noData ? "0" : listLength})`} />
							{popupOpen ? <ChevronUp className='ml-s colour-text-neutral-dark-40' /> : <ChevronDown className='ml-s colour-text-neutral-dark-40' />}
						</div>
					)}
					popupElement={(
						<PopupMenu padding width="208px">
							{headerOptions.map((option, idx) => {
								return <PopupMenuItem key={`generic-list-header-${idx}`} label={option.label} onClick={() => onHeaderOptionSelect(option.label)} />
							})}
						</PopupMenu>
					)}
				/>

				: <DetailSectionHeader icon={<BookOpen />} header={`${header} (${noData ? "0" : listLength})`} />
			}

			<FlexWrapper wrap gap="s">
				{!noData && (
					<SearchTermFilter
						size="small"
						width="m"
						placeholder="Search lists"
						isClearable
						dataSet={userLists}
						setFilteredData={setFilteredUserLists}
						path={["createdBy", "createdDate", "name", "fk", "type"]}
						hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
					/>
				)}
				{!hideAddInput && <SearchField
					options={addableLists}
					onChange={(list) => onSearchSelect(list.value)}
					placeholder={`Add list`}
					size="small"
					width={noData && xl ? "l" : "m"}
					openMenuOnFocus
					innerRef={searchSelect}
					value=""
				/>
				}
				{addAllButton && !hideAddInput && <Button icon={<Plus />} onClick={() => setShowModal(true)} >Add All</Button>}
			</FlexWrapper>
		</>
	)

}

export default GenericListHeader