import React, { Component, useEffect, useState } from "react";
import SideBarSection, { SortableSidebarSection } from "./SideBarSection";
import SideBarFooter from "./SideBarFooter";
import Dropzone from "../../Dropzone/Dropzone";
import DropDown from "../../DropDown";
import Button from "../../Buttons/Button";
// import "@threeskye/core-components/dist/styles/includes/Forms.scss";
import { withData } from "../../../DataController";
import TickerItem from "../../TickerItem";
import arrayMove from "array-move";
import withRouteChange from "@threeskye/route-change";
import TextInput from "../../Input/TextInput"
import SidebarExtensionWrapper from "./SideBarExtensionWrapper";
import FlexWrapper from "../../../layouts/FlexWrapper";
import AlertBlock from "../../../layouts/Shared/Prompts/AlertBlock";
import TextFieldLabel from "../../Input/TextFieldLabel";
import { format } from "date-fns";
import { useRemote } from "../../../Utils/Utils";

//MAP OF ALL TEMPLATE TICKER TYPES
const TEMPLATE_TICKER_TYPES = {
	SELECTION: "selection",
	OPEN: "open",
	FIXED: "fixed"
}

const EditorSideBarExtensionNew = (props) => {

	const [templates, setTemplates] = useState([]);						//all template names.  String
	const [selectedTemplate, setSelectedTemplate] = useState("");		//selected template name.  String
	const [template, setTemplate] = useState(null);						//selected template - actual template.

	const [tickers, setTickers] = useState([]);							//tickers available in the selection group
	const [selectedTickers, setSelectedTickers] = useState([]);			//tickers selected for use in template

	const [selectionGroup, setSelectionGroup] = useState(null);			//ticker groups matching the template grouping. (array)

	const [publishing, setPublishing] = useState(false);				//action status

	const [documentIdentifier, setDocumentIdentifier] = useState(null)	//eg. `MCY.NZ/Company Note`, `Utilities/Sector Note` etc.

	const [saving, setSaving] = useState(false);
	const [saved, setSaved] = useState(false);
	const [previewLoading, setPreviewLoading] = useState(false);
	const [selectedTickerGroup, setSelectedTickerGroup] = useState(null);

	const [tickerMessage, setTickerMessage] = useState({});

	const [dataStatus, setDataStatus] = useState({});

	const { compliance, peerReview } = props;

	const remote = useRemote();

	const complianceReviewAvailable = props.organisation && props.organisation.complianceReviewAvailable;
	const peerReviewAvailable = props.organisation && props.organisation.peerReviewAvailable;

	//Publish is enabled if we have a _response_ regardless of yay or nay
	const publishEnabled = (complianceReviewAvailable === false || compliance.responded)  && (peerReviewAvailable === false || peerReview.responded);
	const publishWithoutReviewEnabled = template !== null && tickers[0] && tickers[0] !== "";


	useEffect(() => {
		remote.get("/data/templates").then(setTemplates);
		// if (props.match.params.template) {
		// 	const isSelection = template && template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION
		// 	const selectedTemplate = this.props.match.params.template;
		// 	this.setState({ selectedTemplate }, () => this.onUpdateTemplate(isSelection))
		// }
		// this.statusCheck = setInterval(this.statusCheck, 60000);
		// this.props.data.addLocalListener(this.listener);
		// this.props.data.setSaveErrorListener(this.saveErrorListener);
		
	}, [])

	useEffect(() => {
		if (selectedTemplate) {
			remote.get("/data/templates/" + selectedTemplate).then(template => {
				if (template.optionalPages) {
					props.setOptionalPages([])
				} else {
					props.setOptionalPages(null);
				}
				const compliance = {
					requested: false,
					approved: false,
					denied: false,
					comment: false
				};
				const peerReview = {
					requested: false,
					approved: false,
					denied: false,
					comment: ""
				}
	
				setTemplate(template);
				setSelectedTickers([""])
				setTickers([""])

				props.setPeerReview(peerReview)
				props.setCompliance(compliance)
				props.data.registerTemplate(template, selectedTemplate);
				props.onTemplateChange(template);
	
				

			});
			
		}
	}, [selectedTemplate])

	useEffect(() => {
		console.log("Use effect for selectedTickerGroup", selectedTickerGroup)
		console.log("Template is ", template);
		if (template && template.ticker.lookup) {
			remote.get(`/crm/groupings/${selectedTickerGroup.grouping}/${selectedTickerGroup.id}`)
		}

	}, [selectedTickerGroup])

	useEffect(() => {
		console.log("Use effect for tickers, would have called updateTickerStatus")
	}, [tickers])

	/**
	 * Update the value of the nth tickers string, to the new string 
	 * @param {*} event 
	 * @param {*} index 
	 */
	const onTickerTextChange = (event, index) => {
		let value = event.currentTarget.value.toUpperCase();
		setTickers(tickers.slice(0, index).concat(value).concat(tickers.slice(index + 1, tickers.length)));
	}


	useEffect(() => {
		if (!documentIdentifier) {
			props.setCompliance(false)
			props.setPeerReview(false)
		} else {
			props.remote.get("/client/review/compliance/" + documentIdentifier)
				.then(compliance => {
					props.setCompliance( compliance );
				});
			props.remote.get("/client/review/peer/" + documentIdentifier)
				.then(peerReview => {
					props.setPeerReview(peerReview);
				});
		}
	}, [documentIdentifier])


	const isReviewDisabled = () => {
		if (!template) {
			return true;
		}

		return !documentIdentifier;
		
	}

	const reorderTicker = ({ oldIndex, newIndex }) => {
		// document.body.style.cursor = 'default';
		// this.setState((prevState) => {
		// 	prevState.selectedTickerGroup.tickers = arrayMove(prevState.selectedTickerGroup.tickers, oldIndex, newIndex);
		// 	prevState.tickers = prevState.selectedTickerGroup.tickers.filter(ticker => prevState.tickers.includes(ticker));
		// 	return prevState;
		// }, () => this.updateTickerStatus(true));
	};

	const onCheckboxTickerToggle = (ticker, checked) => {
		const newTickers = selectedTickerGroup.tickers.filter(tgTicker => tgTicker === ticker ? !checked : tickers.includes(tgTicker));
		setTickers(newTickers);
	}


	const reviewDisabled = isReviewDisabled();

	const showSelection = template && template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION
	const showSelectionTemplateOptions = showSelection && template.ticker.options;
	const showSelectionLookupOptions = showSelection && template.ticker.lookup;
	const showTickerEntry = !showSelection;


	const publish = () => {
		props.showModal("publishConfirm", () => {
			props.downloadPDF(
				{
					tickers: tickers,
					template: selectedTemplate,
					templateName: selectedTemplate,
					tickerGroup: selectedTickerGroup
				},
				dto => publishAction(dto, "/publishv2")
			);
		});
	}

	const preview = () => {
		setPreviewLoading(true);
		props.downloadPDF(
			{
				tickers: tickers,
				template: selectedTemplate,
				templateName: selectedTemplate,
				tickerGroup: selectedTickerGroup
			}, null, () => {
				setPreviewLoading(false);
			}
		);
	}

	const publishAction = (dto, url) => {
		setPublishing(true);
		dto.template = selectedTemplate;
		remote.post(url, dto)
			.then(resp => {
				if (!resp.success) {
					props.showModal("publishFailure", null, null, { message: resp.message, data: resp.data });
					setPublishing(false);
				} else {
					props.showModal("publishSuccess");
					//this.updateTickerStatus(true);
					setPublishing(false);
				}
			});
	}

	const publishOverride = () => {
		props.showModal("overrideConfirm", () => {
			props.downloadPDF(
				{
					tickers: tickers,
					template: selectedTemplate,
					templateName: selectedTemplate,
					tickerGroup: selectedTickerGroup
				},
				dto => publishAction(dto, "/publishv2/override")
			);
		});
	}

	const performComplianceReview = () => {
		const {compliance} = props
		compliance.requesting = true;
		props.setCompliance(compliance)
		
		const data = {};
		//TODO - if data.tickers, then email to compliance includes words 'containing tickers A, B, C'.
		
		remote.post("/client/review/compliance/" + documentIdentifier, data)
			.then(resp => {
				if (resp.success) {
					if (resp.message) {
						props.showModal("reviewResent");
					}
					compliance.requested = true;
					props.setCompliance(compliance);
				} else {
					props.showModal("reviewFailure");
				}
			});
			props.setSideBarExtRight("Comments")
	}

	const performPeerReview = () => {

		props.showModal("analystChecklist", () => {
			const {peerReview} = props
			peerReview.requesting = true;
			props.setPeerReview(peerReview);
			props.downloadPDF({
				tickers: tickers,
				template: selectedTemplate,
				templateName: selectedTemplate,
				tickerGroup: selectedTickerGroup
			}, dto => {
				if (selectedTickerGroup && selectedTickerGroup.tickers) {
					dto.allTickers = selectedTickerGroup.tickers;
				}
				props.remote.post("/client/review/peer/" + documentIdentifier, dto)
					.then(resp => {
						if (resp.success) {
							if (resp.message) {
								props.showModal("reviewResent");
							}
							peerReview.requested = true;
							props.setPeerReview( peerReview );
						} else {
							if (resp.message) {
								props.showModal("reviewFailure", null, null, { message: resp.message });
							} else {
								props.showModal("reviewFailure");
							}
						}
					});
			});
		});
		props.setSideBarExtRight("Comments")
	}


	return (
		<SidebarExtensionWrapper collapsable collapsed={false} style={{ paddingBottom: 260 }} shadow sideBarLeft>
			{/* TEAMPLATE */}
			<SideBarSection>
				<DropDown label="Template" clickable fullWidth value={selectedTemplate ? selectedTemplate : "Select Template"} placeholder={selectedTemplate ? false : true}>
					{templates.map((template, index) => (
						<div key={index} onClick={() => setSelectedTemplate(template)}>
							{template}
						</div>
					))}
				</DropDown>
			</SideBarSection>
			{/* TICKERS */}

			<SortableSidebarSection
				separator
				onSortStart={() => (document.body.style.cursor = "grabbing")}
				onSortEnd={reorderTicker}
				helperClass="drag-item"
			>
				 {/* Sector Dropdown */}
				{showSelectionTemplateOptions && <>
					<DropDown clickable fullWidth label={template.ticker.selectionName} value={selectedTickerGroup && selectedTickerGroup.name}>
						{template.ticker.options.map((option, index) => (
							<li key={index} onClick={() => setSelectedTickerGroup(option)}>
								{option.name}
							</li>
						))}
					</DropDown>
					{selectedTickerGroup && (
						<FlexWrapper className="mt-l" wrap gap="m">
							{selectedTickerGroup.tickers.map((ticker, idx) => {
								const checked = tickers.includes(ticker);
								const hasData = !!tickerMessage[ticker] && tickerMessage[ticker] !== "No data available";
								// Dummy const for old data
								const dataOutOfDate = "";
								return (
									<TickerItem
										tickerStatus={hasData ? "data-available" : dataOutOfDate ? "data-out-of-date" : "data-unavailable"}
										key={idx}
										index={idx}
										ticker={ticker}
										tickerMessage={tickerMessage[ticker]}
										hasData={hasData}
										checked={checked}
										loading={props.loadingLocalData}
										onChange={() => onCheckboxTickerToggle(ticker, checked)}
									/>
								);
							})}
						</FlexWrapper>
					)}
					</>
				
				}
				{showSelectionLookupOptions && <GroupingTickerSelection template={template} data={props.data} setDocumentIdentifier={setDocumentIdentifier} />
}
				{showTickerEntry && tickers.map((ticker, idx) => {
						return <SingleTickerSelection key={idx} setDocumentIdentifier={setDocumentIdentifier} setDataStatus={setDataStatus} template={selectedTemplate} data={props.data}/>
					})}
			</SortableSidebarSection>

			{complianceReviewAvailable && (
				<SideBarSection separator>
					<TextFieldLabel label="Compliance" />
					<Button
						noHover={compliance.approved || compliance.requested || compliance.responded}
						variant={compliance.approved ? "positive" : compliance.requested ? "warning" : ""}
						disabled={reviewDisabled}
						onClick={performComplianceReview}
					>
						{compliance.approved ? "Approved" : compliance.responded ? "Responded" : compliance.requested ? "Requested" : "Request Approval"}
					</Button>
				</SideBarSection>
			)}
			{/* PEER REVIEW */}
			{peerReviewAvailable && (
				<SideBarSection separator>
					<TextFieldLabel label="Peer Review" />
					<Button
						noHover={peerReview.approved || peerReview.requested || peerReview.responded}
						variant={peerReview.approved ? "positive" : peerReview.requested ? "warning" : ""}
						disabled={reviewDisabled}
						onClick={performPeerReview}
					>
						{peerReview.approved ? "Approved" : peerReview.responded ? "Responded" : peerReview.requested ? "Requested" : "Request Review"}
					</Button>
				</SideBarSection>
			)}
			<SideBarFooter
				preview={preview}
				publish={publish}
				saving={saving}
				saved={saved}
				previewLoading={previewLoading}
				publishEnabled={publishEnabled}
				publishWithoutReviewEnabled={publishWithoutReviewEnabled}
				publishOverride={publishOverride}
				publishing={publishing}
				requiresReview={complianceReviewAvailable || peerReviewAvailable}
			/>

		</SidebarExtensionWrapper>
	)
}

const SingleTickerSelection = ({setDocumentIdentifier, setDataStatus, template, data}) => {

	const [text, setText] = useState("");

	const remote = useRemote();
	
	const blurOnEnter = (e) => {
		if (e.key === 'Enter') {
			e.target.blur()
		}
	}

	useEffect(() => {
		if (text === '') {
			setDataStatus(null);
			setDocumentIdentifier(null);
		} else {
			setDocumentIdentifier(`${text}/${template}`)

			remote.get(`/data/data-status/${text}/${template}`)
				.then(result => setDataStatus(result.message));

			data.updateTicker([text], "SIDEBAR");
		}

	}, [text]);

	return (
		<TextInput
			label="Ticker"
			// size="small"
			onBlur={(e) => setText(e.target.value)}
			onChange={(e) => {console.log("text input onchanging ", e.target.value)}}
			onKeyDown={(e) => blurOnEnter(e)}
			name="ticker"
			initialValue={text}
			placeholder="Enter Ticker..."
		/>
	);

}

const TemplateDatasetTickerSelection = ({template, setDocumentIdentifier, data}) => {

	const [selectedGroup, setSelectedGroup] = useState(null);

	return <>
		<DropDown clickable fullWidth label={template.ticker.selectionName} value={selectedGroup && selectedGroup.name}>
		{template.ticker.options.map((option, index) => (
			<li key={index} onClick={() => setSelectedGroup(option)}>
				{option.name}
			</li>
		))}
	</DropDown>
	{selectedGroup && (
	<FlexWrapper className="mt-l" wrap gap="m">
		{selectedGroup.tickers.map((ticker, idx) => {
			const checked = false;//tickers.includes(ticker);
			const hasData = false;//!!tickerMessage[ticker] && tickerMessage[ticker] !== "No data available";
			// Dummy const for old data
			const dataOutOfDate = "";
			return (
				<TickerItem
					tickerStatus={hasData ? "data-available" : dataOutOfDate ? "data-out-of-date" : "data-unavailable"}
					key={idx}
					index={idx}
					ticker={ticker}
					tickerMessage={null/*tickerMessage[ticker]*/}
					hasData={hasData}
					checked={checked}
					loading={false/*props.loadingLocalData*/}
					onChange={() => {}}
				/>
			);
		})}
	</FlexWrapper>
	)}
</>
}

const GroupingTickerSelection = ({template, setDocumentIdentifier, data}) => {

	const remote = useRemote();

	const [grouping, setGrouping] = useState(null);
	const [selectedGroup, setSelectedGroup] = useState(null);

	const [availableTickers, setAvailableTickers] = useState([]);
	const [selectedTickers, setSelectedTickers] = useState([]);

	const [dataStatii, setDataStatii] = useState([]);
	
	
	
	useEffect(() => {
		if (!selectedGroup) {
			return;
			setAvailableTickers([]);
		}
		console.log("Selected group has changed and is ", selectedGroup)
		remote.get(`/crm/groupings/${selectedGroup.grouping}/${selectedGroup.id}`).then(groups => {
			setAvailableTickers(groups.tickers.map(t => t.tickerDetails.ric));
		})
	}, [selectedGroup])

	useEffect(() => {
		//TODO get initial selected state from local storage
		const selected = availableTickers.map(t => false);
		setSelectedTickers(selected);

		const initialData = availableTickers.map(t => null);
		setDataStatii(initialData);

		availableTickers.forEach((t, idx) => {
			remote.get(`/data/data-status/${t}/${template.name}`)
					.then(result => {
						const tempStatii = [...dataStatii];
						tempStatii[idx] = result.message;
						setDataStatii(tempStatii);
					}
			)
		})

	}, [availableTickers]);


	useEffect(() => {
		if (template.ticker.type === 'selection') {
			if (template.ticker.lookup) {
				remote.get("/crm/groupings").then(groupings => {
					if (groupings && groupings.length) {
						const lookup = groupings.filter(g => g.groupName === template.ticker.lookup);
						if (lookup.length) {
							remote.get(`/crm/groupings/${lookup[0].id}`).then(modelGrouping => {
								setGrouping(modelGrouping);
							})
						}
					}
				})
			}
		}
	}, [])

	const onCheckboxTickerToggle = (ticker, checked) => {
		console.log("Checking ticker ", ticker, checked);
		const idx = availableTickers.indexOf(ticker);

		const [...tempSelected] = selectedTickers;
		tempSelected[idx] = checked;
		setSelectedTickers(tempSelected);	
		console.log("Setting selected tickers ", tempSelected)		
		data.setTickers(availableTickers.filter((val, idx) => tempSelected[idx]));
		console.log("Setting data settickers as ", availableTickers.filter((val, idx) => tempSelected[idx]))	
	}

	if (!grouping) {
		return null;
	}

	return <>
		<DropDown clickable fullWidth label={template.ticker.selectionName} value={selectedGroup && selectedGroup.groupName}>
			{grouping.groups.map((option, index) => (
				<li key={index} onClick={() => setSelectedGroup(option) }>
					{option.groupName}
				</li>
			))}
		</DropDown>
		{availableTickers &&<FlexWrapper className="mt-l" wrap gap="m">
			{availableTickers.map((ticker, idx) => {
				const checked = selectedTickers[idx];
				const hasData = dataStatii[idx] && dataStatii[idx] !== "No data available";
				// Dummy const for old data
				const dataOutOfDate = "";
				return (
					<TickerItem
						tickerStatus={hasData ? "data-available" : dataOutOfDate ? "data-out-of-date" : "data-unavailable"}
						key={idx}
						index={idx}
						ticker={ticker}
						tickerMessage={dataStatii[idx]}
						hasData={hasData}
						checked={checked}
						loading={dataStatii[idx] === null}
						onChange={() => onCheckboxTickerToggle(ticker, !checked)}
					/>
				);
			})}
		</FlexWrapper>
	}
	</>
}


class EditorSideBarExtension extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// compliance: {
			// 	requested: false,
			// 	approved: false,
			// 	denied: false,
			// 	comment: false
			// },
			// peerReview: {
			// 	requested: false,
			// 	approved: false,
			// 	denied: false,
			// 	comment: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus asperiores sint pariatur deserunt quos voluptatem."
			// },
			templates: [],
			selectedTemplate: "",
			template: null,
			// Save state for the footer button found in SideBarFooter.js(bottom of page).
			saved: false,
			saving: false,
			tickers: [""],
			selectedTickers: [""],
			selectionGroups: [],
			uploading: false,
			showUploadingSpinner: true,
			uploadingMessage: "",
			tickerMessage: {},
			loadingTickers: false,
			// loadingLocalData: false,
			
			sideBarCollapsed: false,
			lastSavedDate: new Date()
		}

		this.onTickerTextChange = this.onTickerTextChange.bind(this);
		this.onUpdateTemplate = this.onUpdateTemplate.bind(this);
		this.onUpdateTickerGroup = this.onUpdateTickerGroup.bind(this);
		this.onCheckboxTickerToggle = this.onCheckboxTickerToggle.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.onUploadComplete = this.onUploadComplete.bind(this);
		this.updateTickerStatus = this.updateTickerStatus.bind(this);
		this.reviewDisabled = this.reviewDisabled.bind(this);
		this.performComplianceReview = this.performComplianceReview.bind(this);
		this.publish = this.publish.bind(this);
		this.publishOverride = this.publishOverride.bind(this);
		this.statusCheck = this.statusCheck.bind(this);
		this.preview = this.preview.bind(this);
		this.getDocumentIdentifier = this.getDocumentIdentifier.bind(this);


		this.reorderTicker = this.reorderTicker.bind(this);

		this.includePage = this.includePage.bind(this);
		this.listener = this.listener.bind(this);
		this.saveErrorListener = this.saveErrorListener.bind(this);
		this.blurOnEnter = this.blurOnEnter.bind(this)
		this.peerReviewAvailable = this.peerReviewAvailable.bind(this);
		this.complianceReviewAvailable = this.complianceReviewAvailable.bind(this);
		
	}

	complianceReviewAvailable() {
		const org = this.props.organisation;
		if (!org) {
			return true;
		}
		return org && org.complianceReviewAvailable;
	}

	peerReviewAvailable() {
		const org = this.props.organisation;
		if (!org) {
			return true;
		}
		return org && org.peerReviewAvailable;
	}

	getDocumentIdentifier() {
		let documentIdentifier = '';
		if (!this.state.template) {
			return;
		}
		const tickerConf = this.state.template.ticker;
		if (tickerConf.type === TEMPLATE_TICKER_TYPES.OPEN) {
			documentIdentifier = this.state.tickers.join(',');
		} else if (tickerConf.type === TEMPLATE_TICKER_TYPES.SELECTION) {
			if (!this.state.selectedTickerGroup) {
				return '';
			}
			documentIdentifier = this.state.selectedTickerGroup.name;
		} else if (tickerConf.type === TEMPLATE_TICKER_TYPES.FIXED) {
			documentIdentifier = tickerConf.ticker;
		}
		return documentIdentifier;
	}

	performComplianceReview() {
		const { /*tickers,*/ selectedTemplate, selectedTickerGroup } = this.state;
		const {compliance} = this.props
		compliance.requesting = true;
		this.props.setCompliance(compliance)
		

		const data = {};
		if (selectedTickerGroup && selectedTickerGroup.tickers) {
			data.tickers = selectedTickerGroup.tickers;
		}
		this.props.remote.post("/client/review/compliance/" + this.getDocumentIdentifier() + "/" + selectedTemplate, data)
			.then(resp => {
				if (resp.success) {
					if (resp.message) {
						this.props.showModal("reviewResent");
					}
					compliance.requested = true;
					this.props.setCompliance(compliance);
				} else {
					this.props.showModal("reviewFailure");
				}
			});
			this.props.setSideBarExtRight("Comments")
	}

	performPeerReview() {

		this.props.showModal("analystChecklist", () => {
			const { /*tickers,*/ selectedTemplate, selectedTickerGroup } = this.state;
			const {peerReview} = this.props
			peerReview.requesting = true;
			this.props.setPeerReview(peerReview);
			this.props.downloadPDF({
				tickers: this.state.tickers,
				template: this.state.selectedTemplate,
				templateName: this.state.selectedTemplate,
				tickerGroup: this.state.selectedTickerGroup && this.state.selectedTickerGroup.name
			}, dto => {
				if (selectedTickerGroup && selectedTickerGroup.tickers) {
					dto.allTickers = selectedTickerGroup.tickers;
				}
				this.props.remote.post("/client/review/peer/" + this.getDocumentIdentifier() + "/" + selectedTemplate, dto)
					.then(resp => {
						if (resp.success) {
							if (resp.message) {
								this.props.showModal("reviewResent");
							}
							peerReview.requested = true;
							this.props.setPeerReview( peerReview );
						} else {
							if (resp.message) {
								this.props.showModal("reviewFailure", null, null, { message: resp.message });
							} else {
								this.props.showModal("reviewFailure");
							}
						}
					});
			});
		});
		this.props.setSideBarExtRight("Comments")
	}

	includePage(page) {
		const optionalPages = this.props.optionalPages;
		const index = optionalPages.indexOf(page);
		if (index > -1) {
			optionalPages.splice(index, 1);
		} else {
			optionalPages.push(page);
		}
		this.props.setOptionalPages(optionalPages );
		this.props.data.updateLocalData("OPTIONAL_PAGES", JSON.stringify(optionalPages), "SIDEBAR");
	}

	onTickerTextChange(event, index) {
		let value = event.currentTarget.value.toUpperCase();
		this.setState(({ tickers }) => ({ tickers: tickers.slice(0, index).concat(value).concat(tickers.slice(index + 1, tickers.length)) }));
	}

	/**
	 * Update the ticker status.  Triggers pulls for review state and 
	 * also updates data controller with the currently active tickers.
	 * 
	 * 
	 */
	updateTickerStatus(force) {
		const { tickers, selectedTemplate, selectedTickers, template, selectedTickerGroup } = this.state;
		if (template && template.ticker.type === TEMPLATE_TICKER_TYPES.OPEN) {
			const noChange = this.scalarCompareArrays(tickers, selectedTickers);
			if (noChange && !force) {
				return;
			}
			const documentIdentifier = this.getDocumentIdentifier();
			if (tickers && tickers.length > 0) {
				const tickersToCheck = tickers;
				Promise.all(
					tickersToCheck.map(ticker =>
						this.props.remote.get(`/data/data-status/${ticker}/${selectedTemplate}`)
							.then(result => this.setState(({ tickerMessage }) => ({ tickerMessage: { ...tickerMessage, [ticker]: result.message } })))
					)
				).then(() => {
					this.setState({ selectedTickers: tickers });
					this.props.data.updateTicker(this.state.tickers, "SIDEBAR");
				});
				this.props.remote.get("/client/review/compliance/" + documentIdentifier + "/" + selectedTemplate)
					.then(compliance => {
						this.props.setCompliance( compliance );
					});
				this.props.remote.get("/client/review/peer/" + documentIdentifier + "/" + selectedTemplate)
					.then(peerReview => {
						this.props.setPeerReview(peerReview);
					});
			}
		} else if (template && template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION) {
			const noChange = this.scalarCompareArrays(tickers, selectedTickers);
			if (noChange) {
				return;
			}
			this.setState({ selectedTickers: tickers }, () => this.props.data.setTickers(this.state.tickers, "SIDEBAR"));
		} else if (template && template.ticker.type === TEMPLATE_TICKER_TYPES.FIXED) {
			const fixed = template.ticker.ticker;
			if (tickers === [fixed]) {
				//nochange
				return
			}
			console.log("Setting tickers to ", [fixed]);
			this.setState({ selectedTickers: [fixed] }, () => this.props.data.setTickers([fixed], "SIDEBAR"));
		}
	}

	blurOnEnter(e) {
		if (e.key === 'Enter') {
			e.target.blur()
		}
	}

	onUpdateTemplate(wasSelection) {
		const { selectedTemplate } = this.state;
		const prevTemplate = this.state.template;
		if (selectedTemplate !== prevTemplate) {
			this.props.remote.get("/data/templates/" + selectedTemplate).then(template => {
				let { tickers } = this.state;
				if (template.optionalPages) {
					this.props.setOptionalPages([])
				} else {
					this.props.setOptionalPages(null);
				}
				const compliance = {
					requested: false,
					approved: false,
					denied: false,
					comment: false
				};
				const peerReview = {
					requested: false,
					approved: false,
					denied: false,
					comment: ""
				}


				this.setState({ template, selectedTickers: [""], tickers: [""], selectedTickerGroup: null });
				this.props.setPeerReview(peerReview)
				this.props.setCompliance(compliance)
				this.props.data.registerTemplate(template, selectedTemplate);
				this.props.onTemplateChange(template);
				//This changes the syncs selectedTickers with tickers
				//this.updateTickerStatus(true);

				if (template.ticker.type === 'selection') {
					if (template.ticker.lookup) {
						this.props.remote.get("/crm/groupings").then(groupings => {
							if (groupings && groupings.length) {
								const lookup = groupings.filter(g => g.groupName === template.ticker.lookup);
								if (lookup.length) {
									this.props.remote.get(`/crm/groupings/${lookup[0].id}`).then(modelGrouping => {
										this.setState({selectionGroups:modelGrouping.groups});
									})
								}
							}
						})
					}
				}
				if (template.ticker.type === 'fixed') {
					const fixedTicker = template.ticker.ticker;
					this.setState({ selectedTickers: [fixedTicker] }, () => this.props.data.setTickers([fixedTicker], "SIDEBAR"));
				}

			});
			
		}
	}

	onUpdateTickerGroup() {
		let { selectedTickerGroup, selectedTemplate } = this.state;
		console.log("Updated ticker group, selectedTickerGroup is ", selectedTickerGroup)
		if (!selectedTickerGroup || !selectedTickerGroup.tickers) {
			return;
		}
		this.props.setLoadingLocalData(true);
		const documentIdentifier = this.getDocumentIdentifier();
		selectedTickerGroup.tickers.map(ticker =>
			this.props.remote.get(`/data/data-status/${ticker}/${selectedTemplate}`)
				.then(result => this.setState(({ tickerMessage }) => ({ tickerMessage: { ...tickerMessage, [ticker]: result.message } })))
		);
		this.props.remote.get("/client/review/compliance/" + documentIdentifier + "/" + selectedTemplate)
			.then(compliance => {
				this.props.setCompliance(compliance);
			});
		this.props.remote.get("/client/review/peer/" + documentIdentifier + "/" + selectedTemplate)
			.then(peerReview => {
				this.props.setPeerReview( peerReview );
			});

		this.props.data.updateTickerGroup(selectedTickerGroup.name, selectedTickerGroup.tickers);
	}

	scalarCompareArrays(arr1, arr2) {
		return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
	}

	onCheckboxTickerToggle(ticker, checked) {
		const { tickers, selectedTickerGroup } = this.state;
		const newTickers = selectedTickerGroup.tickers.filter(tgTicker => tgTicker === ticker ? !checked : tickers.includes(tgTicker));
		this.setState({ tickers: newTickers }, this.updateTickerStatus);
	}

	reviewDisabled() {
		const { template, tickers } = this.state;

		if (!template) {
			return true;
		}

		if (template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION) {
			const { selectedTickerGroup } = this.state;
			if (!selectedTickerGroup) {
				return true;
			}
		} else if (template.ticker.type === TEMPLATE_TICKER_TYPES.OPEN) {
			for (let i = 0; i < template.ticker.count; i++) {
				const ticker = tickers[i];
				if (!ticker || ticker.length === 0) {
					return true;
				}
			}
		} else {
			return true;
		}

		return false;
	}

	publish() {
		this.props.showModal("publishConfirm", () => {
			this.props.downloadPDF(
				{
					tickers: this.state.tickers,
					template: this.state.selectedTemplate,
					templateName: this.state.selectedTemplate,
					tickerGroup: this.state.selectedTickerGroup && this.state.selectedTickerGroup.name
				},
				dto => this.publishAction(dto, "/publishv2")
			);
		});
	}

	preview() {
		this.setState({ previewLoading: true });

		this.props.downloadPDF(
			{
				tickers: this.state.tickers,
				template: this.state.selectedTemplate,
				templateName: this.state.selectedTemplate,
				tickerGroup: this.state.selectedTickerGroup && this.state.selectedTickerGroup.name
			}, null, () => {
				this.setState({ previewLoading: false });
			}
		);
	}

	publishAction(dto, url) {
		this.setState({ publishing: true });
		dto.template = this.state.selectedTemplate;
		this.props.remote.post(url, dto)
			.then(resp => {
				if (!resp.success) {
					this.props.showModal("publishFailure", null, null, { message: resp.message, data: resp.data });
					this.setState({ publishing: false });
				} else {
					this.props.showModal("publishSuccess");
					this.updateTickerStatus(true);
					this.setState({ publishing: false });
					this.props.changeRoute("/?note-settings="+resp.data)
				}
			});
	}

	publishOverride() {
		this.props.showModal("overrideConfirm", () => {
			this.props.downloadPDF(
				{
					tickers: this.state.tickers,
					template: this.state.selectedTemplate,
					templateName: this.state.selectedTemplate,
					tickerGroup: this.state.selectedTickerGroup && this.state.selectedTickerGroup.name
				},
				dto => this.publishAction(dto, "/publishv2/override")
			);
		});
	}

	onDrop(files) {
		this.setState({ uploading: true, showUploadingSpinner: true, uploadingMessage: "Uploading ..." });
		const tooBig = [];
		const wrongFileType = [];
		let anyUploaded = false;
		for (var i = 0; i < files.length; i++) {
			let file = files[i];
			let name = file.name;

			if (file.size > 5 * 1024 * 1024) {
				console.log("rejecting too big file ", file.size);
				tooBig.push(file);
				continue;
			}
			if (file.type === null || !file.type.includes("spreadsheetml")) {
				console.log("rejecting wrong file type file ", file.type);
				wrongFileType.push(file);
				continue;
			}

			console.log("Accepting file ", file.size, file.type, file.name);
			anyUploaded = true;
			let xhr = new XMLHttpRequest();
			// xhr.open('post', "/api/modules/crm/provider/" + this.props.location);
			xhr.open('post', "/api/data/upload", true);
			xhr.addEventListener('load', e => {
				// if (typeof data !== Object) {
				// 	data = JSON.parse(data);
				// }

				if (xhr.status >= 200 && xhr.status < 300) {
					this.onUploadComplete(e);
				} else {
					console.log(xhr);
				}
			});
			xhr.upload.addEventListener('progress', e => {
				if (e.lengthComputable) {
					let complete = (e.loaded / e.total * 100 | 0);
					if (complete === 100) {
						//TODO onscreen reporting
						console.log("Processing ...");
					} else {
						console.log("Finished");
					}
				}
			});

			xhr.setRequestHeader("Content-Type", file.type);
			xhr.setRequestHeader("Content-Disposition", "attachment; filename=" + name);
			xhr.setRequestHeader("X-3Skye-Session", this.props.remote.createTokenHeader().headers["X-3Skye-Session"]);
			xhr.send(file);
		}
		if (tooBig.length > 0 || wrongFileType.length > 0) {
			this.props.showModal("badProforma", () => { }, () => { }, { tooBig, wrongFileType });
			if (!anyUploaded) {
				this.setState({ uploading: false, showUploadingSpinner: false });
			}
		}
	}

	onUploadComplete(e) {
		//Reload data, if we've got a ticker
		const resp = JSON.parse(e.target.response);
		if (!resp.success) {
			this.props.showModal("uploadFailure");
			this.setState({ uploading: false, showUploadingSpinner: false, uploadingMessage: resp.message });
		} else {
			this.setState({ showUploadingSpinner: false, uploadingMessage: resp.message });
			setTimeout(() => this.setState({ uploading: false }), 1500);
			this.updateTickerStatus(true);
			this.onUpdateTickerGroup();
		}
	}

	setEditorTemplate() {
		this.props.remote.get("/data/templates").then(templates => this.setState({ templates }));
		if (this.props.match.params.template) {
			const { template } = this.state;
			const isSelection = template && template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION
			const selectedTemplate = this.props.match.params.template;
			this.setState({ selectedTemplate }, () => this.onUpdateTemplate(isSelection))
		}
		this.statusCheck = setInterval(this.statusCheck, 60000);
		this.props.data.addLocalListener(this.listener);
		this.props.data.setSaveErrorListener(this.saveErrorListener);
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.template !== prevProps.match.params.template) {
			this.setEditorTemplate()
		}
		
	}

	componentDidMount() {
		this.setEditorTemplate()
	}

	componentWillUnmount() {
		if (this.statusCheck) {
			clearInterval(this.statusCheck);
		}
		this.props.data.removeLocalListener(this.listener);
	}

	saveErrorListener(failed) {
		if (failed) {
			this.props.showSaveError();
		} else {
			this.props.hideSaveError();
			this.setState({lastSavedDate:new Date()});
		}
	}

	listener() {
		//currently only optional pages need localdata
		if (this.state.template && this.state.template.optionalPages) {
			const dataName = "OPTIONAL_PAGES";
			const content = this.props.data.getLocalValue(dataName);
			if (content && content !== null && content !== "") {
				this.props.setOptionalPages(JSON.parse(content));
			}
		}

		if (this.state.template && this.state.template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION) {
			const { selectedTickerGroup } = this.state;
			if (selectedTickerGroup) {
				const dataName = "TICKERLIST";
				const localStore = this.props.data.getLocalValue(dataName);
				const content = (localStore && localStore.split(", ")) || selectedTickerGroup.tickers;


				const current = this.state.selectedTickers || [];

				if (this.scalarCompareArrays(current, content)) {
					//nothing to do
				} else {
					this.setState({ selectedTickers: content, tickers: content }, () => {
						if (!localStore) {
							//save the defaults back
							this.props.data.updateTicker(content);
						}
					});
				}
			}
		}
		this.props.setLoadingLocalData(false);
	}

	statusCheck() {
		const { tickers, selectedTemplate } = this.state;
		const documentIdentifier = this.getDocumentIdentifier();

		if (!tickers || !selectedTemplate || tickers.length === 0) {
			return;
		}
		if (this.props.compliance.requested && !this.props.compliance.approved && !this.props.compliance.denied)
			this.props.remote.get("/client/review/compliance/" + documentIdentifier + "/" + selectedTemplate)
				.then(compliance => {
					this.props.setCompliance( compliance );
				});
		if (this.props.peerReview.requested && !this.props.peerReview.approved && !this.props.peerReview.denied)
			this.props.remote.get("/client/review/peer/" + documentIdentifier + "/" + selectedTemplate)
				.then(peerReview => {
					this.props.setPeerReview( peerReview );
				});
	}

	reorderTicker({ oldIndex, newIndex }) {
		document.body.style.cursor = 'default';
		this.setState((prevState) => {
			prevState.selectedTickerGroup.tickers = arrayMove(prevState.selectedTickerGroup.tickers, oldIndex, newIndex);
			prevState.tickers = prevState.selectedTickerGroup.tickers.filter(ticker => prevState.tickers.includes(ticker));
			return prevState;
		}, () => this.updateTickerStatus(true));
	};

	
	render() {
		const { templates, selectedTemplate, saving, saved, collapsed, previewLoading, template, selectedTickerGroup } = this.state;
		const { compliance, peerReview } = this.props;

		//Publish is enabled if we have a _response_ regardless of yay or nay
		const publishEnabled = (this.complianceReviewAvailable() === false || compliance.responded)  && (this.peerReviewAvailable() === false || peerReview.responded);
		const publishWithoutReviewEnabled = this.state.template !== null && this.state.tickers && this.state.tickers[0] && this.state.tickers[0] !== "";

		const reviewDisabled = this.reviewDisabled();
		const isSelection = template && template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION;
		const tickerSectionHeader = isSelection || this.state.tickers.length > 1 ? "Tickers" : "Ticker";
		const sectorNoteSelected = template && template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION;

		return (
			<SidebarExtensionWrapper collapsable collapsed={false} style={{ paddingBottom: 260 }} shadow sideBarLeft>
				{/* TEAMPLATE */}
				<SideBarSection>
					<DropDown label="Template" clickable fullWidth value={selectedTemplate ? selectedTemplate : "Select Template"} placeholder={selectedTemplate ? false : true}>
						{templates.map((template, index) => (
							<div key={index} onClick={() => this.setState({ selectedTemplate: template }, () => this.onUpdateTemplate(isSelection))}>
								{template}
							</div>
						))}
						{/* {templates.map((template, index) => (
								<li key={index} onClick={() => this.setState({ selectedTemplate: template }, () => this.onUpdateTemplate(isSelection))}>{template}</li>
							))} */}
					</DropDown>
				</SideBarSection>
				{/* TICKERS */}
				<SortableSidebarSection
					separator
					onSortStart={() => (document.body.style.cursor = "grabbing")}
					onSortEnd={this.reorderTicker}
					helperClass="drag-item"
				>
					{template && template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION ? (
						template.ticker.options ? (<>
							{/* Sector Dropdown */}
							<DropDown clickable fullWidth label={template.ticker.selectionName} value={selectedTickerGroup && selectedTickerGroup.name}>
								{template.ticker.options.map((option, index) => (
									<li key={index} onClick={() => this.setState({ selectedTickerGroup: option }, this.onUpdateTickerGroup)}>
										{option.name}
									</li>
								))}
							</DropDown>
							{selectedTickerGroup ? (
								<FlexWrapper className="mt-l" wrap gap="m">
									{selectedTickerGroup.tickers.map((ticker, idx) => {
										const checked = this.state.tickers.includes(ticker);
										const hasData = !!this.state.tickerMessage[ticker] && this.state.tickerMessage[ticker] !== "No data available";
										// Dummy const for old data
										const dataOutOfDate = "";
										return (
											<TickerItem
												tickerStatus={hasData ? "data-available" : dataOutOfDate ? "data-out-of-date" : "data-unavailable"}
												key={idx}
												index={idx}
												ticker={ticker}
												tickerMessage={this.state.tickerMessage[ticker]}
												hasData={hasData}
												checked={checked}
												loading={this.props.loadingLocalData}
												onChange={() => this.onCheckboxTickerToggle(ticker, checked)}
											/>
										);
									})}
								</FlexWrapper>
							) : null}
						</>) : (
							<>
								<DropDown clickable fullWidth label={template.ticker.selectionName} value={selectedTickerGroup && selectedTickerGroup.groupName}>
								{this.state.selectionGroups.map((option, index) => (
									<li key={index} onClick={() => this.setState({ selectedTickerGroup: option }, this.onUpdateTickerGroup)}>
										{option.groupName}
									</li>
								))}
							</DropDown>
							</>
						)
					) : (
						(template && template.ticker.type !== TEMPLATE_TICKER_TYPES.FIXED) ? this.state.tickers.map((ticker, idx) => {
							// return <input key={idx} type="text" onChange={e => this.onTickerTextChange(e, idx)} onBlur={e => this.updateTickerStatus(true, true)} name="ticker" value={ticker && ticker} placeholder="Enter Ticker..." />
							return (
								<TextInput
									label="Ticker"
									// size="small"
									key={idx}
									onChange={(e) => this.onTickerTextChange(e, idx)}
									onBlur={(e) => this.updateTickerStatus(true, true)}
									onKeyDown={(e) => this.blurOnEnter(e)}
									name="ticker"
									value={ticker && ticker}
									placeholder="Enter Ticker..."
								/>
							);
						}) : null
					)}
{/* 					{this.state.tickers[0] && <>
					<p className="label-input mt-xl">Note last saved</p>
					<p>{format(this.state.lastSavedDate, 'HH:mm:ss')}</p>
					</>}
 */}					<p className="label-input mt-xl">Market Data Spreadsheet</p>

					<Dropzone
						onDrop={this.onDrop}
						uploading={this.state.uploading}
						showUploadingSpinner={this.state.showUploadingSpinner}
						uploadingMessage={this.state.uploadingMessage}
					/>
					{this.state.tickerMessage[this.state.tickers[0]] && (
						<AlertBlock
							justify="center"
							className="mt-m"
							type={this.state.tickerMessage[this.state.tickers[0]] !== "No data available" ? "positive" : "warning"}
							small
							fullWidth
						>
							{this.state.tickerMessage[this.state.tickers[0]]}
						</AlertBlock>
					)}
				</SortableSidebarSection>
				{/* COMPLIANCE */}
				{this.complianceReviewAvailable() && (
					<SideBarSection separator>
						<TextFieldLabel label="Compliance" />
						<Button
							noHover={compliance.approved || compliance.requested || compliance.responded}
							variant={compliance.approved ? "positive" : compliance.requested ? "warning" : ""}
							disabled={reviewDisabled}
							onClick={this.performComplianceReview}
						>
							{compliance.approved ? "Approved" : compliance.responded ? "Responded" : compliance.requested ? "Requested" : "Request Approval"}
						</Button>
					</SideBarSection>
				)}
				{/* PEER REVIEW */}
				{this.peerReviewAvailable() && (
					<SideBarSection separator>
						<TextFieldLabel label="Peer Review" />
						<Button
							noHover={peerReview.approved || peerReview.requested || peerReview.responded}
							variant={peerReview.approved ? "positive" : peerReview.requested ? "warning" : ""}
							disabled={reviewDisabled}
							onClick={this.performPeerReview.bind(this)}
						>
							{peerReview.approved ? "Approved" : peerReview.responded ? "Responded" : peerReview.requested ? "Requested" : "Request Review"}
						</Button>
					</SideBarSection>
				)}
				{!this.props.fixedFooter && (
					<SideBarFooter
						preview={this.preview}
						publish={this.publish}
						saving={saving}
						saved={saved}
						previewLoading={previewLoading}
						publishEnabled={publishEnabled}
						publishWithoutReviewEnabled={publishWithoutReviewEnabled}
						publishOverride={this.publishOverride}
						publishing={this.state.publishing}
						requiresReview={this.complianceReviewAvailable() || this.peerReviewAvailable()}
					/>
				)}
				{/* FOOTER */}
				{this.props.fixedFooter && (
					<SideBarFooter
						fixed
						preview={this.preview}
						publish={this.publish}
						saving={saving}
						saved={saved}
						previewLoading={previewLoading}
						publishEnabled={publishEnabled}
						publishWithoutReviewEnabled={publishWithoutReviewEnabled}
						requiresReview={this.complianceReviewAvailable() || this.peerReviewAvailable()}
					/>
				)}
			</SidebarExtensionWrapper>
		);
	}
}

export default withData(withRouteChange(EditorSideBarExtension));


const DatasetSelector = ({template, selectedTickerGroup, setSelectedTickerGroup, onCheckboxTickerToggle}) => {

	//setSelectedTickerGroup: this.setState({ selectedTickerGroup: option }, this.onUpdateTickerGroup)

	return <>
		<DropDown clickable fullWidth label={template.ticker.selectionName} value={selectedTickerGroup && selectedTickerGroup.name}>
			{template.ticker.options.map((option, index) => (
				<li key={index} onClick={() => setSelectedTickerGroup(option)}>
					{option.name}
				</li>
			))}
		</DropDown>
		{selectedTickerGroup ? (
			<FlexWrapper className="mt-l" wrap gap="m">
				{selectedTickerGroup.tickers.map((ticker, idx) => {
					const checked = this.state.tickers.includes(ticker);
					const hasData = !!this.state.tickerMessage[ticker] && this.state.tickerMessage[ticker] !== "No data available";
					// Dummy const for old data
					const dataOutOfDate = "";
					return (
						<TickerItem
							tickerStatus={hasData ? "data-available" : dataOutOfDate ? "data-out-of-date" : "data-unavailable"}
							key={idx}
							index={idx}
							ticker={ticker}
							tickerMessage={this.state.tickerMessage[ticker]}
							hasData={hasData}
							checked={checked}
							loading={this.props.loadingLocalData}
							onChange={() => onCheckboxTickerToggle(ticker, checked)}
						/>
					);
				})}
			</FlexWrapper>
		) : null}
	</>
}