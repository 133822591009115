import React from 'react';
import FlexWrapper from '../../FlexWrapper';
import AnalyticsToggleHeader from './AnalyticsToggleHeader';
import DateRangeToggle from './DateRangeToggle';

const AnalyticsToggleBlock = ({children, dateRanges, header, headers}) => {
	return (
		<FlexWrapper direction="column" gap="l" fullWidth align="flex-start">
			<FlexWrapper className="analytics-header" fullWidth justify="space-between">
				{/* Header elements */}
				<AnalyticsToggleHeader value={header} />
				{dateRanges && <DateRangeToggle value="6 Months" />}
			</FlexWrapper>
			{/* Content */}
			{children}
		</FlexWrapper>
   );
}

export default AnalyticsToggleBlock;