import React, { useEffect, useState } from 'react';
import { analyticsTickersDummyData, dummyResearch, dummyUser } from "../../../Utils/DummyData"
import { countReadersPerDoc, sumValues } from '../../../Utils/Utils'
import { useHistory } from 'react-router-dom';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import Button from '../../../components/Buttons/Button';
import { ArrowLeft } from 'react-feather';
import TickerListSearchField from '../../../components/Input/TickerListSearchField';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Table from '../../../components/Table/Table';
import { useRemote, getIcon } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import withRouteChange from "@threeskye/route-change"
import TickerSectorItem from '../../../components/TickerSectorItem';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';


const AnalyticsTickersList = ({ changeRoute }) => {
	const [tickerSearchTerm, setTickerSearchTerm] = useState("")
	const [filteredTickers, setFilteredTickers] = useState([])
	const [showRecentlyAdded, setShowRecentlyAdded] = useState(false);
	const [tickersLoading, setTickersLoading] = useState(true)
	const [tickers, setTickers] = useState([]);
	let history = useHistory();
	const remote = useRemote();

	useEffect(() => {
		remote.get("/crm/tickers").then(setTickers).then(() => setTickersLoading(false));
	}, [])

    // search filter
	useEffect(() => {
		const resultsToFilter = [...analyticsTickersDummyData];
		const filteredResults = resultsToFilter.filter((ticker) => {
			let checks = []
			if (tickerSearchTerm) {
				ticker.ric === tickerSearchTerm ? checks.push(true) : checks.push(false)
			}
			return checks.includes(false) ? false : true
		})
		setFilteredTickers(filteredResults)
	}, [tickerSearchTerm])

	// table data
	const colWidths = ["auto", "70px", "70px", "70px", "70px", "70px", "70px"];
	const headers = ["Code", getIcon("send", "16px"), getIcon("fail", "16px"), getIcon("read", "16px"), getIcon("views", "16px"), getIcon("downloads", "16px"), getIcon("modalDownloads", "16px")]
	
	const dataMap = (data) => data.map((ticker, idx) => {
		const researchesOnTicker = dummyResearch.filter(r => r.ticker === ticker.ric)
		const delivered = countReadersPerDoc(researchesOnTicker, dummyUser, "delivered", true)
		const sent = countReadersPerDoc(researchesOnTicker, dummyUser, "sent", true)
		const pdf = countReadersPerDoc(researchesOnTicker, dummyUser, "download", "pdf")
		const modal = countReadersPerDoc(researchesOnTicker, dummyUser, "download", "modal")
		const email = countReadersPerDoc(researchesOnTicker, dummyUser, "viewedIn", "email")
		const portal = countReadersPerDoc(researchesOnTicker, dummyUser, "viewedIn", "portal")
				
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/ticker/" + ticker.tickerId)}>
				<td><TickerSectorItem label={ticker.ric} extensionLabel={ticker.name} image={ticker.logo} country={ticker.country} filename={ticker.ric} /></td>
				<td><div className="icon-counter-count">{sumValues(delivered)}</div></td>
				<td><div className="icon-counter-count">{sumValues(sent) - sumValues(delivered)}</div></td>
				<td><div className="icon-counter-count">{sumValues(email)}</div></td>
				<td><div className="icon-counter-count">{sumValues(portal)}</div></td>
				<td><div className="icon-counter-count">{sumValues(pdf)}</div></td>
				<td><div className="icon-counter-count">{sumValues(modal)}</div></td>
			</tr>
		)
	});

	return (
		<Page fixedBanner showScrollbar>
			<PageContentBanner divider gap="l">
				<Button className="button-variant-secondary" icon={<ArrowLeft />} onClick={() => history.goBack()}>Back</Button>
				<TickerListSearchField
					size="small"
					width="m"
					placeholder="Try searching AIA.NZ"
					onChange={setTickerSearchTerm}
					value={tickerSearchTerm}
					onClear={() => setTickerSearchTerm("")}
					tickerListOverwrite={analyticsTickersDummyData}
					onlyTickers={true}
					isClearable
					autoFocus
				/>
			</PageContentBanner>

			<PageContentBodyGrid rowGap="xl" showScrollbar>
				<PageGridItem colSpan="12">
					{showRecentlyAdded || tickerSearchTerm ? (
						<Table
							minWidth={900}
							width={1100}
							colWidths={colWidths}
							headers={headers}
							dataMap={dataMap(tickerSearchTerm ? filteredTickers : analyticsTickersDummyData)}
						// dataLoading={tickersLoading}
						/>
					) : (
						<SuggestionMessage>
							Start typing to see results or{" "}
							<span className="text-link" onClick={() => setShowRecentlyAdded(true)}>
								click here
							</span>{" "}
							to see recently added
						</SuggestionMessage>
					)}
					{/* Empty state message*/}
					{/* <NoResultsMessage /> */}
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(AnalyticsTickersList);