import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../../components/Buttons/Button';
import { CornerDownLeft, Mail, Plus, RefreshCw, Trash, User, UserX, } from 'react-feather';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import IconCounter from '../../../components/IconCounter';
import Table from '../../../components/Table/Table';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import useWindowDimensions, { formatDate, useRemote, undoDeleteDistributionList } from '../../../Utils/Utils';
import TableActionIconGroup from '../../../components/Table/TableActionIconGroup';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import TickerSectorItem from '../../../components/TickerSectorItem';
import { toast, toastDanger, UndoToastMessage } from '../../../components/popups/Toast';
import { fromUnixTime } from 'date-fns';
import TableActionIcon from '../../../components/Table/TableActionIcon';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import LoadingIcon from '../../../components/LoadingIcon';



const SuppressionList = ({ changeRoute }) => {

	const CRAIGS_SUPPRESSION_LIST = 17360;	//FIXME hardcoded Craigs suppression key

	const colWidths = [35 + "%", "5%", 10 + "%", 50 + "%"];
	const headers = ["Name", "", "Date Created", "Reason"];
	const [bouncesLoading, setBouncesLoading] = useState(true);
	const [unsubscribesLoading, setUnsubscribesLoading] = useState(true);

	const remote = useRemote();

	const { width } = useWindowDimensions();

	const [bounces, setBounces] = useState([]);
	const [unsubscribes, setUnsubscribes] = useState([]);
	const [users, setUsers] = useState([])

	useEffect(() => {
		getBounces();
		remote.get("/crm/contacts").then(setUsers);	//TODO move to storage!
	}, []);


	const getBounces = () => {
		setBouncesLoading(true);
		setUnsubscribesLoading(true);
		remote.get("/crm/suppressions/bounce").then((b) =>{setBounces(b); setBouncesLoading(false);});
		remote.get("/crm/unsubscribes/"+CRAIGS_SUPPRESSION_LIST).then((u) => {setUnsubscribes(u); setUnsubscribesLoading(false);});	//hardcoded to Craigs! FIXME
	}

	const findUser = useMemo(() => (email) => {
		return users.filter(u => !u || !u.email || u.email.localeCompare(email) === 0);
	}, [users])


	const displayUser = (email) => {
		const user = findUser(email);
		if (user && user.length > 0) {
			const u = user[0];
			return <>{u.firstName + " " + u.lastName} <span className="extension-label ml-s">{u.email}</span>{" "}</>
		} else {
			return <>User not found <span className="extension-label ml-s">{email}</span>{" "}</>
		}
	}

	const removeUserFromSuppression = (user, list) => {
		if (list === 'bounce') {
			remote.delete(`/crm/suppressions/bounce/${user.id}`).then(setBounces).catch((e) => toast("Error removing bounce: "+e));
		} else {
			remote.delete(`/crm/unsubscribes/${CRAIGS_SUPPRESSION_LIST}/${user.id}`).then(setUnsubscribes).catch((e) => toast("Error removing unsubscribe: "+e));
		}
	}

	const actions = (email, list) => {
		const user = findUser(email);
		if (user && user.length > 0) {
			const u = user[0];
			return <>
					<TableActionIcon icon={<Trash />}
						toolTip="Remove user from suppression"
						onClick={(e) => {
							e.stopPropagation();
							removeUserFromSuppression(u,list);
						}}/>
					<TableActionIcon icon={<User />}
						toolTip="View user details"
						onClick={(e) => {
							e.stopPropagation();
							changeRoute(ROUTE_PATHS[PAGES.CRM] + "/users/user/"+u.id);
						}}/>
			</>
		} else {
			return null;
		}
	}

	const bouncesTable = bounces.map((b,idx) => <tr 
		key={idx}>
		<td>
			{displayUser(b.email)}
		</td>
		<td>{actions(b.email, 'bounce')}</td>
		<td>
		{formatDate(fromUnixTime(b.created), "dd/MM/yyyy")}
		</td>
			
		<td>
		{b.reason}
		</td>
	</tr>);

	const unsubscribeTable = unsubscribes.map((u, idx) => <tr
		key={idx} >
			<td>
			{displayUser(u)}
		</td>
		<td>{actions(u, CRAIGS_SUPPRESSION_LIST)}</td>
			
		<td>
		</td>
		<td>

		</td>
	</tr>)

	return (
		<Page fixedBanner id="crm-suppressions-page">
			{/* Old mailing list page below */}
			{/* <CraigsMailingListPage /> */}
			<PageContentBanner divider gap="m">
				{/* <Button icon={<UserPlus />} onClick={() => setModalShowing(true)}>Create User</Button> */}
				<Button icon={<RefreshCw />} onClick={getBounces} >{width < 900 ? "" : "Refresh"}</Button>
				{/* <SelectSearchField size="small" width="m" placeholder="Search lists" isClearable/> */}
				{/* <SearchTermFilter
					size="small"
					width="m"
					placeholder="Search lists"
					isClearable
					dataSet={data}
					setFilteredData={setFilteredData}
					path={["createdBy", "createdDate", "name"]}
					hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
				/> */}
			</PageContentBanner>
			<PageContentBodyGrid showScrollbar>
				<PageGridItem col="1 / span 6">
					<DetailSectionHeader header="Manual Unsubscribes" icon={<UserX/>} />
				</PageGridItem>
				<PageGridItem colSpan="12">
					{unsubscribesLoading ? <LoadingIcon centered size={50}/> : <Table minWidth={1000} colWidths={colWidths} headers={headers} dataMap={unsubscribeTable} />}
				</PageGridItem>
				<PageGridItem col="1 / span 6">
					<DetailSectionHeader header="Bounces" icon={<><Mail /><CornerDownLeft/></>} />
				</PageGridItem>
				<PageGridItem colSpan="12">
					{bouncesLoading ? <LoadingIcon centered size={50}/> : <Table minWidth={1000} colWidths={colWidths} headers={headers} dataMap={bouncesTable} />}
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(SuppressionList);