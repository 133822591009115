import React from 'react';
import { getPercentage } from '../../../Utils/Utils';
import FlexWrapper from '../../FlexWrapper';
import "./PercentageBars.scss";

const PercentageBars = ({ data }) => {
	const total = data.reduce((accumulator, object) => {
		return accumulator + object.value;
	}, 0);

	return (
		<FlexWrapper fullWidth className="percentage-bars-wrapper" gap="l" direction="column" align="flex-start">
			{data && data.length >= 0 && data.map((d, idx) => (
				<FlexWrapper fullWidth key={idx} direction="column" gap="s" align="flex-start" className="percentage-bar-wrapper">
					<FlexWrapper fullWidth gap="s" justify="space-between" className="bar-labels">
						<div className="bar-value">{d.name + " (" + d.value + ")"}</div>
						<div className="bar-percentage">{getPercentage(d.value, total, 1)}</div>
					</FlexWrapper>
					<div className="bar-meter"><div className="bar-meter-inner" style={{width: getPercentage(d.value, total, 1)}} /></div>
				</FlexWrapper>
			))}
		</FlexWrapper>
   );
}

export default PercentageBars;