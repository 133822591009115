import React, { Component } from "react";
import { ChevronDown } from "react-feather";
import "./DropDown.scss";
import Popup from "./Popup";
import PopupMenu from "./PopupMenu";
import PopupMenuItem from "./PopupMenuItem";

// Component for the details sections on the client and account pages.

class DropDown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: false,
			isHiding: false
		};
		this.onClick = this.onClick.bind(this);
		this.showDropdown = this.showDropdown.bind(this);
		this.hideDropdown = this.hideDropdown.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	onClick(itemClick, e) {
		itemClick && itemClick(e);
	}

	showDropdown() {
		this.setState({ isActive: true });
	}

	hideDropdown() {
		this.setState({ isHiding: true });
		setTimeout(() => {
			this.setState({ isActive: false, isHiding: false });
		}, 100);
	}

	handleKeyPress = (event) => {
		if(event.key === 'Escape'){
			this.hideDropdown()
		}
	}

	render() {
		// let className = "dropdown-selection-list-container";
		let classNames = "dropdown-container";
		let allocationDropdown;

		const { placeholder, filterLabel, size, onHover, transparent } = this.props;
		// const { isActive, isHiding } = this.state;

		if (this.props.classNames) {
			classNames += " " + this.props.classNames;
		}
		if (placeholder) {
			classNames += " placeholder";
		}
		if (transparent) {
			classNames += " transparent";
		}
		if (filterLabel) {
			allocationDropdown = <div className="filter-inner-label">{filterLabel}</div>;
		}
		// if (isActive) {
		// 	className += " dropdown-selection__active";
		// } else {
		// 	className += "";
		// }
		// if (fullWidth) {
		// 	classNames += " dropdown-full-width";
		// }
		// if (isHiding) {
		// 	className += " fade-out";
		// }
		if (size) {
			classNames += " dropdown-size-" + this.props.size;
		}

		return (
			<>
				{this.props.label && <label className="label-input">{this.props.label}</label>}
				<Popup
					hoverOnly={onHover}
					absolute={!this.props.fixed}
					relative={!this.props.fixed}
					popupOuterWidth={this.props.fixed ? "" : "100%"}
					triggerElement={
						<div
							onBlur={this.hideDropdown}
							className={classNames}
							onClick={this.state.isActive ? this.hideDropdown : this.showDropdown}
							onMouseEnter={this.props.clickable ? null : this.showDropdown}
							onMouseLeave={this.props.clickable ? null : this.hideDropdown}
							style={this.props.style ? this.props.style : null}
							tabIndex={0}
							onKeyDown={this.handleKeyPress}
						>
							{allocationDropdown}
							<div
								className={`dropdown-value-wrapper flex-center${this.state.isActive ? " dropdown-value__active" : ""}`}
								style={this.props.inputDropdown && { padding: "9px 12px" }}
							>
								{this.props.value ? this.props.value : "Select"}
								<ChevronDown className="dropdown-chevron" />
							</div>
						</div>
					}
					popupElement={
						<PopupMenu>
							{this.props.children.map((child, idx) => {
								return React.cloneElement(<PopupMenuItem noRadius label={child} key={idx} onClick={(e) => this.onClick(child.props.onClick, e)} />);
							})}
						</PopupMenu>
					}
				/>
			</>
		);
	}
}

export default DropDown;
