import React from 'react';
import { Mail } from 'react-feather';
import { formatDate } from '../../../Utils/Utils';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';

const DistributionListDetails = ({ editMode, list, editList }) => {
	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="List Details" icon={<Mail />} />
			</PageGridItem>
			<PageGridItem col={editMode ? "1 / span 6" : "1 / span 3"}>
				<DetailField autoFocus bold editMode={editMode} label="Name" value={list && list.name} onChange={(e) => editList({...list, name: e.target.value})} />
			</PageGridItem>
			{!editMode && (
				<>
					<PageGridItem col="4 / span 3">
						<DetailField label="Created By" value={list && list.createdBy && list.createdBy} />
					</PageGridItem>
					<PageGridItem col="1 / span 3">
						<DetailField label="Date Created" value={list && list.createdDate && formatDate(list.createdDate)} />
					</PageGridItem>
					<PageGridItem col="4 / span 3">
						<DetailField label="Last Distribution" value={list && list.lastDistribution && formatDate(list.lastDistribution)} />
					</PageGridItem>
				</>
			)}
		</>
	);
}

export default DistributionListDetails;