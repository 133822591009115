import React from 'react';
import TextArea from '../../../components/Input/TextArea';
import TextInput from '../../../components/Input/TextInput';

const DetailField = (props) => {
	const { label, value, editMode, onChange, autoFocus, invalid, helperText, disabled, textArea, type, placeholder, bold, variant, className, maxlength, onClick, style } = props;
	return (
		<div className={`detail-field-wrapper${className ? ' ' + className : ''}`} onClick={onClick} style={style}>
			{editMode ? (
				<React.Fragment>
					{textArea ? (
						<TextArea maxlength={maxlength} {...props} onChange={(e) => onChange(e.target.value)} value={value} className="transition-fade-up-slow"></TextArea>
					) : (
						<TextInput
							disabled={disabled}
							invalid={invalid}
							helperText={helperText}
							autoFocus={autoFocus}
							label={label}
							placeholder={placeholder || label}
							value={value}
							onChange={onChange}
							className="transition-fade-up-slow"
							type={type}
							variant={variant}
						/>
					)}
				</React.Fragment>
			) : (
				<React.Fragment>
					{label ? <label className="colour-text-label-grey transition-fade-up">{label}</label> : ""}
					<div className={`${label ? "mt-s " : ""}transition-fade-up-slow${bold ? " font-weight-semi-bold" : ""}`} style={{ wordBreak: "break-word" }}>
						{value ? value : "-"}
					</div>
				</React.Fragment>
			)}
		</div>
	);
}

export default DetailField;