import React from 'react';

class Extensions extends React.PureComponent {

	render() {
		const { comp } = this.props.match.params;
		console.log("Extensions received comp ", comp);
		const Component = React.lazy(()=>import("./extensions/" + comp));
			
		return <React.Suspense fallback={<div>Redirecting...</div>}><Component /></React.Suspense>
	}
}

export default Extensions;