import React, { useEffect, useState } from 'react';
import { Briefcase } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import Table from '../../../components/Table/Table';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from '@threeskye/route-change'
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import useWindowDimensions, { parseLocationDetails, useRemote, undoDeleteCompany } from '../../../Utils/Utils';
import TableActionIconGroup from '../../../components/Table/TableActionIconGroup';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import { toastDanger, toast, UndoToastMessage } from '../../../components/popups/Toast';

const CompanyLists = ({ changeRoute }) => {
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState(false)
	const [loading, setLoading] = useState(true)
	const remote = useRemote();
	const { width } = useWindowDimensions();

	useEffect(() => {
		remote.get("/crm/organisations")
			.then((organisations) => {

				let sortedOrganisations = organisations.sort((userA, userB) => {
					let nameA = userA.name
					let nameB = userB.name
					return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
				})
				return sortedOrganisations
			})
			.then((data) => {
				setData(data)
				setLoading(false)
			})
			.catch(() => setLoading(false));
	}, []);

	const addCompanyToData = (company) => {
		let companiesToSort = [...data]
		let sortedCompanies = companiesToSort.sort((userA, userB) => {
			let nameA = userA.name
			let nameB = userB.name
			return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
		})
		setData(sortedCompanies)
	}

	const removeCompany = (e, company) => {
		e.stopPropagation();
		remote.get(`/crm/organisations/${company.id}/contacts`)
			.then(companyContacts => {
				remote.delete(`/crm/organisations/${company.id}`)
					.then(() => {
						let newArray = []
						data.forEach((org) => {
							if (org.id !== company.id) {
								newArray.push(org)
							}
						})
						setData(newArray)
						toast(<UndoToastMessage message="Company removed" onClick={() => { undoDeleteCompany(company, companyContacts, addCompanyToData) }} />, { autoClose: 10000 })

					})
					.catch(() => {
						toastDanger("Company could not be removed")
					})
			})
	}

	const colWidths = [40 + "%", 40 + "%", 20 + "%"]
	const headers = ["Name", "Headquarters", ""]
	const dataMap = (dataToMap) => {
		return dataToMap.map((company, idx) => {
			let parsedCompany = parseLocationDetails(company)
			return (
				<tr className="cursor-pointer" key={idx} onClick={() => changeRoute(ROUTE_PATHS[PAGES.CRM] + "/companies/company/" + company.id)}>
					<td><p>{company.name}</p></td>
					<td><p>{parsedCompany.locations && parsedCompany.locations.location && parsedCompany.locations.location}</p></td>
					<TableActionIconGroup
						download={{ hide: true }}
						edit={{ onClick: (e) => { e.stopPropagation(); changeRoute(ROUTE_PATHS[PAGES.CRM] + "/companies/company/" + company.id + "?edit=true") } }}
						duplicate={{ onClick: (e) => { e.stopPropagation(); changeRoute(ROUTE_PATHS[PAGES.CRM] + "/companies/company/" + company.id + "?create=true") } }}
						remove={{ onClick: (e) => removeCompany(e, company) }}
					/>
				</tr>
			)
		})
	}

	return (
		<Page fixedBanner>
			{/* Modal */}
			<PageContentBanner divider gap="m">
				<Button icon={<Briefcase />} onClick={() => changeRoute(ROUTE_PATHS[PAGES.CRM] + "/companies/company/create")}>{width < 900 ? "" : "Create Company"}</Button>
				{/* <SelectSearchField size="small" width="m" placeholder="Search companies" isClearable /> */}
				<SearchTermFilter
					size="small"
					width="l"
					placeholder={`Search companies ${loading ? "" : "(" + data.length + ")"}`}
					isClearable
					dataSet={data}
					setFilteredData={setFilteredData}
					path={["name"]}
					hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
				/>
			</PageContentBanner>
			<PageContentBodyGrid rowGap="xl" showScrollbar>
				<PageGridItem container col="1 / span 12">
					<div>
						<Table
							minWidth={450}
							colWidths={colWidths}
							headers={headers}
							dataMap={dataMap(hasSearchTerm ? filteredData : data)}
							dataLoading={loading}
						/>
					</div>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(CompanyLists);