import React from 'react';
import LoadingIcon from '../components/LoadingIcon.js';
import OverlayWrapper from './OverlayWrapper';

const LoadingOverlay = (props) => {
	const { variant, size } = props;
	return (
		<OverlayWrapper>
			<LoadingIcon size={size ? size : 72} variant={variant} />
		</OverlayWrapper>
	)
}

export default LoadingOverlay;