import React, { useEffect, useState } from 'react';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import { getIcon, countReadersPerDoc, getUsersWithRelevantResearch } from '../../../Utils/Utils';
import Table from '../../../components/Table/Table';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { Eye, Mail, Smartphone, Monitor, User } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';
import FlexWrapper from '../../FlexWrapper';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import Divider from '../../../components/Divider';
import TickerSectorItem from '../../../components/TickerSectorItem';
import AnalyticsMetricFilter from '../../../components/Analytics/AnalyticsMetricFilter';
import { analyticsTickersDummyData, dummyResearch, dummyUser } from '../../../Utils/DummyData';

const AnalyticsTickerResearchViews = ({ changeRoute, datatita }) => {
    // state
    const [filteredData, setFilteredData] = useState([]);
    const [hasSearchTerm, setHasSearchTerm] = useState(false);
    const [filteredList, setFilteredList] = useState([])
    const [loading, setLoading] = useState(false);
    const [currentFilter, setCurrentFilter] = useState("");
    const [usersList, setUsersList] = useState([])

    // data
    const ticker = analyticsTickersDummyData.find(t => t.tickerId === +datatita.match.params.tickerId)
    const research = dummyResearch.find(r => r.researchId === +datatita.match.params.researchId)
    const watchedReserched = 0;// countReadersPerDoc([research], dummyUser)
    const usersWatching = getUsersWithRelevantResearch(dummyUser, watchedReserched)

    useEffect(() => {
        setUsersList(usersWatching)
    }, [JSON.stringify(usersWatching)])


    // data filters
    const filterOnclick = (value) => {
        return currentFilter === value ? setCurrentFilter("") : setCurrentFilter(value);
    }

    const filteredUsers = (arr, state, property, string) => {
        if (state === string) {
            return arr.filter(user => {
                return user.readDocs.some(r => r.researchId === research.researchId && r[property] === string);
            })
        }
    };

    useEffect(() => {
        if (usersList && usersList.length > 0) {
            let newList = usersList
            if (currentFilter === "portal") newList = filteredUsers(usersList, currentFilter, "viewedIn", "portal")
            if (currentFilter === "email") newList = filteredUsers(usersList, currentFilter, "viewedIn", "email")
            if (currentFilter === "pdf") newList = filteredUsers(usersList, currentFilter, "download", "pdf")
            if (currentFilter === "modal") newList = filteredUsers(usersList, currentFilter, "download", "modal")

            setFilteredList(newList)
        }
    }, [research, currentFilter, usersList])


    // data metrics 
    const pdf = 0;//countReadersPerDoc([research], usersList, "download", "pdf")[research.researchId.toString()]
    const modal = 0;//countReadersPerDoc([research], usersList, "download", "modal")[research.researchId.toString()]
    const email = 0;//countReadersPerDoc([research], usersList, "viewedIn", "email")[research.researchId.toString()]
    const portal = 0;//countReadersPerDoc([research], usersList, "viewedIn", "portal")[research.researchId.toString()]


    // table data
    const headers = [
        "Time/Date",
        "User",
        "Email",
        { text: "Download", className: "text-align-center" },
        { text: "Viewed In", className: "text-align-center" },
        { text: "Device", className: "text-align-center" },
    ]
    const colWidths = ["14%", "15%", "20%", "8%", "8%", "8%"];

    const dataMap = (data) => data && data.map((datum, idx) => {
        const viewingData = datum.readDocs.filter(d => d.researchId === research.researchId)
        const { download, viewedIn, device, viewingDate } = viewingData[0];

        return (
            <tr key={idx}>
                <td>{viewingDate}</td>
                <td>{datum.firstName + " " + datum.lastName}</td>
                <td>{datum.email}</td>
                <td className="text-align-center">
                    {download && download === "pdf" ? getIcon("downloads", "24px", download, "PDF Download") : download === "modal" ? getIcon("modalDownloads", "24px", download, "Modal Download") : "-"}
                </td>
                <td className="text-align-center">
                    {viewedIn && viewedIn === "email" ? getIcon("read", "24px", viewedIn, "Email") : viewedIn === "portal" ? getIcon("views", "24px", viewedIn, "Portal") : "-"}
                </td>
                <td className="text-align-center">
                    {device && device === "mobile" ? getIcon("mobile", "24px", device) : device === "desktop" ? getIcon("desktop", "24px", device) : "-"}
                </td>
            </tr>
        );
    });

    let listToRender = hasSearchTerm ? filteredData : filteredList


    return (
        <Page fixedBanner showScrollbar>
            {/* Modal */}
            <PageContentBanner divider gap="m">
                <ActionButtonsBanner handleBackOnClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/ticker/" + ticker.tickerId)} />
            </PageContentBanner>
            <PageContentBodyGrid showScrollbar>
                <PageGridItem colSpan="12">
                    <FlexWrapper gap="xl">
                        <FlexWrapper gap="xs" direction="column" align="flex-start">
                            <TickerSectorItem label={ticker.ric} image={ticker.logo} imageSize={64} country={ticker.country} filename={ticker.ric} />
                        </FlexWrapper>
                        <FlexWrapper gap="xs" direction="column" align="flex-start">
                            <p className="font-weight-semi-bold">{research.title}</p>
                            <p>{research.publishedDate + " " + research.author}</p>
                        </FlexWrapper>
                        <Divider vertical height={40} />
                        <AnalyticsMetricFilter
                            onClick={() => filterOnclick("email")}
                            active={currentFilter === "email"}
                            inactive={currentFilter !== "" && currentFilter !== "email"}
                            label="Email Views"
                            status="read"
                            icon={<Mail />}
                            count={email}
                            style={{ marginTop: 3 }}
                        />
                        <AnalyticsMetricFilter
                            onClick={() => filterOnclick("portal")}
                            active={currentFilter === "portal"}
                            inactive={currentFilter !== "" && currentFilter !== "portal"}
                            label="Portal Views"
                            icon={<Eye />}
                            count={portal}
                            style={{ marginTop: 3 }}
                        />
                        <AnalyticsMetricFilter
                            onClick={() => filterOnclick("pdf")}
                            active={currentFilter === "pdf"}
                            inactive={currentFilter !== "" && currentFilter !== "pdf"}
                            label="PDF Downloads"
                            icon={<PdfIcon noHover />}
                            count={pdf}
                            style={{ marginTop: 3 }}
                        />
                        <AnalyticsMetricFilter
                            onClick={() => filterOnclick("modal")}
                            active={currentFilter === "modal"}
                            inactive={currentFilter !== "" && currentFilter !== "modal"}
                            label="Model Downloads"
                            icon={<XlsIcon noHover />}
                            count={modal}
                            style={{ marginTop: 3 }}
                        />
                    </FlexWrapper>
                </PageGridItem>
                <PageGridDivider colSpan="12" />
                <PageGridItem colSpan="12">
                    <SearchTermFilter
                        size="small"
                        width="l"
                        placeholder={`Search users ${loading ? "" : "(" + usersList.length + ")"}`}
                        isClearable
                        dataSet={filteredList}
                        setFilteredData={setFilteredData}
                        path={["firstName", "lastName", "email"]}
                        hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
                    />
                </PageGridItem>
                <PageGridItem colSpan="12">
                    <Table
                        minWidth={1000}
                        colWidths={colWidths}
                        headers={headers}
                        dataMap={dataMap(listToRender)}
                        noResults={{ message: "No users found", subMessage: "Try adding a new user." }}
                        dataLoading={loading}
                    />
                </PageGridItem>
            </PageContentBodyGrid>
        </Page>
    );
}

export default withRouteChange(AnalyticsTickerResearchViews);
