import React from 'react';
import { CheckCircle, Search } from 'react-feather';
import Table from '../../../components/Table/Table';
import IconCounter from '../../../components/IconCounter';
import TickerSectorItem from '../../../components/TickerSectorItem';

const TopTickers = ({searched, watchlisted}) => {

	const colWidths = ["60%", "40%"];
	const data = [
		{ ticker: "MEL.NZ", searched: 442, watchlisted: 182, logo: "https://research-portal-test.3skye.com/icons/tickers/MEL.NZ.png"},
		{ ticker: "CEN.NZ", searched: 442, watchlisted: 182, logo: "https://research-portal-test.3skye.com/icons/tickers/CEN.NZ.png"},
		{ ticker: "AIA.NZ", searched: 442, watchlisted: 182, logo: "https://research-portal-test.3skye.com/icons/tickers/AIA.NZ.png"},
		{ ticker: "MCY.NZ", searched: 442, watchlisted: 182, logo: "https://research-portal-test.3skye.com/icons/tickers/MCY.NZ.png"},
		{ ticker: "MEL.NZ", searched: 442, watchlisted: 182, logo: "https://research-portal-test.3skye.com/icons/tickers/MEL.NZ.png"},
		{ ticker: "CEN.NZ", searched: 442, watchlisted: 182, logo: "https://research-portal-test.3skye.com/icons/tickers/CEN.NZ.png"},
		{ ticker: "AIA.NZ", searched: 442, watchlisted: 182, logo: "https://research-portal-test.3skye.com/icons/tickers/AIA.NZ.png"},
		{ ticker: "MCY.NZ", searched: 442, watchlisted: 182, logo: "https://research-portal-test.3skye.com/icons/tickers/MCY.NZ.png"},
	];

	const dataMap = () => data && data.map((company, idx) => {
		return (
			<tr key={idx} className="cursor-pointer">
				<td>
					<TickerSectorItem size="m" label={company.ticker} image={company.logo} />
				</td>
				<td className="table-action-icons transition-fade-in-slow">
					<IconCounter icon={searched ? <Search /> : <CheckCircle />} tooltip={searched ? "Searched" : "Watchlisted"} dataAvailable count={searched ? company.searched : company.watchlisted} />
				</td>
			</tr>
		);
	});

	return (
		<Table rowSize="s" colWidths={colWidths} dataMap={dataMap()} />
   );
}

export default TopTickers;