
import React, { useRef, useEffect } from 'react';
import { Lock, MoreVertical, Send, ChevronDown, ChevronUp } from 'react-feather';
import FlexWrapper from '../../../layouts/FlexWrapper';
import PopupMenu from '../../PopupMenu';
import Popup from '../../Popup';
import PopupMenuItem from '../../PopupMenuItem';
import "./VersionOption.scss";
import { useState } from 'react';
import Tooltip from '../../popups/Tooltip';
import Divider from '../../Divider';
import { useRemote, useOutsideAlerter } from '../../../Utils/Utils';

// Menu that appears when clicking the 3 vertical dots
const VersionMenu = ({ onClick, locked, published, restoreOnClick, unlockOnClick, lockOnClick, deleteOnClick, handleClickAway, setPopupVisible }) => {

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, () => { setPopupVisible(false); handleClickAway() });

	return (
		<div ref={wrapperRef}>
			<PopupMenu width={118} padding="s" onClick={onClick}>
				<PopupMenuItem label="Restore" onClick={restoreOnClick} />
				{locked || published ? <PopupMenuItem disabled={published} label="Unlock" onClick={unlockOnClick} /> : <PopupMenuItem label="Lock" onClick={lockOnClick} />}
				<PopupMenuItem disabled={locked || published} label="Delete" onClick={deleteOnClick} />
			</PopupMenu>
		</div>
	);
}

const VersionOption = ({ id, current, selected, locked, author, onClick, date, published, size, refresh, data, versionsToLoad, expand, expanded, nestedVersion }) => {
	const [menuShowing, setMenuShowing] = useState(false);

	const displaySize = !size ? "" : size < 1024 ? (size + ' B') : size < 1024*1024 ? ((size / 1024).toFixed(0) + " kB") : (size / (1024*1024)).toFixed(1) + " MB";

	const displayAuthor = author && author.length > 20 ? author.substring(0, 15) + "..." : author;

	const remote = useRemote();

	const restoreVersion = () => {
		data.loadLocalData({}, () => {refresh()})
	}

	return (<>
		<FlexWrapper gap="xs" align="flex-start" justify="space-between" className={`version-option${current ? " current-version" : ""}${selected || expanded ? " selected-version" : ""}${nestedVersion ? " nested-version" : ""}`} fullWidth onClick={() => onClick}>
			{/* gap for version content located in VersionOption.scss */}
			<FlexWrapper className="version-content" direction="column" align="flex-start">
				{/* Current flag */}
				{current && <div className="current-flag colour-text-primary font-weight-semi-bold font-size-body-xs">Current Version</div>}
				{/* Date/name */}
				<FlexWrapper gap="xs" direction="column" align="flex-start" fullWidth>
					<div className="version-date-name font-weight-medium">{date}</div>
					{/* {renamed && <div className="version-date font-size-body-xs">15th June, 12:33 PM</div>} */}
				</FlexWrapper>
				{/* File size/author */}
				<FlexWrapper gap="s" className="version-file-size-author font-size-body-s">
					{author && (
						<>
							<div className="version-file-size">{displayAuthor}</div>
							<Divider dark vertical height={12} />
						</>
					)}
					<div>{displaySize}</div>
					{published && (
						<>
							<Divider dark vertical height={12} />
							<div className="version-published"><Tooltip label="Published"><Send className="colour-text-positive-dark" size={14} /></Tooltip></div>
						</>
					)}
					{(locked || published) && (
						<>
							<Divider dark vertical height={12} />
							<div className="version-lock"><Tooltip label="Locked"><Lock className="colour-text-accent-1" size={14} /></Tooltip></div>
						</>
					)}
				</FlexWrapper>
			</FlexWrapper>
			{/* Menu */}
			<div className="version-right-content">
				{!current && <div className={`version-menu${menuShowing ? " menu-showing" : ""}`}>
					<Popup
						placement="left-start"
						onClick={() => setMenuShowing(!menuShowing)}
						triggerElement={<MoreVertical className="version-menu-trigger" size={16} />}
						popupElement={<VersionMenu
							locked={locked}
							published={published}
							onClick={() => setMenuShowing(!menuShowing)}
							restoreOnClick={() => {data.restoreVersion(id).then(restoreVersion)}}
							unlockOnClick={() => {data.lockVersion(id, false, versionsToLoad).then(refresh)}}
							lockOnClick={() => {data.lockVersion(id, true, versionsToLoad).then(refresh)}}
							deleteOnClick={() => {data.deleteVersion(id, versionsToLoad).then(refresh)}}
							handleClickAway={() => setMenuShowing(false)}
							/>}
					/>
				</div>}
				{expand && expand !== undefined && <ChevronDown className={`version-expand-icon colour-text-neutral-dark-50${expanded ? ' expanded' : ''}`} size={14} onClick={() => expand()} />}
			</div>
		</FlexWrapper>
		</>
   );
}

export default VersionOption;
