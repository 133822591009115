import React, { useState, useEffect } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import useWindowDimensions from '../../../Utils/Utils';
import { useParams, useHistory } from 'react-router-dom';
import SystemUserDetails from './SystemUserDetails';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import { useRemote } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import withRouteChange from "@threeskye/route-change";

const blankUser = {
	firstName: "",
	lastName: "",
	preferredName: "",
	role: "",
	email: "",
	phone: { areaCode: "", ddi: "", number: "" },
	mobile: "",
}

const SystemUserPage = ({changeRoute}) => {
	const [editMode, setEditMode] = useState(false);
	const [authorsMode, setAuthorsMode] = useState(false);
	const [user, setUser] = useState(null)
	const [showModal, setShowModal] = useState(false);
	const { width } = useWindowDimensions();
	let history = useHistory();
	const remote = useRemote()
	const { userId } = useParams()
	const params = new URLSearchParams(window.location.search);
	const authorsQuery = params.get('authors');

	useEffect(() => {
		if (authorsQuery) {
			setAuthorsMode(true)
		}
		remote.get(`/crm/users/${userId}/`).then(setUser)
	}, [userId] )

	return (
		<Page fixedBanner>
			{/* Page banner with back button */}
			<PageContentBanner divider>
				<ActionButtonsBanner
					handleBackOnClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + "/user-management" + (authorsMode ? "?authors=true" : ''))}
				/>
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				{/* grid container for the user details section */}
				<PageGridItem container col="1 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
						{/* General Details */}
						<SystemUserDetails user={user} editMode={editMode} />
					</PageContentBodyGrid>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}
export default withRouteChange(SystemUserPage);