import React from 'react';
import './SideBarWrapper.scss';

const SideBarWrapper = (props) => {
	const { children, borderLeft, borderRight, left, right, className } = props;
	return (
		<div className={`side-bar-wrapper colour-bg-neutral-light${className ? " " + className : ""}${left ? " sidebar-left" : right ? " sidebar-right" : ""}${borderLeft ? " border-default-left" : borderRight ? " border-default-right" : ""}`}>
			{children}
		</div>
	)
};

export const SideBarIconGroup = (props) => {
	const { children, divider, className } = props;
	return (
		<div className={`side-bar-inner-icons${divider ? " side-bar-icon-group-divider" : ""}${className ? " " + className : ""}`}>
			{children}
		</div>
	)
};

export default SideBarWrapper;