import React, { Component } from 'react';
import UnorderedList from '../Lists/UnorderedList';
import "./ModalListWrapper.scss";
class ModalListWrapper extends Component {
	render() { 
		const { header, children } = this.props;
		return ( 
			<div className="modal-list">
				<h4>{header}</h4>
				<UnorderedList>
					{children}
				</UnorderedList>
			</div>
		 );
	}
}
 
export default ModalListWrapper;