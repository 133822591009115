import React, { useState, useEffect } from 'react';
import { Image, TrendingUp } from 'react-feather';
import TickerSectorImage from '../../../components/TickerSectorImage';
import { formatDate, useRemote } from '../../../Utils/Utils';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import SelectDetailField from '../../Shared/DetailSection/SelectDetailField';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';
import LoadingIcon from '../../../components/LoadingIcon';
import FlexWrapper from '../../FlexWrapper';
import Button from '../../../components/Buttons/Button';
import Tooltip from '../../../components/popups/Tooltip';
import ImageEditModal from '../../../components/Modals/ImageEditModal'

const TickerPageDetails = ({ editMode, ticker, imageEdit, setOnSaveListener, setOnCancelListener }) => {
	const [formattedDate, setFormattedDate] = useState("")
	const [showImageEditModal, setShowImageEditModal] = useState(false)
	const [imageUpload, setImageUpload] = useState(null)
	const [stagedImage, setStagedImage] = useState(null)
	const [stagedImageURL, setStagedImageURL] = useState(null)

	useEffect(() => {
		if(stagedImage) {
			let url = URL.createObjectURL(stagedImage[0])
			setStagedImageURL(url)
		}
	}, [stagedImage])

	useEffect(() => {
		if (ticker && ticker.added) {
			let splitDate = ticker.dateAdded.split("-")
			let newDate = formatDate(splitDate[0], splitDate[1], splitDate[2])
			setFormattedDate(newDate)
		}
	}, [ticker])

	const remote = useRemote();

	const [refresh, setRefresh] = useState(1);

	const onDrop = (files) => {
		let file = files[0];

		if (file.size > 5 * 1024 * 1024) {
			toastDanger("File too large - please upload a 200x200 .png file");
			return;
		}

		if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/webp") {
			toastDanger("Invalid file type - please upload a .png, .jpeg, or .webp file");
			console.log(file, "invalid type");
			return;
		}

		let xhr = new XMLHttpRequest();
		xhr.open('post', `/api/crm/images/ticker/${ticker.id}`, true);
		xhr.addEventListener('load', e => {
			if (xhr.status >= 200 && xhr.status < 300) {
				onUploadComplete(e);
			} else {
				console.log(xhr);
			}
		});
		
		xhr.upload.addEventListener('progress', e => {
			if (e.lengthComputable) {
				let complete = (e.loaded / e.total * 100 | 0);
				if (complete === 100) {
					//TODO onscreen reporting
					console.log("Processing ...");
				} else {
					console.log("Finished");
				}
			}
		});

		xhr.setRequestHeader("Content-Type", file.type);
		xhr.setRequestHeader("Content-Disposition", "attachment; filename=upload.png");
		xhr.setRequestHeader("X-3Skye-Session", remote.createTokenHeader().headers["X-3Skye-Session"]);
		xhr.send(file);
	}

	const onUploadComplete = (e) => {
		console.log("On upload complete has e", e);
		const resp = JSON.parse(e.target.response);
		if (!resp.success) {
			toastDanger(resp.message);
		} else {
			toastSuccess("Successfully uploaded");
			setRefresh(refresh + 1);
		}
	}

	const onUploadClick = (e) => {
		const fileInput = document.createElement('input');
		fileInput.type = 'file';
		fileInput.multiple = true;
		fileInput.onchange = (event) => { setImageUpload(event.target.files); setShowImageEditModal(true) };
		fileInput.click();
	};

	if (!ticker || !ticker.id) {
		return <LoadingIcon centered={true} />
	}

	return (
		<>
			{showImageEditModal && (
				<ImageEditModal
					handleClose={() => setShowImageEditModal(false)}
					cancelButton={{ onClick: () => {setShowImageEditModal(false)} }}
					// confirmButton={{ onClick: (image) => setShowImageEditModal(false, onDrop(image)) }}
					initialImg={imageUpload}
					clearInitialImg={() => setImageUpload(null)}
					onConfirm={(image) => { 
						setOnSaveListener(() => {onDrop(image)});
						setOnCancelListener(() => {setStagedImage(null); setStagedImageURL(null)})
						setStagedImage(image); 
						setShowImageEditModal(false) 
					}}
				/>
			)}
			<PageGridItem col="1 / span 6" justifyContent="between">
				<DetailSectionHeader header="Ticker Details" icon={<TrendingUp />} />
				<FlexWrapper gap="m">
					<TickerSectorImage hoverIcon={<Image />} removeOverlay={!editMode} hoverUpload={editMode} imageSize={50} image={stagedImageURL ? stagedImageURL : `/api/public/images/ticker/${ticker.id}.png?refresh=${refresh}`} onClick={editMode ? onUploadClick : () => null} />
				</FlexWrapper>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField bold editMode={false} autoFocus label="Code" value={ticker.ric} />
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField bold editMode={false} label="Name" value={ticker.name} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField editMode={false} label="Headquarters Location" value={ticker.address + ", " + ticker.country} />
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField editMode={false} label="Website" value={ticker.website} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<SelectDetailField editMode={false} label="Sector" value={ticker.gicSector} />
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<SelectDetailField editMode={false} label="Industry" value={ticker.gicIndustry} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField label="Date Created" value={formattedDate} />
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField label="Last Published" value={ticker.lastPublished} />
			</PageGridItem>

		</>
	);
}

export default TickerPageDetails;