import React, { useState, useEffect } from 'react';
import PageContentToggle from '../../../components/Menus/PageContentToggle'
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import { getIcon, countReadersPerDoc, getUsersWithRelevantResearch, countDocsPerViewer, sumValues, useStorage, useRemote } from '../../../Utils/Utils';
import Table from '../../../components/Table/Table';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { Eye, Mail, BookOpen, User } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';
import FlexWrapper from '../../FlexWrapper';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import Divider from '../../../components/Divider';
import TickerSectorItem from '../../../components/TickerSectorItem';
import AnalyticsMetricFilter from '../../../components/Analytics/AnalyticsMetricFilter';
import { analyticsTickersDummyData, dummyResearch, dummyUser } from '../../../Utils/DummyData';
import { format, parseISO } from 'date-fns';

const AnalyticsTickerPage = ({ changeRoute, datatita }) => {
    // states
    const [currentView, setCurrentView] = useState("research");
    const [filteredData, setFilteredData] = useState([]);
    const [hasSearchTerm, setHasSearchTerm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [relevantResearch, setRelevantResearch] = useState([])
    const [usersList, setUsersList] = useState([])

	const [tickers, setTickers] = useState([]);
	const [ticker, setTicker] = useState(null);
	const [published, setPublished] = useState([]);

	const storage = useStorage();
	const remote = useRemote();

	useEffect(() => {
		storage.getOrFetch("/crm/tickers").then(setTickers)
	}, [])

	useEffect(() => {
		const foundTicker = tickers.find(t => t.id === +datatita.match.params.tickerId);
		if (foundTicker) {
			setTicker(foundTicker);
			remote.get("/documents/list?num=500&offset=0&ticker="+foundTicker.ric).then(setPublished);
		}
	}, [tickers])

    const watching = sumValues(countReadersPerDoc(relevantResearch, dummyUser))
    const research = relevantResearch.length
    const pdf = published.reduce((a, b) => a + b.downloads, 0);
    const modal = published.reduce((a, b) => a + b.modelDownloads, 0);
    const email = published.reduce((a, b) => a + b.opened, 0);
    const portal = published.reduce((a, b) => a + b.views, 0);


    // research table data 
    const researchHeaders = ["Time/Date", "Title", "Author", getIcon("read", "16px"), getIcon("views", "16px"), getIcon("downloads", "16px"), getIcon("modalDownloads", "16px")]
    const researchColWidths = ["160px", "30%", "19%", "70px", "70px", "70px", "70px"]

    const researchDataMap = (data) => data && data.map((datum, idx) => {
        const { title, author } = datum
        const pdf = datum.downloads;
        const modal = datum.modelDownloads;
        const email = datum.opened;
        const portal = datum.views;
		const publishedDate = format(parseISO(datum.date), 'dd MMM yyyy');


        return (
            <tr key={idx} className="cursor-pointer" onClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/ticker/" + ticker.tickerId + "/research/" + datum.researchId)}>
                <td>{publishedDate}</td>
                <td>{title}</td>
                <td>{author}</td>
                <td><div className="icon-counter-count">{email}</div></td>
                <td><div className="icon-counter-count">{portal}</div></td>
                <td><div className="icon-counter-count">{pdf}</div></td>
                <td><div className="icon-counter-count">{modal}</div></td>
            </tr>
        );
    });

    // users watching table data
    const watchingHeaders = ["Name", "Email", getIcon("read", "16px"), getIcon("views", "16px"), getIcon("downloads", "16px"), getIcon("modalDownloads", "16px")]
    const watchingColWidths = ["35%", "280px", "70px", "70px", "70px", "70px"]

    const usersDataMap = (data) => data && data.map((datum, idx) => {
        const { firstName, lastName, email } = datum
//        const reserchIdArry = Object.keys(watchedReserched)
//        const thisTickerResearch = datum.readDocs.filter(r => reserchIdArry.includes(r.researchId.toString()))

        return (
            <tr key={idx}>
                <td>{firstName} {lastName}</td>
                <td>{email}</td>
                <td><div className="icon-counter-count">1{/* countDocsPerViewer(thisTickerResearch, "viewedIn", "email") */}</div></td>
                <td><div className="icon-counter-count">2{/* countDocsPerViewer(thisTickerResearch, "viewedIn", "portal") */}</div></td>
                <td><div className="icon-counter-count">3{/* {countDocsPerViewer(thisTickerResearch, "download", "pdf")} */}</div></td>
                <td><div className="icon-counter-count">4{/* {countDocsPerViewer(thisTickerResearch, "download", "modal")} */}</div></td>
            </tr>
        );
    });

	if (!ticker) {
		return <p>No ticker</p>
	}

    return (
        <Page fixedBanner showScrollbar>
            {/* Modal */}
            <PageContentBanner divider gap="m">
                <ActionButtonsBanner handleBackOnClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers")} />
            </PageContentBanner>
            <PageContentBodyGrid showScrollbar>
                <PageGridItem colSpan="12" style={{ marginTop: -10 }}>
                    <FlexWrapper gap="xl">
                        <FlexWrapper gap="xs" direction="column" align="flex-start">
                            <TickerSectorItem label={ticker.ric} image={ticker.logo} imageSize={64} country={ticker.country} filename={ticker.ric}  />
                        </FlexWrapper>
                        <Divider vertical height={40} />
                        <AnalyticsMetricFilter
                            label="Research"
                            status="research"
                            icon={<BookOpen color='#0D8EFD' />}
                            count={research}
                            style={{ marginTop: 3 }}
                            pointer="default"
                        />
                        <AnalyticsMetricFilter
                            label="Watching"
                            status="watching"
                            icon={<User color='#00805E' />}
                            count={watching}
                            style={{ marginTop: 3 }}
                            pointer="default"
                        />
                        <AnalyticsMetricFilter
                            label="Email Views"
                            status="read"
                            icon={<Mail />}
                            count={email}
                            style={{ marginTop: 3 }}
                            pointer="default"
                        />
                        <AnalyticsMetricFilter
                            label="Portal Views"
                            icon={<Eye />}
                            count={portal}
                            style={{ marginTop: 3 }}
                            pointer="default"
                        />
                        <AnalyticsMetricFilter
                            label="PDF Downloads"
                            icon={<PdfIcon noHover />}
                            count={pdf}
                            style={{ marginTop: 3 }}
                            pointer="default"
                        />
                        <AnalyticsMetricFilter
                            label="Model Downloads"
                            icon={<XlsIcon noHover />}
                            count={modal}
                            style={{ marginTop: 3 }}
                            pointer="default"
                        />
                    </FlexWrapper>
                </PageGridItem>
                <PageGridDivider colSpan="12" />
                <PageGridItem colSpan="12">
                    <FlexWrapper gap="m">
                        <PageContentToggle labels={[
                            { label: "Research", active: currentView === "research", onClick: () => setCurrentView("research") },
                            { label: "Watching", active: currentView === "watching", onClick: () => setCurrentView("watching") },
                        ]} />
                        <Divider vertical height={24} />
                        <SearchTermFilter
                            size="small"
                            width="l"
                            placeholder={currentView === "research" ? `Search research ${loading ? "" : "(" + published.length + ")"}` : `Search research ${loading ? "" : "(" + usersList.length + ")"}`}
                            isClearable
                            dataSet={currentView === "research" ? published : usersList}
                            setFilteredData={setFilteredData}
                            path={currentView === "research" ? ["author", "title"] : ["firstName", "lastName", "email"]}
                            hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
                        />
                    </FlexWrapper>
                </PageGridItem>
                <PageGridItem colSpan="12">
                    <Table
                        minWidth={1000}
                        colWidths={currentView === "research" ? researchColWidths : watchingColWidths}
                        headers={currentView === "research" ? researchHeaders : watchingHeaders}
                        dataMap={currentView === "research" ? researchDataMap(hasSearchTerm ? filteredData : published) : usersDataMap(hasSearchTerm ? filteredData : usersList)}
                        noResults={{ message: "No results found" }}
                        dataLoading={loading}
                    />
                </PageGridItem>
            </PageContentBodyGrid>
        </Page>
    );
}

export default withRouteChange(AnalyticsTickerPage);