import React from 'react';
import Modal from './Modal';

const ConfirmActionModal = ({ action, handleClose, confirmButton, cancelButton, header }) => {
	return (
		<Modal
			header={header ? header : "Confirm"}
			handleClose={handleClose}
			cancelButton={{ ...cancelButton }}
			confirmButton={{ label: "Confirm", ...confirmButton }}
		>
			Are you sure you want to {action}?
		</Modal>
	);
}
export default ConfirmActionModal;