import React from 'react';
import { User } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import SelectDetailField from '../../Shared/DetailSection/SelectDetailField';
import PageGridItem from '../../Shared/Page/PageGridItem';

const SystemUserDetails = ({ editMode, user }) => {
	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="General Details" icon={<User />} />
			</PageGridItem>
			<PageGridItem col="1 / span 6">
				<DetailField bold editMode={editMode} autoFocus label="First Name" value={user && user.name && user.name} />
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				{/* <DetailField bold editMode={editMode} autoFocus label="Last Name" value={user && user.lastName && user.lastName} /> */}
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField editMode={editMode} autoFocus label="Email" value={user && user.email && user.email} />
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<SelectDetailField editMode={editMode} autoFocus label="Title" value={user && user.title && user.title} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField editMode={editMode} autoFocus label="Phone" value={user && user.phone && user.phone.number && user.phone.number} />
			</PageGridItem>
		</>
	);
}

export default SystemUserDetails;