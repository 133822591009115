import AgedCareRetirement2 from './Aged Care & Retirement 2.png';
import AgedCareRetirement from './Aged Care & Retirement.png';
import Banking from './Banking.png';
import BuildingConstruction from './Building & Construction.png';
import ConsumerDiscretionary2 from './Consumer Discretionary 2.png';
import ConsumerDiscretionary from './Consumer Discretionary.png';
import ConsumerStaples from './Consumer Staples.png';
import Dairy2 from './Dairy 2.png';
import Dairy3 from './Dairy 3.png';
import Dairy4 from './Dairy 4.png';
import Dairy from './Dairy.png';
import Energy2 from './Energy 2.png';
import Energy3 from './Energy 3.png';
import Energy from './Energy.png';
import EquityResearch from './Equity Research.png';
import FoodAgriculture2 from './Food & Agriculture 2.png';
import FoodAgriculture3 from './Food & Agriculture 3.png';
import FoodAgriculture from './Food & Agriculture.png';
import InformationTechnology from './Information Technology.png';
import Infrastructure from './Infrastructure.png';
import Internet2 from './Internet 2.png';
import Internet from './Internet.png';
import MetasMining2 from './Meta\'s & Mining 2.png';
import MetasMining3 from './Meta\'s & Mining 3.png';
import MetalsMining from './Metals & Mining.png';
import OilGas2 from './Oil & Gas 2.png';
import OilGas from './Oil & Gas.png';
import RealEstate from './Real Estate.png';
import Research2 from './Research 2.png';
import Research from './Research.png';
import Telecommunications from './Telecommunications.png';
import Transport2 from './Transport 2.png';
import Transport from './Transport.png';
import Utilities2 from './Utilities 2.png';
import Utilities from './Utilities.png';

const filenames = [
	'aged-care-and-retirement-2',
	'aged-care-and-retirement',
	'banking',
	'building-and-construction',
	'consumer-discretionary',
	'consumer-discretionary-2',
	'dairy',
	'dairy-2',
	'dairy-3',
	'dairy-4',
	'energy',
	'energy-2',
	'energy-3',
	'equity-research',
	'food-and-agriculture',
	'food-and-agriculture-2',
	'food-and-agriculture-3',
	'information-technology',
	'infrastructure',
	'internet',
	'internet-2',
	'metals-and-mining',
	'metals-and-mining-2',
	'metals-and-mining-3',
	'oil-and-gas',
	'oil-and-gas-2',
	'real-estate',
	'research',
	'research-2',
	'telecommunications',
	'transport',
	'transport-2',
	'utilities',
	'utilities-2'
];

const urls = filenames.map(f => `/api/public/images/${f}.png`);

export default urls;

const index = [
  AgedCareRetirement2,
  AgedCareRetirement,
  Banking,
  BuildingConstruction,
  ConsumerDiscretionary2,
  ConsumerDiscretionary,
  ConsumerStaples,
  Dairy2,
  Dairy3,
  Dairy4,
  Dairy,
  Energy2,
  Energy3,
  Energy,
  EquityResearch,
  FoodAgriculture2,
  FoodAgriculture3,
  FoodAgriculture,
  InformationTechnology,
  Infrastructure,
  Internet2,
  Internet,
  MetasMining2,
  MetasMining3,
  MetalsMining,
  OilGas2,
  OilGas,
  RealEstate,
  Research2,
  Research,
  Telecommunications,
  Transport2,
  Transport,
  Utilities2,
  Utilities
];


