import React from 'react';

import './NoAccess.scss';

class NoAccess extends React.PureComponent {

	render() {
		return <div className="no-access">Sorry you do not have access to 3Skye Research</div>
	}
}

export default NoAccess;