import React, { useState } from 'react';
import "./ExpandableTableRow.scss";

const ExpandableTableRow = ({ children, className, expandContent }) => {
	const [expandActive, setExpandActive] = useState(false);

	let classes = "expandable-table-row cursor-pointer";
	let expandRowClasses = "expand-content-row";
	
	if (className) {
		classes += " " + className;
	}
	if (expandActive) {
		classes += " expand-active";
		expandRowClasses += " expand-active";
	}

	return (
		<>
			<tr className={classes} onClick={() => setExpandActive(!expandActive)}>
				{children}
			</tr>
			<tr className={expandRowClasses}>
				<td colSpan="100%" className="expand-inner">{expandContent}</td>
			</tr>
		</>
   );
}

export default ExpandableTableRow;