import React from 'react';
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import "./RechartsOverrides.scss";

const UserChart = (props) => {
	const data = [
		{
			name: "Jan",
			Users: 120,
		},
		{
			name: "Feb",
			Users: 167,
		},
		{
			name: "Mar",
			Users: 170,
		},
		{
			name: "Apr",
			Users: 190,
		},
		{
			name: "May",
			Users: 230,
		},
		{
			name: "Jun",
			Users: 258,
		},
	];

	return (
		<ResponsiveContainer width="100%" height={264}>
			<AreaChart data={data} margin={{ top: 8, right: 0, left: -20, bottom: 0 }}>
				<defs>
					<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#0E8EFD" stopOpacity={0.7} />
						<stop offset="70%" stopColor="#0E8EFD" stopOpacity={0} />
					</linearGradient>
				</defs>
				<XAxis tickLine={false} axisLine={false} dataKey="name" />
				<YAxis axisLine={false} tickLine={false}/>
				<CartesianGrid vertical={false} strokeDasharray="3 3" />
				<Tooltip />
				<Area type="monotone" dataKey="Users" strokeWidth={2} stroke="#0E8EFD" fillOpacity={1} fill="url(#colorUv)" />
			</AreaChart>
		</ResponsiveContainer>
	);
}

export default UserChart;