import React from "react";
import withRouteChange from '@threeskye/route-change';
import "./SidebarExtensionLink.scss";

const SidebarExtensionLink = (props) => {
	const { link, children, active, changeRoute, onClick } = props;
	return (
		<div onClick={onClick ? onClick : () => changeRoute(link)} className={`sidebar-extension-link${active ? " sidebar-extension-link-active" : ""}`}>
			{children}
		</div>
	);
};

export default withRouteChange(SidebarExtensionLink);