import React from 'react';
import DropDown from '../../../components/DropDown';

const DateRangeToggle = ({value}) => {
	return (
		<DropDown fixed value={value} transparent clickable>
			<li>6 Months</li>
			<li>12 Months</li>
		</DropDown>
	);
}

export default DateRangeToggle;