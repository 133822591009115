import React from 'react';
import AnalyticsCounter from '../../../components/Analytics/AnalyticsCounter';
import FlexWrapper from '../../FlexWrapper';

const TotalMetrics = (props) => {
	return (
		<FlexWrapper fullWidth gap="xxl">
			<AnalyticsCounter label="Total Users" count="260" />
			<AnalyticsCounter label="Active Users" count="18" />
			<AnalyticsCounter label="Research Published" count="415" />
			<AnalyticsCounter label="Research Views" count="12,396" />
		</FlexWrapper>
   );
}

export default TotalMetrics;