import React from 'react';
import FlexWrapper from '../../FlexWrapper';
import DateRangeToggle from './DateRangeToggle';

const AnalyticsHeader = ({children, dateRanges}) => {
	return (
		<FlexWrapper className="analytics-header mb-l" fullWidth justify="space-between">
			<p className="font-weight-semi-bold">{children}</p>
			{dateRanges && <DateRangeToggle value="6 Months" />}
		</FlexWrapper>
   );
}

export default AnalyticsHeader;