import React from 'react';
import { useState } from 'react';
import AnalyticsCounter from '../../../components/Analytics/AnalyticsCounter';
import Tooltip from '../../../components/popups/Tooltip';
import FlexWrapper from '../../FlexWrapper';
import MapChart from '../Charts/MapChart';
import PercentageBars from '../Charts/PercentageBars';
import AnalyticsHeader from './AnalyticsHeader';

const MapMetrics = () => {
	const [content, setContent] = useState("");
	
	const data = [
		{name: "New Zealand", value: 117},
		{name: "New Zealand", value: 53},
		{name: "New Zealand", value: 22},
		{name: "New Zealand", value: 10},
	];
	return (
		<>
			<AnalyticsHeader dateRanges>Readers by Country</AnalyticsHeader>
			<FlexWrapper gap="xxl" align="flex-start" justify="space-between">
				{/* Map Here */}
				<MapChart setTooltipContent={setContent} />
				{/* Bars */}
				<div style={{ width: "100%", maxWidth: 350 }}>
					<PercentageBars data={data} />
				</div>
			</FlexWrapper>
			{content}
		</>
   );
}

export default MapMetrics;