import React from 'react';
import { ChevronDown } from 'react-feather';
import DropDown from '../../../components/DropDown';
import Popup from '../../../components/Popup';
import PopupMenu from '../../../components/PopupMenu';
import PopupMenuItem from '../../../components/PopupMenuItem';
import FlexWrapper from '../../FlexWrapper';

const AnalyticsToggleHeader = ({headers, value, setValue}) => {
	return (
		<DropDown fixed value={<p className="font-weight-semi-bold">{value}</p>} transparent clickable>
			<li>Research Views</li>
			<li>Analyst Views</li>
		</DropDown>
	);
}

export default AnalyticsToggleHeader;