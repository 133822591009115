import React, { useEffect, useState } from 'react'
import SidebarExtensionWrapper from '../components/SideBar/SideBarExtension/SideBarExtensionWrapper';
import SideBarLeft from '../components/SideBar/SideBarLeft';
import TopBar from '../components/TopBar/TopBar';
import { withThreeSkyeGlobal } from '@threeskye/global';
import { withRouter, useLocation } from "react-router";
import "./MenuWrapper.scss";
// import SideBarRight from '../components/SideBar/SideBarRight';
// import CommentsSidebarExtension from '../components/SideBar/SideBarExtension/CommentsSidebarExtension';


const MenuWrapper = (props) => {
	let [sideBarExtensionLeft, setSideBarExtensionLeft] = useState(true)
	let [sideBarRight, setSideBarRight] = useState(false)
	let [sideBarExtensionRight, setSideBarExtensionRight] = useState(false)
	let [alertBannerActive, setAlertBannerActive] = useState(false)
	let [sidebarExtLeftLinks, setSidebarExtLeftLinks] = useState([])

	const [topMessage, setTopMessage] = useState(null);

	const location = useLocation()
	let pathArray = location.pathname.split("/")

	const {topComponent} = props;

	useEffect(() => {
		setTopMessage(topComponent);
	}, [topComponent])

	useEffect(() => {
		props.storage.watch("research-master-grid", updateGridClasses)

		return (
			props.storage.unwatch("research-master-grid")
		)
	}, [])

	const updateGridClasses = (gridClass) => {
		switch (gridClass) {
			case "sideBarExtLeftActive":
				setSideBarExtensionLeft(true)
				break;
			case "sideBarExtLeftInactive":
				setSideBarExtensionLeft(false)
				break;
			case "SideBarRight":
				setSideBarRight(!sideBarRight)
				break;
			case "alertBannerActive":
				setAlertBannerActive(true)
				break;
			case "alertBannerInactive":
				setAlertBannerActive(false)
				break;
			case "sideBarExtRightActive":
				setSideBarExtensionRight(true)
				break;
			case "sideBarExtRightInactive":
				setSideBarExtensionRight(false)
				break;
			default:
				break;
		}
	}

	useEffect(() => {
		const path = props.location.pathname

		if (path === "/") {
			setSidebarExtLeftLinks([])
			setSideBarExtensionLeft(false)
			setSideBarRight(false)
			setSideBarExtensionRight(false)
			setAlertBannerActive(false)
			setTopMessage(null);
		}
		else if (path.includes("/editor")) {
			setSidebarExtLeftLinks([])
			setSideBarExtensionLeft(true)
			setSideBarRight(true)
			setSideBarExtensionRight(false)
			setAlertBannerActive(false)
		}
		else if (path.includes("/admin")) {
			setSidebarExtLeftLinks([{ name: "Tickers", path: "/admin/tickers" }, { name: "Model Portfolios", path: "/admin/model-portfolio" }, { name: "Sectors", path: "/admin/sectors" }, { name: "Disclaimer", path: "/admin/disclaimers" }, { name: "User Management", path: "/admin/user-management" },  { name: "Company Management", path: "/admin/company-management" }, { name: "Roles and Permissions", path: "/admin/role-permissions" }, { name: "Templates", path: "/admin/templates" }])
			setSideBarExtensionLeft(true)
			setSideBarRight(false)
			setSideBarExtensionRight(false)
			setAlertBannerActive(false)
			setTopMessage(null);
		}
		else if (path.includes("/analytics")) {
			setSidebarExtLeftLinks([{ name: "Tickers", path: "/analytics/tickers" }, { name: "Users", path: "/analytics/users" }, { name: "Companies", path: "/analytics/companies" }])
			setSideBarExtensionLeft(true)
			setSideBarRight(false)
			setSideBarExtensionRight(false)
			setAlertBannerActive(false)
			setTopMessage(null);
		}
		else if (path.includes("/crm")) {
			setSidebarExtLeftLinks([{ name: "Users", path: "/crm/users" }, { name: "Companies", path: "/crm/companies" }, { name: "Distribution Lists", path: "/crm/distribution" }, { name: "Suppressed Emails", path: "/crm/suppressions" }])
			setSideBarExtensionLeft(true)
			setSideBarRight(false)
			setSideBarExtensionRight(false)
			setAlertBannerActive(false)
			setTopMessage(null);
		}
		else if (path.includes("/settings") && !path.includes("/publication")) {
			setSidebarExtLeftLinks([{ name: "Profile", path: "/settings/profile" }, { name: "Settings", path: "/settings/general" }])
			setSideBarExtensionLeft(true)
			setSideBarRight(false)
			setSideBarExtensionRight(false)
			setAlertBannerActive(false)
			setTopMessage(null);
		}
		else if (path.includes("/publication")) {
			setSidebarExtLeftLinks([{ name: "Note View", path: `/publication/${pathArray[2]}` }, {name: "Note Settings", path: `/publication/${pathArray[2]}/settings` }])
			setSideBarExtensionLeft(true)
			setSideBarRight(false)
			setSideBarExtensionRight(false)
			setAlertBannerActive(false)
			setTopMessage(null);
		}
		else {
			setSidebarExtLeftLinks([])
			setSideBarExtensionLeft(false)
			setSideBarRight(false)
			setSideBarExtensionRight(false)
			setAlertBannerActive(false)
			setTopMessage(null);
		}
	}, [props.location.pathname])

	return (
		<div id="master-grid" className={`${sideBarExtensionLeft ? " grid-sidebar-ext-left-active " : ""}${sideBarRight ? " grid-sidebar-right-active " : ""}${sideBarExtensionRight ? " grid-sidebar-ext-right-active " : ""}${alertBannerActive ? " grid-alert-banner-active " : ""}`}>
			<TopBar topMessage={topMessage} />
			<SideBarLeft />
			{sidebarExtLeftLinks.length > 0 && <SidebarExtensionWrapper links={sidebarExtLeftLinks} />}
			{props.children}
		</div>
	)
};

export default withThreeSkyeGlobal(withRouter(MenuWrapper));