import React from 'react';
import { Plus } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Table from '../../../components/Table/Table';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import ExpandableTableRow from '../../../components/Table/ExpandableTableRow';
import Checkbox from '../../../components/Checkbox';
import CheckboxGroup, { CheckboxGroupWrapper } from '../../../components/Input/CheckboxGroup';
import TableActionIconGroup from '../../../components/Table/TableActionIconGroup';
import { toast } from 'react-toastify';

const RolePermissionsList = (props) => {
	// Data for table
	const data = [
		{ role: "Senior Research Analyst", users: "7", permissions: "All" },
		{ role: "Research Analyst", users: "4", permissions: "All" },
		{ role: "Associate Research Analyst", users: "2", permissions: "3" },
		{ role: "Research Associate", users: "1", permissions: "3" },
	];

	// Table widths and headers
	const colWidths = [40 + "%", 20 + "%", 20 + "%", 20 + "%"];
	const headers = ["Role", "Users", "Permissions", ""];

	const dummyEditorPermissions = [
		{ label: "Edit", checked: true, onClick: null},
		{ label: "Request Peer Approval", checked: false, onClick: null},
		{ label: "Request Compliance Approval", checked: true, onClick: null},
		{ label: "Publish", checked: false, onClick: null},
		{ label: "Comment", checked: true, onClick: null},
		{ label: "Workspace Notes", checked: true, onClick: null},
		{ label: "Options", checked: true, onClick: null},
		{ label: "Author", checked: true, onClick: null},
		{ label: "Compliance Approval", checked: true, onClick: null},
	];
	const dummyLibraryPermissions = [
		{ label: "View", checked: true, onClick: null},
		{ label: "Share", checked: true, onClick: null},
	];
	const dummyAnalyticsPermissions = [
		{ label: "View", checked: true, onClick: null},
	];
	const dummyClientManagementPermissions = [
		{ label: "Users", checked: true, onClick: null},
		{ label: "Companies", checked: false, onClick: null},
		{ label: "Distribution", checked: false, onClick: null},
	];
	const dummySystemPermissions = [
		{ label: "Per Ticker Disclosure", checked: true, onClick: null},
		{ label: "3SKYE Users", checked: true, onClick: null},
		{ label: "Roles and Permissions", checked: true, onClick: null},
	];

	const expandContent = (
		<CheckboxGroupWrapper align="start" gap="xxl">
			<CheckboxGroup list={dummyEditorPermissions} masterCheckbox={<Checkbox label="Editor" />} />
			<CheckboxGroup list={dummyLibraryPermissions} masterCheckbox={<Checkbox label="Library" checked />} />
			<CheckboxGroup list={dummyAnalyticsPermissions} masterCheckbox={<Checkbox label="Analytics" />} />
			<CheckboxGroup list={dummyClientManagementPermissions} masterCheckbox={<Checkbox label="Client Management" />} />
			<CheckboxGroup list={dummySystemPermissions} masterCheckbox={<Checkbox label="System Admin" checked/>} />
		</CheckboxGroupWrapper>
	);

	// Table map
	const dataMap = () => data.map((user, idx) => {
		return (
			<ExpandableTableRow expandContent={expandContent}>
				<td>{user.role}</td>
				<td>{user.users}</td>
				<td>{user.permissions}</td>
				<TableActionIconGroup
					download={{ hide: true }}
					edit
					duplicate={{ onClick: (e) => {e.stopPropagation(); alert("Shadow Clone Jutsu 🙏")} }}
					remove={{ onClick: (e) => e.stopPropagation(toast("Role deleted")) }}
				/>
			</ExpandableTableRow>
		);
		// return (
		// 	<tr key={idx} className="cursor-pointer">
		// 		<td>{user.role}</td>
		// 		<td>{user.users}</td>
		// 		<td>{user.permissions}</td>
		// 		<td className="table-action-icons">
		// 			<TableActionIcon icon={<Edit2 />} toolTip="Edit" onClick={() => alert("Edit Mode")} />
		// 			<TableActionIcon icon={<Trash2 />} toolTip="Delete" onClick={() => alert("Delet this")} />
		// 		</td>
		// 	</tr>
		// )
	});

	return (
		<Page fixedBanner>
			<PageContentBanner divider>
				<Button disabled icon={<Plus />}>New Role</Button>
			</PageContentBanner>
			<PageContentBodyGrid>
				<PageGridItem colSpan="12" disabled>
					<Table minWidth={600} colWidths={colWidths} headers={headers} dataMap={dataMap()} />
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	)
}

export default RolePermissionsList;