import React, { Component } from "react";
import moment from "moment";
import { withThreeSkyeGlobal } from "@threeskye/global";
import RemoteData from "../../components/RemoteData";
import withInfiniteScroll from "../../components/withInfiniteScroll";
import { throttle } from "throttle-debounce";

const CraigsDocsContext = React.createContext({});
const NUM_OF_DOCS_TO_LOAD = 40;
const formatDate = function (date) {
	return date ? date.format("YYYY-MM-DD") : null;
};

class ContextWrapper extends Component {
	state = { publishedDocs: null, unpublishedDocs: null, docsAreLoading: true, filters: { ticker: null, from: null, to: null }, hasLoadedAllPublishedDocs: false };

	constructor(props) {
		super(props);
		this.fetchInitialDocuments = this.fetchInitialDocuments.bind(this);
		this.fetchAllUnpublishedDocs = this.fetchAllUnpublishedDocs.bind(this);
		this.fetchMorePublishedDocs = this.fetchMorePublishedDocs.bind(this);
		this.fetchMoreDocuments = throttle(500, this.fetchMoreDocuments.bind(this));
		this.onDocumentClick = this.onDocumentClick.bind(this);
		this.setFilters = this.setFilters.bind(this);
	}

	componentDidMount() {
		const { setFetchMoreDataFunc } = this.props;
		this.fetchInitialDocuments();
		if (setFetchMoreDataFunc) {
			setFetchMoreDataFunc(this.fetchMoreDocuments);
		}
	}

	fetchInitialDocuments() {
		this.setState({ docsAreLoading: true });
		this.fetchAllUnpublishedDocs().then((unpublishedDocs) => {
			const numOfDocsToLoad = unpublishedDocs ? NUM_OF_DOCS_TO_LOAD - unpublishedDocs.length : NUM_OF_DOCS_TO_LOAD;
			if (numOfDocsToLoad > 0) {
				this.fetchMorePublishedDocs(numOfDocsToLoad).then(() => this.setState({ docsAreLoading: false }));
			} else {
				this.setState({ docsAreLoading: false });
			}
		});
	}

	fetchAllUnpublishedDocs() {
		return this.props.remote.get(`/documents/list/unpublished`).then((unpublishedDocs) => {
			this.setState({ unpublishedDocs });
			return unpublishedDocs;
		});
	}

	fetchMorePublishedDocs(numOfDocsToLoad) {
		const { filters, publishedDocs } = this.state;
		const { ticker } = filters;
		const from = formatDate(filters.from);
		const to = formatDate(filters.to);
		const offset = publishedDocs ? publishedDocs.length : 0;

		return this.props.remote.get(`/documents/list?ticker=${ticker}&&from=${from}&&to=${to}&&num=${numOfDocsToLoad}&&offset=${offset}`).then((morePublishedDocs) => {
			this.setState((prevState) => {
				if (prevState.publishedDocs) {
					prevState.publishedDocs = prevState.publishedDocs.concat(morePublishedDocs);
				} else {
					prevState.publishedDocs = morePublishedDocs;
				}
				if (!morePublishedDocs || morePublishedDocs.length < numOfDocsToLoad) {
					prevState.hasLoadedAllPublishedDocs = true;
				}
				return prevState;
			});
			return morePublishedDocs;
		});
	}

	fetchMoreDocuments() {
		this.setState({ docsAreLoading: true });
		return this.fetchMorePublishedDocs(NUM_OF_DOCS_TO_LOAD).then(() => this.setState({ docsAreLoading: false }));
	}

	openInNewTab(url) {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}
	
	//functions better as direct download than openInNewTab
	openInTopFrame(url) {
		const newWindow = window.open(url, '_top', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

	onDocumentClick(doc, event) {
		if(event) {
			event.stopPropagation()
		}
		
		if (doc && doc.id) {
			const { remote } = this.props;
			remote.get(`/documents/document/${doc.id}`).then((response) => {
				if (response) {
					this.openInTopFrame(response.url);
					/*
					const { filename, extension, base64 } = response;
					const linkSource = `data:${extension === "pdf" ? "application" : "text"}/${extension};base64,${base64}`;
					const downloadLink = document.createElement("a");
					downloadLink.href = linkSource;
					downloadLink.download = `${filename}.${extension}`;
					downloadLink.click();*/
				}
			});
		}
	}

	setFilters(filters) {
		const oldFilters = this.state.filters;
		if (filters.from !== oldFilters.from || filters.to !== oldFilters.to || filters.ticker !== oldFilters.ticker) {
			const { unpublishedDocs } = this.state;
			let filteredUnpublishedDocs = [];
			const fromDateIncludesToday = !filters.from || filters.from.isBefore(moment());
			const toDateIncludesToday = !filters.to || filters.to.isAfter(moment());
			if (unpublishedDocs && fromDateIncludesToday && toDateIncludesToday) {
				filteredUnpublishedDocs = unpublishedDocs.filter(({ ticker }) => ticker === filters.ticker);
			}
			this.setState({ filters, publishedDocs: null, filteredUnpublishedDocs, hasLoadedAllPublishedDocs: false }, this.fetchInitialDocuments);
		}
	}

	render() {
		const { children, scrollableElementRef, scrollableElementChildRef } = this.props;
		const { docsAreLoading, filters, publishedDocs, filteredUnpublishedDocs } = this.state;
		const { setFilters, onDocumentClick } = this;

		const craigsDocs = publishedDocs && filteredUnpublishedDocs ? filteredUnpublishedDocs.concat(publishedDocs) : filteredUnpublishedDocs || publishedDocs;

		return (
			<CraigsDocsContext.Provider
				value={{
					craigsDocs,
					craigsDocsAreLoading: docsAreLoading,
					craigsDocsFilters: filters,
					setCraigsDocsFilters: setFilters,
					onCraigsDocClick: onDocumentClick,
					scrollableElementRef,
					scrollableElementChildRef,
				}}>
				{children}
			</CraigsDocsContext.Provider>
		);
	}
}

export const CraigsDocsWrapper = withInfiniteScroll(withThreeSkyeGlobal(ContextWrapper));

export const withCraigsDocsFilters = function (WrappedComponent) {
	return class extends Component {
		render() {
			return (
				<CraigsDocsContext.Consumer>{({ craigsDocsFilters, setCraigsDocsFilters }) => <WrappedComponent {...this.props} {...{ craigsDocsFilters, setCraigsDocsFilters }} />}</CraigsDocsContext.Consumer>
			);
		}
	};
};

export const withCraigsDocsInfScrollRefs = function (WrappedComponent) {
	return class extends Component {
		render() {
			return (
				<CraigsDocsContext.Consumer>
					{({ scrollableElementRef, scrollableElementChildRef }) => <WrappedComponent {...this.props} {...{ scrollableElementRef, scrollableElementChildRef }} />}
				</CraigsDocsContext.Consumer>
			);
		}
	};
};

const withCraigsDocs = function (WrappedComponent) {
	return class extends Component {
		render() {
			return (
				<CraigsDocsContext.Consumer>
					{({ craigsDocs, craigsDocsAreLoading, onCraigsDocClick }) => (
						<RemoteData isLoading={craigsDocsAreLoading} data={craigsDocs}>
							<WrappedComponent {...this.props} {...{ craigsDocs, onCraigsDocClick }} />
						</RemoteData>
					)}
				</CraigsDocsContext.Consumer>
			);
		}
	};
};

export default withCraigsDocs;
