import React from 'react'
import Tooltip from '../popups/Tooltip'
import './IconLink.scss'

const IconLink = ({ icon, className, active, onClick, label }) => {
	return (
		<Tooltip disabled={!label} placement="right" label={label} delay={1000}>
			<div
				className={`link-container${active ? " current-route" : ""}${className ? " " + className : ""}`} 
				onClick={onClick}
			>
				{icon}
			</div>
		</Tooltip>
	)
}

export default IconLink