import React from "react";
import SidebarExtensionLink from "./SidebarExtensionLink";
import { withRouter } from "react-router";
import "./SidebarExtensionLinkList.scss";

const SidebarExtensionLinkList = (props) => {
	const { links, location } = props;

	return (
		<div className="sidebar-extension-link-list">
			{links && links.length > 0 && links.map((link, idx) => (
				<SidebarExtensionLink link={link.path} active={link.path === location.pathname}  key={idx}>{link.name}</SidebarExtensionLink>
			))}
		</div>
	);
} 

export default withRouter(SidebarExtensionLinkList);