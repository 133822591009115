import React, { Component } from "react";
import useWindowDimensions from "../../Utils/Utils";
import FlexWrapper from "../FlexWrapper";
import DetailSectionHeader from "../Shared/DetailSection/DetailSectionHeader";
import Page, { PageContentBodyGrid } from "../Shared/Page/Page";
import PageGridDivider from "../Shared/Page/PageGridDivider";
import PageGridItem from "../Shared/Page/PageGridItem";
import "./AnalyticsPage.scss";
import AnalyticsHeader from "./Components/AnalyticsHeader";
import AnalyticsToggleBlock from "./Components/AnalyticsToggleBlock";
import MapMetrics from "./Components/MapMetrics";
import TopAnalystsTable from "./Components/TopAnalystsTable";
import TopTickers from "./Components/TopTickers";
import TopResearchTable from "./Components/TopResearchTable";
import TopUsersTable from "./Components/TopUsersTable";
import TotalMetrics from "./Components/TotalMetrics";
import UsageMetrics from "./Components/UsageMetrics";
import BrowserChart from "./Charts/BrowserChart";

const AnalyticsPage = () => {
	
	const { width } = useWindowDimensions();

	return (
		<Page id="analytics-page" style={{paddingBottom: 0}} noBanner>
			{/* LEFT SIDE GRID */}
			<PageContentBodyGrid id="analytics-page-grid" paddingTop="0px" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				<PageGridItem container col="1 / span 7" style={{ paddingTop: "1.5rem" }}>
					<PageContentBodyGrid style={{rowGap: 48}} paddingBottom={48} gridColCount="6" subGrid>
						<PageGridItem col="1 / span 6">
							<TotalMetrics />
						</PageGridItem>
						<PageGridDivider col="1 / span 6" />
						<PageGridItem col="1 / span 6">
							<UsageMetrics />
						</PageGridItem>
						<PageGridDivider col="1 / span 6" />
						<PageGridItem col="1 / span 6">
							{/* Analysts / Research */}
							<AnalyticsToggleBlock header="Research Views" dateRanges>
								{/* <TopAnalystsTable /> */}
								<TopResearchTable />
							</AnalyticsToggleBlock>
						</PageGridItem>
						<PageGridItem col="1 / span 6">
							<MapMetrics dateRanges />
						</PageGridItem>
					</PageContentBodyGrid>
				</PageGridItem>
				{/* RIGHT SIDE GRID */}
				<PageGridItem container col="8 / span 5" style={{ paddingTop: "1.5rem", paddingBottom: 0 }}>
					<PageContentBodyGrid style={{rowGap: 48}} paddingBottom={48} gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"}>
						<PageGridItem col="1 / span 6">
							<AnalyticsHeader dateRanges>Most Active Users</AnalyticsHeader>
							<TopUsersTable />
						</PageGridItem>
						<PageGridItem col="1 / span 6">
							<AnalyticsToggleBlock header="Device Usage" dateRanges>
								<BrowserChart />
							</AnalyticsToggleBlock>
						</PageGridItem>
						<PageGridItem col="1 / span 6">
							<AnalyticsToggleBlock header="Tickers Searched" dateRanges>
								<TopTickers searched={true} watchlisted={false} />
							</AnalyticsToggleBlock>
						</PageGridItem>
					</PageContentBodyGrid>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
};

export default AnalyticsPage;
