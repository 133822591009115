import React, { useState, useEffect } from 'react';
import { Shield, User } from 'react-feather';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import PageGridItem from '../../Shared/Page/PageGridItem';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import Button from '../../../components/Buttons/Button';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import LoadingIcon from '../../../components/LoadingIcon';
import { useRemote } from '../../../Utils/Utils';


const SettingsProfile = () => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const editMode = false;

	const remote = useRemote()

	useEffect(() => {
		remote.get("/crm/me").then((me) => {
			let nameArray = me.name.split(" ")
			me.firstName =  nameArray.at(0)
			me.lastName = nameArray.at(-1)
			setUser(me)
		});
		setLoading(false)
	}, [])
	
	// Fields here should only be editable by users with the right permissions. They must also be linked to the User Management pages.

	return (
		<Page fixedBanner>
			<PageContentBanner divider>
				<ActionButtonsBanner
					hideBackButton
					editMode={editMode}
					edit={{ disabled: true }}
				/>
			</PageContentBanner>
			<PageContentBodyGrid rowGap="xl">
				{/* grid container for the user details section */}
				<PageGridItem container col="1 / span 8">
					<PageContentBodyGrid rowGap="xl" gridColCount="8" subGrid>
						{loading ? <LoadingIcon size={48} centered />
							: (
								<>
									<PageGridItem col="1 / span 8">
										<DetailSectionHeader header="Personal Details" icon={<User />} />
									</PageGridItem>
									<PageGridItem col="1 / span 4">
										<DetailField disabled value={user && user.firstName} label="First Name" />
									</PageGridItem>
									<PageGridItem col="5 / span 4">
										<DetailField disabled value={user && user.lastName} label="Last Name" />
									</PageGridItem>
									<PageGridItem col="1 / span 4">
										<DetailField disabled value={user && user.email} label="Email" />
									</PageGridItem>
									<PageGridItem col="5 / span 4">
										<DetailField disabled value={user && user.title} label="Role" />
									</PageGridItem>
									<PageGridItem col="1 / span 4">
										<DetailField disabled value={user && user.phone} label="Phone Number" />
									</PageGridItem>
									{(!user || !user.adLogin) && (
										<PageGridItem col="1 / span 4">
											<Button disabled>Save Changes</Button>
										</PageGridItem>
									)}
								</>
							)
						}
						{/* Security Page Details */}
						<PageGridDivider col="1 / span 8" />
						<PageGridItem col="1 / span 8">
							<DetailSectionHeader header="Security" icon={<Shield />} />
						</PageGridItem>
						<PageGridItem col="1 / span 8">
							<p>{!user || user.adLogin ? "Please reset your password via your company password reset process" : "Enter current password to update to a new one"}</p>
						</PageGridItem>
						{(!user || !user.adLogin) && (
							<>
								<PageGridItem col="1 / span 4">
									<DetailField editMode type="password" label="Current Password" placeholder="********" />
								</PageGridItem>
								<PageGridItem col="1 / span 4">
									<DetailField editMode type="password" label="New Password" placeholder="********" />
								</PageGridItem>
								<PageGridItem col="5 / span 4">
									<DetailField editMode type="password" label="Confirm New Password" placeholder="********" />
								</PageGridItem>
								<PageGridItem col="1 / span 4">
									<Button disabled>Update Password</Button>
								</PageGridItem>
							</>
						)}
					</PageContentBodyGrid>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default SettingsProfile;