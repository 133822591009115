import React, { useEffect, useState } from 'react';
import { UserPlus } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import Table from '../../../components/Table/Table';
import { Mail } from 'react-feather';
import PageGridItem from '../../Shared/Page/PageGridItem';
import IconCounter from '../../../components/IconCounter';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import useWindowDimensions, { useRemote, undoDeleteUser } from '../../../Utils/Utils';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import TableActionIconGroup from '../../../components/Table/TableActionIconGroup';
import { toastDanger, UndoToastMessage, toast } from '../../../components/popups/Toast';

const UsersLists = ({ changeRoute }) => {

	const remote = useRemote();

	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [hasSearchTerm, setHasSearchTerm] = useState(false);
	const [loading, setLoading] = useState(true)
	const { width } = useWindowDimensions();

	useEffect(() => {
		getContacts()
	}, []);

	const getContacts = () => {
		remote.get("/crm/contacts")
		.then((contacts) => {
			let sortedContacts = contacts.sort((userA, userB) => {
				let nameA = userA.firstName + " " + userA.lastName
				let nameB = userB.firstName + " " + userB.lastName
				return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
			})
			return sortedContacts
		})
		.then((contacts) => setData(contacts))
		.then(() => setLoading(false))
	}

	
	const addContactToData = (contact) => {
		let contactsToSort = [...data]
		let sortedContacts = contactsToSort.sort((userA, userB) => {
			let nameA = userA.firstName + " " + userA.lastName
			let nameB = userB.firstName + " " + userB.lastName
			return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
		})
		setData(sortedContacts)
	}

	const removeUser = (e, user) => {
		e.stopPropagation();
		remote.get(`/crm/contact/${user.id}/lists`).then((subscribedLists) => {
			remote.delete(`/crm/contacts/${user.id}`)
			.then(() => {
				let newArray = []
				data.forEach((contact) => {
					if (contact.id !== user.id) {
						newArray.push(contact)
					}
				})
				setData(newArray)
			}).then(() => 
				toast(<UndoToastMessage message="User removed" onClick={() => {undoDeleteUser(user, subscribedLists, addContactToData)}} />, {autoClose: 10000})
			)
			.catch(() => {
				toastDanger("User could not be removed")
			})
		})
	}

	const colWidths = [20 + "%", 20 + "%", 10 + "%", 20 + "%", 5 + "%", 15 + "%"]
	const headers = ["Name", "Company", "Title", "Email", "Distribution Lists", ""]
	const dataMap = (dataToMap) => dataToMap && dataToMap.map((user, idx) => {
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => changeRoute(ROUTE_PATHS[PAGES.CRM] + "/users/user/" + user.id)}>
				<td>{user.firstName + " " + user.lastName}</td>
				<td>{user.companyName}</td>
				<td>{user.role}</td>
				<td><div className="truncate">{user.email}</div></td>
				<td>
					<IconCounter icon={<Mail />} dataAvailable={true} type="read" count={user.distributionLists} />
				</td>
				<TableActionIconGroup
					download={{ hide: true }}
					edit={{ onClick: (e) => { e.stopPropagation(); changeRoute(ROUTE_PATHS[PAGES.CRM] + "/users/user/" + user.id + "?edit=true") } }}
					duplicate={{ onClick: (e) => { e.stopPropagation(); changeRoute(ROUTE_PATHS[PAGES.CRM] + "/users/user/" + user.id + "?create=true") } }}
					remove={{ onClick: (e) => removeUser(e, user) }}
				/>
			</tr>
		);
	})
	return (
		<Page fixedBanner showScrollbar>
			{/* Modal */}
			<PageContentBanner divider gap="m">
				<Button icon={<UserPlus />} onClick={() => changeRoute(ROUTE_PATHS[PAGES.CRM] + "/users/user/create")}>{width < 900 ? "" : "Create User"}</Button>
				<SearchTermFilter
					size="small"
					width="l"
					placeholder={`Search users ${ loading ? "" : "(" + data.length + ")"}`}
					isClearable
					dataSet={data}
					setFilteredData={setFilteredData}
					path={["companyName", "firstName", "lastName", "preferredName", "email", "role"]}
					hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
				/>
			</PageContentBanner>
			<PageContentBodyGrid showScrollbar>
				<PageGridItem colSpan="12">
					<Table
						minWidth={1000}
						colWidths={colWidths}
						headers={headers}
						dataMap={dataMap(hasSearchTerm ? filteredData : data)}
						noResults={{ message: "No users found", subMessage: "Try adding a new user." }}
						dataLoading={loading}
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(UsersLists);