import React from 'react';
import { ChevronDown } from 'react-feather';
import FlexWrapper from '../../layouts/FlexWrapper';
import "./ShowMoreButton.scss";

const ShowMoreButton = ({onClick, active}) => {
	return (
		<FlexWrapper fullWidth justify="center" align="center" gap="s" className={`show-more-button p-m border-default-top cursor-pointer${active ? " button-active" : ""}`} onClick={onClick}>
			<p>{active ? "Show Less" : "Show More"}</p><ChevronDown size={16} />
		</FlexWrapper>
	);
}

export default ShowMoreButton;