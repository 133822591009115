import React from 'react';
import { Clock, Eye, Search } from 'react-feather';
import Table from '../../../components/Table/Table';
import IconCounter from '../../../components/IconCounter';
import FlexWrapper from '../../FlexWrapper';
import ProfileImage from '../../../components/ProfileImage';
import TickerSectorItem from '../../../components/TickerSectorItem';
import useWindowDimensions from '../../../Utils/Utils';

const TopResearchTable = () => {
	const { width } = useWindowDimensions();
	const colWidths = width > 1480 ? ["15%", "15%", "40%", "30%"] : ["15%", "15%", "30%", "35%"];
	const data = [
		{ ticker: "MEL.NZ", author: "Charles Xavier", title: "Stable content on the horizon for this really long title", publishedDate: "30/08/2022", views: 376, session: "12m 34s", logo: "https://research-portal-test.3skye.com/icons/tickers/MEL.NZ.png"},
		{ ticker: "STU.NZ", author: "Charles Xavier", title: "Stable content on the horizon for this really long title", publishedDate: "30/08/2022", views: 376, session: "12m 34s", logo: "https://research-portal-test.3skye.com/icons/tickers/STU.NZ.png"},
		{ ticker: "MCY.NZ", author: "Charles Xavier", title: "Stable content on the horizon for this really long title", publishedDate: "30/08/2022", views: 376, session: "12m 34s", logo: "https://research-portal-test.3skye.com/icons/tickers/MCY.NZ.png"},
		{ ticker: "CEN.NZ", author: "Charles Xavier", title: "Stable content on the horizon for this really long title", publishedDate: "30/08/2022", views: 376, session: "12m 34s", logo: "https://research-portal-test.3skye.com/icons/tickers/CEN.NZ.png"},
		{ ticker: "OCA.NZ", author: "Charles Xavier", title: "Stable content on the horizon for this really long title", publishedDate: "30/08/2022", views: 376, session: "12m 34s", logo: "https://research-portal-test.3skye.com/icons/tickers/OCA.NZ.png"},
	];

	const dataMap = () => data && data.map((research, idx) => {
		return (
			<tr key={idx} className="cursor-pointer">
				<td>
					<TickerSectorItem size="m" label={research.ticker} image={research.logo} />
				</td>
				<td>{research.publishedDate}</td>
				<td className="truncate">{research.title}</td>
				<td className="table-action-icons transition-fade-in-slow">
					<FlexWrapper gap="xxl" justify="flex-end">
						<ProfileImage src="https://i.ibb.co/9vjYZzn/pexels-photo-1024311.jpg" size={24} />
						<IconCounter icon={<Eye />} tooltip="Views" dataAvailable count={research.views} />
						<IconCounter icon={<Clock />} tooltip="Average Session" dataAvailable count={research.session} />
					</FlexWrapper>
				</td>
			</tr>
		);
	});

	return (
		<Table rowSize="s" colWidths={colWidths} dataMap={dataMap()} />
   );
}

export default TopResearchTable;