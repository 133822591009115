import React from 'react';
import { Copy, Download, Edit2, Trash2 } from 'react-feather';
import TableActionIcon from './TableActionIcon';

const TableActionIconGroup = ({edit, duplicate, remove, download, disabled}) => {
	return (
		<td className={`table-action-icons${disabled ? " action-icons-disabled" : ""}`}>
			{!edit || (edit && edit.hide) ? "" : (
				<TableActionIcon icon={edit && edit.icon ? edit.icon : <Edit2 />} toolTip={edit && edit.toolTip ? edit.toolTip : "Edit"} onClick={edit && edit.onClick} />
			)}
			{!duplicate || (duplicate && duplicate.hide) ? "" : (
				<TableActionIcon icon={duplicate && duplicate.icon ? duplicate.icon : <Copy />} toolTip={duplicate && duplicate.toolTip ? duplicate.toolTip : "Duplicate"} onClick={duplicate && duplicate.onClick} />
			)}
			{!remove || (remove && remove.hide) ? "" : (
				<TableActionIcon hover="danger" icon={remove && remove.icon ? remove.icon : <Trash2 />} toolTip={remove && remove.toolTip ? remove.toolTip : "Delete"} onClick={remove && remove.onClick} />
			)}
			{!download || (download && download.hide) ? "" : (
				<TableActionIcon icon={download && download.icon ? download.icon : <Download />} toolTip={download && download.toolTip ? download.toolTip : "Download"} onClick={download && download.onClick} />
			)}
		</td>
   );
}

export default TableActionIconGroup;