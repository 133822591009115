import React, {useEffect, useState} from 'react';
import { UserPlus } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Table from '../../../components/Table/Table';
import useWindowDimensions, { useRemote } from '../../../Utils/Utils';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import SelectSearchField from '../../../components/Input/SelectSearchField';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

const UserManagement = ({changeRoute}) => {

	// Data for table?
	// const data = [
	// 	{ name: "Hermione Granger", title: "Senior Research Analyst", isAuthor: "Yes", email: "hermione.granger@cip.com", phone: "021012345" },
	// 	{ name: "Ron Weasley", title: "Senior Research Analyst", isAuthor: "Yes", email: "Ron.weasley@cip.co.nz", phone: "021012345" },
	// 	{ name: "Harry Potter", title: "Research Analyst", email: "Harry.potter@cip.co.nz", phone: "021012345" },
	// 	{ name: "Draco Malfoy", title: "Senior Research Analyst", isAuthor: "Yes", email: "Draco.malfoy@cip.co.nz", phone: "021012345" },
	// 	{ name: "Romilda Vane", title: "Associate Research Analyst", isAuthor: "Yes", email: "Romilda.Vane@cip.co.nz", phone: "021012345" }
	// ];

	// Table widths and headers
	const colWidths = [30 + "%", 20 + "%", 10 + "%", 20 + "%", 20 + "%"];
	const headers = ["Name", "Title", "Author", "Email", "Phone", ""];
	const authorsColWidths = [3 + "%", 27 + "%", 20 + "%", 10 + "%", 20 + "%", 20 + "%"];
	const authorHeaders = ["", "Name", "Title", "Author", "Email", "Phone"];
	const { width } = useWindowDimensions();

	const [data, setData] = useState([]);
	const [authors, setAuthors] = useState([]);
	const [viewAuthors, setViewAuthors] = useState(false);

	const remote = useRemote();
	const params = new URLSearchParams(window.location.search);
	const authorsQuery = params.get('authors');

	useEffect(() => {
		if (authorsQuery) {
			setViewAuthors(true)
		}
		remote.get('/crm/users').then((val) => {
			setData(val)
			setAuthors(val
				.filter((person) => person.isAuthor)
				.sort((a, b) => a.sortOrder - b.sortOrder))
		});
	}, [])

	const isAuthor = (data) => {
		return data ? "Yes" : "No";
	};

	// Table map
	const dataMap = () => data.map((user, idx) => {
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + "/user-management/" + user.email)}>
				<td>{user.name}</td>
				<td>{user.title}</td>
				<td>{isAuthor(user.isAuthor)}</td>
				<td>{user.email}</td>
				<td>{user.phone}</td>
			</tr>
		)
	});

	const DragHandle = sortableHandle(() => (
		<span className="material-symbols-outlined">drag_handle</span>
	));
	
	const SortableItem = sortableElement(({ user, idx }) => (
		<tr key={idx} className="cursor-pointer dragged" onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + "/user-management/" + user.email + "?authors=true")} >
			<td><DragHandle /></td>
			<td>{user.name}</td>
			<td>{user.title}</td>
			<td>{isAuthor(user.isAuthor)}</td>
			<td>{user.email}</td>
			<td>{user.phone}</td>
			<td>{user.sortOrder}</td>
		</tr>
	  ));
	  
	  const SortableList = sortableContainer(({ data, email }) => {
		return (
		  <tbody>
			{data
			  .map((user, idx) => (
				<SortableItem
				  key={idx}
				  index={idx}
				  user={user}
				  email={email}
				/>
			  ))}
		  </tbody>
		);
	  });
	  

	const sortEnd = (move) => {
		const userDragged = authors[move.oldIndex]
		const updatedData = {
			email: userDragged.email,
			sortOrder: move.newIndex + 1,
		};
	  
		remote.put("/crm/user/order", updatedData)
			.then((updated) => {
				setAuthors(updated);
			})
			.catch((error) => {
				console.error("Error updating sortOrder:", error);
			});
	};

	return (
		<Page fixedBanner>
			<PageContentBanner divider gap="m">
				<Button disabled icon={<UserPlus />}>{width < 900 ? "" : "Create User"}</Button>
				<SelectSearchField size="small" width="m" placeholder="Search users" isClearable/>
				<Button onClick={() => setViewAuthors(!viewAuthors)}>{width < 900 ? "" : (!viewAuthors ? "Authors" : "All Users")}</Button>
			</PageContentBanner>
			<PageContentBodyGrid>
				<PageGridItem colSpan="12">
					<Table minWidth={900} 
					colWidths={viewAuthors ? authorsColWidths : colWidths} 
					headers={viewAuthors ? authorHeaders : headers} 
					dragDrop={viewAuthors ? true : false} 
					dataMap={viewAuthors ? <SortableList data={authors} useDragHandle onSortEnd={sortEnd}/> : dataMap()} 
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	)
}

export default withRouteChange(UserManagement);