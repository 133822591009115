import React from 'react';
import "./UnorderedList.scss";

const UnorderedList = (props) => {
	const { children } = props;
	return (
		<ul className="unordered-list-wrapper">
			{children}
		</ul>
	)
}

export default UnorderedList;