import React from 'react';
import "./SideBarComment.scss";

const SideBarComment = (props) => {
	let { className, comment } = props;
	const { name, date } = comment;

	return (
		<div className={`comment-outer-wrapper${className ? " " + className : ""}`}>
				<React.Fragment>				
					{/* <div className="comment-user-details-wrapper mb-s"> */}
						{/* <ProfileImage src={image ? image : ""} initials={image ? false : initials} /> */}
						<div className="comment-user-details">
							<h4 className="comment-user-name">{name}</h4>
							<h5 className="comment-date">{date}</h5>
						</div>
					{/* </div> */}
					<div className="comment-wrapper">
					{comment.comment ? <p>{comment.comment}</p> : <p className="extension-label">No comment.</p>}
					</div>
				</React.Fragment>
		</div>
	)
}

export default SideBarComment;