import React, { Component } from "react";
import CraigsLibraryTable from "./CraigsLibraryTable";
import { PageContentBodyGrid } from "../Shared/Page/Page";
import { withCraigsDocsInfScrollRefs } from "./withCraigsDocs";
import PageGridItem from "../Shared/Page/PageGridItem";
import { withRouter } from "react-router";
// import { SortParamsWrapper } from "@threeskye/core-components/dist/components/Sorting/WithSortParams";

class CraigsLibraryPageBody extends Component {

	onDeliveryClick = (event, document) => {
		if (event) {
			event.stopPropagation()
		}
		this.props.history.push("/crm/delivery/"+document.docId);
	}

	render() {
		const { scrollableElementRef, scrollableElementChildRef } = this.props;
		return (
			<PageContentBodyGrid id="craigs-library-page-body" forwardRef={scrollableElementRef} showScrollbar>
				<PageGridItem fullWidth showScrollbar>
					{/* <SortParamsWrapper> */}
					<div ref={scrollableElementChildRef}>
						<CraigsLibraryTable onDeliveryClick={this.onDeliveryClick} />
					</div>
					{/* </SortParamsWrapper> */}
				</PageGridItem>
			</PageContentBodyGrid>
		);
	}
}

export default withRouter(withCraigsDocsInfScrollRefs(CraigsLibraryPageBody));