import React, { useEffect, useState } from 'react';
import Button from '../../../components/Buttons/Button';
import Tab from '../../../components/Menus/Tab';
import StatusToken from '../../../components/StatusTokens';
import { useRemote } from '../../../Utils/Utils';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import PageGridItem from '../../Shared/Page/PageGridItem';

const CompanyMgmt = (props) => {

	const [company, setCompany] = useState(null);
	const [edit, setEdit] = useState("");
	const [view, setView] = useState("");

	const remote = useRemote();

	const setTemplatesEnabled = (template) => {
		switch (template) {
			case "peer":
				setCompany({ ...company, peerReviewAvailable: !company.peerReviewAvailable })
				break;
			case "compliance":
				setCompany({ ...company, complianceReviewAvailable: !company.complianceReviewAvailable })
				break;

			default:
				break;
		}
	}

	const getTokenState = (template) => {
		switch (template) {
			case "peer":
				return company.peerReviewAvailable ? "positive" : ""
			case "compliance":
				return company.complianceReviewAvailable ? "positive" : ""
			default:
				break;
		}

	}
	const getTokenMessage = (template) => {
		switch (template) {
			case "peer":
				return company.peerReviewAvailable ? "Hide Peer Templates" : "Show Peer Templates"
			case "compliance":
				return company.complianceReviewAvailable ? "Hide Compliance Templates" : "Show Compliance Templates"
			default:
				break;
		}
	}

	useEffect(() => {
		remote.get("/crm/company").then(setCompany);
	}, [])

	return <Page fixedBanner>
		{/* Page banner with back button */}
		<PageContentBanner divider>
			<ActionButtonsBanner />
		</PageContentBanner>
		{/* Page content */}
		<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
			{/* grid container for the template details section */}
			<PageGridItem container col="1 / span 6">
				{company && <PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
					{/* General Details */}
					{company && (
						<>
							<StatusToken onClick={() => setTemplatesEnabled("compliance")} type={getTokenState("compliance")}>{getTokenMessage("compliance")}</StatusToken>
							<StatusToken onClick={() => setTemplatesEnabled("peer")} type={getTokenState("peer")}>{getTokenMessage("peer")}</StatusToken>
						</>
					)
					}
					<PageGridDivider col="1 / span 6" />
							<Tab disabled={!company || !company.complianceReviewAvailable} onClick={() => setView(company.complianceReviewEmailTemplate)} active={view === company.complianceReviewEmailTemplate} text="Compliance Template"/>
							<Tab disabled={!company || !company.complianceReviewAvailable} onClick={() => setView(company.complianceReviewApprovalEmailTemplate)} active={view === company.complianceReviewApprovalEmailTemplate} text="Compliance approval template" />
							<Tab disabled={!company || !company.peerReviewAvailable} onClick={() => setView(company.peerReviewEmailTemplate)} active={view === company.peerReviewEmailTemplate} text="Peer template" />
							<Tab disabled={!company || !company.peerReviewAvailable} onClick={() => setView(company.peerReviewApprovalEmailTemplate)} active={view === company.peerReviewApprovalEmailTemplate} text="Peer approval template" />
					{/* Publishing actions */}
					<PageGridDivider col="1 / span 6" />
					{/* Custom Publishing actions */}
					<iframe width="680" height="1024" srcdoc={view} />
				</PageContentBodyGrid>}
			</PageGridItem>

		</PageContentBodyGrid >
	</Page >

}

export default CompanyMgmt;