import React, { useState, useEffect, useRef } from 'react';
import RemoteImpl from '@threeskye/global/dist/RemoteImpl';
import SessionStorage from '@threeskye/global/dist/SessionStorage'
import { format, parseISO } from 'date-fns'
import Tooltip from '../components/popups/Tooltip';
import { Mail, AlertCircle, Send, Eye, Users, BookOpen, Smartphone, Monitor } from "react-feather";
import PdfIcon from '../images/icons/PdfIcon';
import XlsIcon from '../images/icons/XlsIcon';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export const getIcon = (type, size, disabled, label, colour) => {
    switch (type) {
        case "send":
            return <Tooltip label="Delivered"><Send width={size} colour={colour} className="colour-text-positive"/> </Tooltip>
        case "fail":
            return <Tooltip label="Failed"><AlertCircle width={size} colour={colour} className="colour-text-danger"/> </Tooltip>
        case "read":
            return <Tooltip label={label ? label : "Read"}><Mail width={size} colour={colour} className="colour-text-purple" disabled={disabled === ""} /> </Tooltip>
        case "views":
            return <Tooltip label={label ? label : "Portal Views"}><Eye width={size} colour={colour} className="colour-text-primary" disabled={disabled === ""} /></Tooltip>
        case "downloads":
            return <Tooltip label={label ? label : "PDF Downloads"}><PdfIcon size={size} colour={colour} noHover disabled={disabled === ""} /></Tooltip>
        case "modalDownloads":
		case "modelDownloads":
				return <Tooltip label={label ? label : "Model Downloads"}><XlsIcon size={size} colour={colour} noHover disabled={disabled === ""} /></Tooltip>
        case "watching":
            return <Tooltip label="Model Downloads"><Users size={size} colour={colour} noHover /></Tooltip>
        case "research":
            return <Tooltip label="Model Downloads"><BookOpen size={size} colour={colour} noHover /></Tooltip>
        case "mobile":
            return <Tooltip label="Mobile"><Smartphone size={size} colour={colour} className="colour-text-accent-2" disabled={disabled === ""} /></Tooltip>
        case "desktop":
            return <Tooltip label="Desktop"><Monitor size={size} colour={colour} className="colour-text-primary" disabled={disabled === ""} /></Tooltip>
        default:
            break;
    }
}

export const countReadersPerDoc = (arr1, arr2, property, string) => {
	const result = {};

    arr1.forEach(doc => {
        result[doc.researchId] = 0;
        arr2.forEach(user => {
            user.readDocs.forEach(readDoc => {
                if (readDoc.researchId === doc.researchId) {
                    if (readDoc[property] === string) result[doc.researchId]++;
                    else if (!property && !string) result[doc.researchId]++
                } 
            });
        });
    });

    return result;
}

export const getUsersWithRelevantResearch = (users, research) => {
    return users.filter(user => {
      return user.readDocs.some(doc => research.hasOwnProperty(doc.researchId));
    });
  }

export  const countDocsPerViewer = (arr, property, value) => {
    return arr.filter(obj => obj[property] === value).length;
}

export const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0);


export const getPercentage = (value, total, places) => {
    const sum = ((value / total) * 100).toFixed(places ? places : 0) + "%";
    return sum;
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

// based off this snippet: https://stackoverflow.com/a/54570068
export function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef < HTMLDivElement > (null);

    const handleHideDropdown = (event) => {
        if (event.key === 'Escape') {
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}

export const useOutsideAlerter = (ref, callback) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export function formatDate(date) {
    let preformattingDate = date
    if (date) {
        if(Array.isArray(preformattingDate)) {
            preformattingDate = new Date(...date)
        }
        try {
            let nonISODate = parseISO(preformattingDate)
            let formattedDate = format(nonISODate, "dd/MM/yyyy")
            return formattedDate
        } catch {
            let formattedDate = format(preformattingDate, "dd/MM/yyyy")
            return formattedDate
        }
    } else {
        return ""
    }
}

export function formatDateTime(date) {
    let formattedDate = format(new Date(date), "dd/MM/yyyy HH:mm")
    return formattedDate
}

export function parseLocationDetails(c) {
    if (c && c.locations && Array.isArray(c.locations) && c.locations.length >= 1) {
        c.locations = { value: c.locations[0].location, label: c.locations[0].location, location: c.locations[0].location }
    }
    return c
}

export function onDocumentClick(doc) {
    if (doc && doc.id) {
        remote.get(`/documents/document/${doc.id}`).then((response) => {
            if (response) {
                openInNewTab(response.url);
            }
        });
    }
}

function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const remote = new RemoteImpl();
export const useRemote = () => remote;

const storage = new SessionStorage(remote);
export const useStorage = () => storage;

export const undoDeleteUser = (user, subscribedLists, updateState) => {
    //create user
    remote.post(`/crm/contacts`, user)
        .then((contactResponse) => {
            //add user to state
            updateState(contactResponse.data)
            //link user and org
            user.companyId && remote.put(`/crm/organisations/${user.companyId}/contacts/${contactResponse.data.id}`)
            //link user to distribution lists
            subscribedLists.forEach((list) => {
                remote.put(`/crm/lists/${list.id}/members/${contactResponse.data.id}`)
            })
        })
}
export const undoDeleteCompany = (company, members, updateState) => {
    remote.post(`/crm/organisations`, company)
        .then((companyResponse) => {
            //save members
            members.map(contact => {
                remote.put(`/crm/organisations/${companyResponse.data.id}/contacts/${contact.id}`)
            })
            updateState(companyResponse.data)
        })
}


export const undoDeleteDistributionList = (distributionList, tickers, contacts, updateState) => {
    //save list
    remote.post("/crm/lists", { name: distributionList.name }).then((response => {
        //save tickers
        tickers.forEach((ticker) => {
            remote.put(`/crm/lists/${response.data.id}/tickers/${ticker.id}`)
        })
        //save members
        contacts.forEach(contact => {
            remote.put(`/crm/lists/${response.data.id}/members/${contact.id}`)
        })
        updateState(response.data)
    }));
}


const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export function useDebounceEffect( fn, waitTime, deps ) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps)
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  }, deps)
}
