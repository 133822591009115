import React, { Component } from "react";
import "./CraigsLibraryPage.scss";
import Page from "../Shared/Page/Page";
import CraigsLibraryPageContentBanner from "./CraigsLibraryPageContentBanner";
import CraigsLibraryPageBody from "./CraigsLibraryPageBody";
import { CraigsDocsWrapper } from "./withCraigsDocs";

class CraigsLibraryPage extends Component {
	render() {
		return (
			<Page fixedBanner id="craigs-library-page">
				<CraigsDocsWrapper>
					<CraigsLibraryPageContentBanner />
					<CraigsLibraryPageBody />
				</CraigsDocsWrapper>
			</Page>
		);
	}
}

export default CraigsLibraryPage;
