import React, { useState, useEffect } from "react";
import { useRemote, getIcon, useStorage } from '../../Utils/Utils';
import { gicsSectors } from '../../Utils/DummyData';
import withCraigsDocs from "./withCraigsDocs";
import withRouteChange from "@threeskye/route-change"
import Table from "../../components/Table/Table"
import "./CraigsLibraryTable.scss";
import LibraryTableRow from "./LibraryTableRow";
import { useLocation } from "react-router-dom";

const CraigsLibraryTable = ({ craigsDocs, onCraigsDocClick, onDeliveryClick, changeRoute /*sortParams, toggleSort*/, match }) => {
	const [data, setData] = useState([])
	const [expandedRows, setExpandedRows] = useState([])

	const [tickers, setTickers] = useState([])
	const [models, setModels] = useState([])
	const [modelsLoading, setModelsLoading] = useState(true)
	
	const storage = useStorage();
	const remote = useRemote();
	const location = useLocation()

	useEffect(() => {
		storage.getOrFetch("/crm/tickers").then(setTickers)
		remote.get("/crm/tickers/models").then((resp) => {
			setModels(resp)
			setModelsLoading(false)
		});

	}, [])

	useEffect(() => {
		setData(craigsDocs)
		// if there is a searchQuery in the url, open the appropriate publication Settings
		if (craigsDocs && location && location.search) {
			let noteSettingsSearchParamArray = location.search
			.split(/[=,?]/g)
			.filter(val => val !== "" && val !== "note-settings")
			.map((val) => parseInt(val))
			setExpandedRows(noteSettingsSearchParamArray)
		}
	}, [JSON.stringify(craigsDocs)])


	const getImage = (t) => {
		const sector = gicsSectors.find(s => s.name === t)
		const theTicker = tickers && tickers.find(ticker => ticker.ric === t)

		if (theTicker) return theTicker.logo
		else if (sector) return sector.image
		else return ""
	}

	const headerWidths = ["80px", "50px", "180px", "320px", "160px", "80px", "50px", "50px", "50px", "50px", "50px", "50px",]
	const headers = ["Date", "", "Coverage", "Title", "Author", "", getIcon("send", "16px"), getIcon("fail", "16px"), getIcon("read", "16px"), getIcon("views", "16px"), getIcon("downloads", "16px"), getIcon("modalDownloads", "16px")]

	const dataMap = data.map((document, idx) => {

		return <LibraryTableRow
			data={document}
			idx={idx}
			tickers={tickers}
			expandedRows={expandedRows}
			setExpandedRows={setExpandedRows}
			getImage={getImage}
			onCraigsDocClick={onCraigsDocClick}
			onDeliveryClick={onDeliveryClick}
			models={models}
			modelsLoading={modelsLoading}
		/>
	});

	return (
		<>
			<Table id="craigs-library-table" headerWidths={headerWidths} minWidth={1250} headers={headers} dataMap={dataMap} />
		</>
	);
}

export default withRouteChange(withCraigsDocs(CraigsLibraryTable));

export const RawCraigsLibraryTable = CraigsLibraryTable;
