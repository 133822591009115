import { Element as SlateElement, Editor, Path, Transforms } from 'slate';

function withPageBreaks(editor) {

	editor.addPageBreak = (pageBreak, path) => {
		try {
			const nodeEntry = Editor.node(editor, path)
			const node = nodeEntry[0];
			console.log("Node from editor.node",node, path);
			console.log("Node.pagebreaks ", node.pageBreaks)
			let pageBreaks = [];
			if (node.pageBreaks && Array.isArray(node.pageBreaks)) {
				pageBreaks = pageBreaks.concat(node.pageBreaks);
			} 
			pageBreaks.push({pos:pageBreak.pos, id:pageBreak.pageBreakId, offset: pageBreak.offset, path});
			console.log("Setting path transform ", path, pageBreaks)
			Transforms.setNodes(editor, {
				pageBreaks:pageBreaks
			}, {
				at:path
			});
	
		} catch (error) {
			console.log(error)
		}
	}

	editor.removePageBreak = (pageBreak, path) => {
		try {
			const nodeEntry = Editor.node(editor, path)
			const node = nodeEntry[0];
			console.log("Node from editor.node",node);
			console.log("Node.pagebreaks ", node.pageBreaks)
			const pageBreaks = [];
			if (node.pageBreaks && Array.isArray(node.pageBreaks)) {
				node.pageBreaks.forEach(p => {
					if (p.id !== pageBreak.pageBreakId) {
						pageBreaks.push(p)
					}
				})
			}
			Transforms.setNodes(editor, {
				pageBreaks:pageBreaks
			}, {
				at:path
			});
		} catch (error) {
			console.log(error)
		}
	}

	editor.getPageBreakNode = (path) => {
		console.log("Getting node for ",path);
		return Editor.node(editor, path);
	} 

	editor.getAllPageBreaks = (end) => {
		const match = Editor.nodes(editor, {
			at: {
				anchor: {path:[0]},
				focus: {path:[end]}
			},
			match: n => n.pageBreaks && n.pageBreaks.length > 0
		})
		return match;
	}

	return {editor:editor};
		
}
export default withPageBreaks