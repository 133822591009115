import React from 'react';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import Table from '../../../components/Table/Table';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { useState } from 'react';
import { useRemote } from '../../../Utils/Utils';
import Tooltip from '../../../components/popups/Tooltip';
import { Eye, Mail, Users } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';

const AnalyticsUsersList = ({ changeRoute }) => {
	const remote = useRemote();

	const dummyAnalytics = [
		{
			user: { firstName: "Coraline", lastName: "Jones", email: "Coraline.jones@gmail.com", id: 123456 },
			analytics: { watching: 11, emailViews: 22, portalViews: 33, pdfDownload: 44, modalDownload: 55 },
		},
		{
			user: { firstName: "Gary", lastName: "Oak", email: "Gary.oak@acme.com", id: 123456 },
			analytics: { watching: 1, emailViews: 2, portalViews: 3, pdfDownload: 4, modalDownload: 5 },
		},
	];

	// State
	const [data, setData] = useState(dummyAnalytics);
	const [filteredData, setFilteredData] = useState([]);
	const [hasSearchTerm, setHasSearchTerm] = useState(false);
	const [loading, setLoading] = useState(false);

	// Table data
	const colWidths = ["20%", "20%", "10%", "10%", "10%", "10%", "10%"];
	const headers = [
		"Name",
		"Email",
		{
			text: (
				<Tooltip label="Watching">
					<Users className="cursor-pointer colour-text-positive-dark" size={16} />
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Email Views">
					<Mail className="cursor-pointer colour-text-purple" size={16} />
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Portal Views">
					<Eye className="cursor-pointer colour-text-primary" size={16} />
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="PDF Downloads">
					<PdfIcon noHover className="cursor-pointer" size={16} />
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Model Downloads">
					<XlsIcon noHover className="cursor-pointer" size={16} />
				</Tooltip>
			),
			className: "text-align-center"
		},
	];

	const dataMap = (dummyAnalytics) =>
		dummyAnalytics &&
		dummyAnalytics.map((data, idx) => {
			return (
				<tr key={idx} className="cursor-pointer" onClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/users/user/" + data.user.id)}>
					{/* User details */}
					<td>{data.user.firstName + " " + data.user.lastName}</td>
					<td>
						<div className="truncate">{data.user.email}</div>
					</td>
					{/* Analytics icons */}
					<td className="text-align-center">{data.analytics.watching}</td>
					<td className="text-align-center">{data.analytics.emailViews}</td>
					<td className="text-align-center">{data.analytics.portalViews}</td>
					<td className="text-align-center">{data.analytics.pdfDownload}</td>
					<td className="text-align-center">{data.analytics.modalDownload}</td>
				</tr>
			);
		});

	return (
		<Page fixedBanner showScrollbar>
			{/* Modal */}
			<PageContentBanner divider gap="m">
				<SearchTermFilter
					size="small"
					width="l"
					placeholder={`Search users ${loading ? "" : "(" + data.length + ")"}`}
					isClearable
					dataSet={data}
					setFilteredData={setFilteredData}
					path={["firstName", "lastName", "email"]}
					hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
				/>
			</PageContentBanner>
			<PageContentBodyGrid showScrollbar>
				<PageGridItem colSpan="12">
					<Table
						minWidth={1000}
						colWidths={colWidths}
						headers={headers}
						dataMap={dataMap(hasSearchTerm ? filteredData : data)}
						noResults={{ message: "No users found", subMessage: "Try adding a new user." }}
						dataLoading={loading}
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(AnalyticsUsersList);