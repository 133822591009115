import React from 'react';
import Tooltip from '../popups/Tooltip';
import "./FilterIcon.scss";

const FilterIcon = ({children, icon, active, onClick, className, disabled, toolTip}) => {
	return (
		<Tooltip disabled={!toolTip} label={toolTip} delay={300} className={`filter-icon-wrapper${active ? ' filter-active' : ''}${disabled ? ' filter-disabled' : ''}${className ? ' ' + className : ''}`}>
			<div className="filter-icon" onClick={onClick}>{children || icon}</div>
		</Tooltip>
   );
}

export default FilterIcon;