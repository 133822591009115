import React,{ useState, useRef } from "react";
//import Editor, { images, tables, simpleInlineStyles } from "./lib/index";
import Editor from '@threeskye/editor-component-slate';
import './SlateEditor.scss';

const SlateEditor = (props) => {


	// Add the initial value when setting up our state.
	const initial = props.load;

	const tableStyleRef = useRef();
	const [value, setValue] = useState(initial);
	const [editor, setEditor] = useState(null);


	/* const addins = ()=>{	
		// const bold = inlineStyle("mod+b", "bold");
		// const italic = inlineStyle("mod+i", "italic");
		// const underline = inlineStyle("mod+u", "underline");
		// const mentionsAddin = mentions(mentionTriggers, mentionQueue);


		

		const headings = {
				install: editor=>{
					setEditor(editor);
					return {
						getRenderer: (type, editor) => {
							if (type === "heading-one") 
								return ({attributes, children})=><h1 {...attributes}>{children}</h1>
							else
								return null;
						},
						editor: editor
						
					}
				}
			}
		

		// const addins = [ bold, italic, underline, mentionsAddin, images(), table]
		const addins = simpleInlineStyles().concat([images(), headings]).concat(props.addins);
		return addins;
	} */

	// const onFocus = () => {
	// 	console.log("Slate editor focussing")
	// }
	// const onBlur = () => {
	// 	console.log("Slate editor blurring")
	// }
	

	return (
	<>
		<div className="threeskye-editor">
		<Editor 
			addins={() => props.addins}
			className="editor-wrapper"
			value={value}
			onChange={(bits) =>  {props.onChange(bits);setValue(bits);}}
			toolbar={{type:"full"}}
		/>
		</div>
	</>
	)
}


export default SlateEditor;