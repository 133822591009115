import React from 'react';
import HorizontalStackedChart from './HorizontalStackedChart';
import "./RechartsOverrides.scss";

const BrowserChart = (props) => {
	const data = [
		{
			name: "Chrome",
			value: 92,
		},
		{
			name: "Firefox",
			value: 33,
		},
		{
			name: "Safari",
			value: 30,
		},
		{
			name: "Edge",
			value: 26,
		},
		{
			name: "Brave",
			value: 20,
		},
		{
			name: "Internet Explorer",
			value: 10,
		},
	];

	return (
		<HorizontalStackedChart data={data} tooltips />
	);
}

export default BrowserChart;