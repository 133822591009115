import React from 'react';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import Table from '../../../components/Table/Table';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import DetailField from '../../Shared/DetailSection/DetailField';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { useState } from 'react';
import { useRemote } from '../../../Utils/Utils';
import Tooltip from '../../../components/popups/Tooltip';
import { Eye, Mail, Monitor, Smartphone, Users } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';
import FlexWrapper from '../../FlexWrapper';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import Divider from '../../../components/Divider';
import IconCounter from '../../../components/IconCounter';
import TickerSectorItem from '../../../components/TickerSectorItem';
import AnalyticsMetricFilter from '../../../components/Analytics/AnalyticsMetricFilter';

const AnalyticsUserPage = ({ changeRoute }) => {
	const remote = useRemote();

	const dummyUser = {
		firstName: "Coraline",
		lastName: "Jones",
		email: "Coraline.jones@gmail.com",
		id: 123456,
		analytics: { watching: 11, emailViews: 22, portalViews: 33, pdfDownload: 44, modalDownload: 55 },
		research: [
			{
				ticker: { name: "Auckland International Airport Limited", code: "AIA.NZ", ric: "AIA.NZ", image: "https://research-portal-test.3skye.com/icons/tickers/AIA.NZ.png" },
				publishedDate: "22/01/2022",
				title: "Core strong, market volatile",
				author: "Carmen Sandiego",
				download: "modal",
				viewedIn: "",
				device: "desktop",
			},
			{
				ticker: { name: "Mercury", code: "MEL.NZ", ric: "MEL.NZ", image: "https://research-portal-test.3skye.com/icons/tickers/MEL.NZ.png" },
				publishedDate: "22/01/2022",
				title: "Core strong, market volatile",
				author: "Carmen Sandiego",
				download: "",
				viewedIn: "portal",
				device: "desktop",
			},
			{
				ticker: { name: "Auckland International Airport Limited", code: "AIA.NZ", ric: "AIA.NZ", image: "https://research-portal-test.3skye.com/icons/tickers/AIA.NZ.png" },
				publishedDate: "22/01/2022",
				title: "Core strong, market volatile",
				author: "Carmen Sandiego",
				download: "pdf",
				viewedIn: "",
				device: "mobile",
			},
		],
	};

	// State
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [hasSearchTerm, setHasSearchTerm] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentFilter, setCurrentFilter] = useState("");

	// Table data
	const colWidths = ["10%", "15%", "20%", "10%", "10%", "10%"];
	const headers = [
		"Time/Date",
		"Ticker",
		"Title",
		{ text: "Download", className: "text-align-center" },
		{ text: "Viewed In", className: "text-align-center" },
		{ text: "Device", className: "text-align-center" },
	];

	const dataMap = (data) =>
		data &&
		data.map((data, idx) => {
			const { download, viewedIn, device } = data;
			return (
				<tr key={idx} className="cursor-pointer">
					{/* User details */}
					<td>{data.publishedDate}</td>
					<td>
						<TickerSectorItem image={data.ticker.image} label={data.ticker.code} country={data.ticker.country} filename={data.ticker.ric} />
					</td>
					<td>{data.title}</td>
					<td className="text-align-center">
						<Tooltip disabled={download === ""} label={download === "pdf" ? "PDF Download" : download === "modal" ? "Modal Download" : ""}>
							{download === "pdf" ? <PdfIcon noHover /> : download === "modal" ? <XlsIcon noHover /> : "-"}
						</Tooltip>
					</td>
					<td className="text-align-center">
						<Tooltip disabled={viewedIn === ""} label={viewedIn === "email" ? "Email" : "Portal"}>
							{viewedIn === "email" ? <Mail className="colour-text-purple" /> : viewedIn  === "portal" ? <Eye className="colour-text-primary" /> : "-"}
						</Tooltip>
					</td>
					<td className="text-align-center">
						<Tooltip disabled={device === ""} label={device === "mobile" ? "Mobile" : "Desktop"}>
							{device === "mobile" ? <Smartphone className="colour-text-accent-2" /> : device  === "desktop" ? <Monitor className="colour-text-primary" /> : "-"}
						</Tooltip>
					</td>
				</tr>
			);
		});
	
	const filterOnclick = (value) => {
		return currentFilter === value ? setCurrentFilter("") : setCurrentFilter(value);
	}

	return (
		<Page fixedBanner showScrollbar>
			{/* Modal */}
			<PageContentBanner divider gap="m">
				<ActionButtonsBanner handleBackOnClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/users")} />
			</PageContentBanner>
			<PageContentBodyGrid showScrollbar>
				<PageGridItem colSpan="12">
					<FlexWrapper gap="xl">
						<FlexWrapper gap="xs" direction="column" align="flex-start">
							<p className="font-weight-semi-bold">{dummyUser.firstName + " " + dummyUser.lastName}</p>
							<p>{dummyUser.email}</p>
						</FlexWrapper>
						<Divider vertical height={40} />
						<AnalyticsMetricFilter
							onClick={() => filterOnclick("emailViews")}
							active={currentFilter === "emailViews"}
							inactive={currentFilter !== "" && currentFilter !== "emailViews"}
							label="Email Views"
							status="read"
							icon={<Mail />}
							count={dummyUser.analytics.emailViews}
						/>
						<AnalyticsMetricFilter
							onClick={() => filterOnclick("portalViews")}
							active={currentFilter === "portalViews"}
							inactive={currentFilter !== "" && currentFilter !== "portalViews"}
							label="Portal Views"
							icon={<Eye />}
							count={dummyUser.analytics.portalViews}
						/>
						<AnalyticsMetricFilter
							onClick={() => filterOnclick("pdfDownloads")}
							active={currentFilter === "pdfDownloads"}
							inactive={currentFilter !== "" && currentFilter !== "pdfDownloads"}
							label="PDF Downloads"
							icon={<PdfIcon noHover />}
							count={dummyUser.analytics.pdfDownload}
						/>
						<AnalyticsMetricFilter
							onClick={() => filterOnclick("modalDownload")}
							active={currentFilter === "modalDownload"}
							inactive={currentFilter !== "" && currentFilter !== "modalDownload"}
							label="Model Downloads"
							icon={<XlsIcon noHover />}
							count={dummyUser.analytics.modalDownload}
						/>
					</FlexWrapper>
				</PageGridItem>
				<PageGridDivider colSpan="12" />
				<PageGridItem colSpan="12">
					<SearchTermFilter
						size="small"
						width="l"
						placeholder={`Search users ${loading ? "" : "(" + data.length + ")"}`}
						isClearable
						dataSet={data}
						setFilteredData={setFilteredData}
						path={["firstName", "lastName", "email"]}
						hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
					/>
				</PageGridItem>
				<PageGridItem colSpan="12">
					<Table
						minWidth={1000}
						colWidths={colWidths}
						headers={headers}
						dataMap={dataMap(hasSearchTerm ? filteredData : dummyUser.research)}
						noResults={{ message: "No users found", subMessage: "Try adding a new user." }}
						dataLoading={loading}
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(AnalyticsUserPage);