import React, { useState, useEffect } from 'react';
import { withRemote } from '@threeskye/global';
import PopupMenu from '../PopupMenu';
import './NewMenu.scss';
import PopupMenuHeader from '../popups/PopupMenuHeader';
import PopupMenuItem from '../PopupMenuItem';
import Divider from '../Divider';

const NewMenu = ({ changeRoute, editorPath, remote, crmPath }) => {
	const [templates, setTemplates] = useState([])
	// const [loading, setLoading] = useState(true)

	useEffect(() => {
		remote.get("/data/templates").then((templates) => {
			setTemplates(templates)
			// setLoading(false)
		})
	}, [])

	const menuItems = templates.map((template) => {
		return { name: template, onClick: () => {changeRoute(`${editorPath}`)} }
	})
	return (
		<>
			<PopupMenu padding width="240px">
				<PopupMenuHeader>Templates</PopupMenuHeader>
				{menuItems && menuItems.map((item, idx) => <PopupMenuItem key={idx} label={item.name ? item.name : item} icon={item.icon && item.icon} onClick={item.onClick} />)}
				<Divider />
				<PopupMenuHeader>Client Management</PopupMenuHeader>
				<PopupMenuItem label="User" onClick={() => changeRoute(`${crmPath}/users/user/create`)} />
				<PopupMenuItem label="Company" onClick={() => changeRoute(`${crmPath}/companies/company/create`)}/>
				<PopupMenuItem label="Distribution List" onClick={() => changeRoute(`${crmPath}/distribution/distribution-list/create`)}/>
			</PopupMenu>
		</>
	);
}

export default withRemote(NewMenu);